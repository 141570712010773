export const ReqStockYearlyData=(ws,marketID,stockCode)=>{
    let data ={
      request: "ReqStockYearlyData", 
      requestID: 1,
      marketID: marketID,
      stockCode: stockCode,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqStockYearlyData ERROR:'+ JSON.stringify(error)) // catch error
    }
}