import React,{useState,useEffect,useRef,useLayoutEffect} from 'react';
import {
  MinimizeIcon,
  MinimizeIcon2,
  DragIcon,
  LoadingIcon1,
  LoadingIcon2,
  SortIcon,
  SortIcon_Down,
  SortIcon_Up,
  DownTriangle,
  NewListIcon
} from '../icons'
import {fidID_Dict} from '../fidID_Dict'
import{StopLoopIcon,CheckIcon,PlayIcon}from '../icons'
import {formatAMPM} from '../util'
import DropDownMenu from './components/DropDownMenu'
import { Pagination,ConfigProvider,Table,Tooltip } from 'antd'
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import zhHK from 'antd/es/locale/zh_HK';
import { ResizeObserver } from '@juggle/resize-observer';

import lang from '../lang.json'
const f16='16px'
const f14='14px'
const f12='12px'
const f10='10px'
const f8='8px'


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function List(props){
  let prefixCls = "";
  if (props.theme) {
    prefixCls = "dark";
  }
  const [locale, setLocal] = useState(zhCN);
  if (props.lang.lang === "EN" && locale.locale != "en") {
    setLocal(enUS);
  } else if (props.lang.lang === "TC" && locale.locale != "zh-hk") {
    setLocal(zhHK);
  } else if (props.lang.lang === "SC" && locale.locale != "zh-cn") {
    setLocal(zhCN);
  }

  if (props.data.length>0) {
    props.data.map((item,index) => item["index"] = ++index)
  }

  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}

  const [isLoopMenuExpand,setisLoopMenuExpand]=useState(false)
  const handleClickMenu=()=>{
    setisLoopMenuExpand(!isLoopMenuExpand)
  }

  const [pageCurrent, setPageCurrent] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  

  const handleSetLoop=(item)=>()=>{
    setisLoopMenuExpand(!isLoopMenuExpand)
    props.SetListLoopInterval(item)
  }

  const handleNumValue=(field,val)=> {
    return fidID_Dict[field].t==='Number'?
    (<div style={{ flex:2, 
                        wordWrap: "break-word",
                        wordBreak:"normal",
                        color:fidID_Dict[field].color?((val || val==0)?(val>=0?'#04D26F':'#FF3E3A'):'black'):(props.theme?'white':'black')
                      }}>
 						{fidID_Dict[field].isComma?
 						numberWithCommas(val):
 						((val ||
              val==0)?Math.round(val*10000)/(fidID_Dict[field].percent?100:10000):'--')
 						}
 					</div>):<div style={{flex:2}}>{(val || val==0)?val:'--'}</div>
  }

  const columns = [
    {
      align: "center",
      width: 55,
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      render: (t,r,index) => <span style={{opacity:.5}}>{t}</span>,
    },
    {
      align: "center",
      title: L("SYMBOL"),
      dataIndex: 'stockCode',
      key: 'SYMBOL',
      fixed: 'left',
      sorter: true,
    },
    {
      align: "center",
      title: L("NAME"),
      dataIndex: 'stockCode',
      key: 'NAME',
      fixed: 'left',
      sorter: true,
      render: t => <span>{props.HKstockListDict&&props.HKstockListDict[t]&&props.HKstockListDict[t].stockName}</span>,
    },
    {
      align: "center",
      title: L("Price"),
      dataIndex: '44',
      key: '44',
      sorter: true,
      render: t => handleNumValue(44,t),
    },
    {
      align: "center",
      title: L("Price $ Change"),
      dataIndex: '126',
      key: '126',
      sorter: true,
      render: t => handleNumValue(126,t),
    },
    {
      align: "center",
      title: L("Price % Change"),
      dataIndex: '135',
      key: '135',
      sorter: true,
      render: t => handleNumValue(135,t),
    },
    {
      align: "center",
      title: L("Volume"),
      dataIndex: '64',
      key: '64',
      sorter: true,
      render: t => handleNumValue(64,t),
    },
    {
      align: "center",
      title: L("Stock RS"),
      dataIndex: '117',
      key: '117',
      sorter: true,
      render: t => handleNumValue(117,t),
    },
    {
      align: "center",
      title: L("Industry RS"),
      dataIndex: '115',
      key: '115',
      sorter: true,
      render: t => handleNumValue(115,t),
    },
    {
      align: "center",
      title: L("Vol % Chg vs 50-day"),
      ellipsis:true,
      sorter: true,
      showSorterTooltip: { title:L("Vol % Chg vs 50-day")},
      dataIndex: '134',
      key: '134',
      render: t => handleNumValue(134,t),
    }
  ]
  const [tableHeight, setTableHeight] = useState(0)
  const ro = new ResizeObserver((entries, observer) => {
    if (entries) {
      const th = entries[0].contentRect?.height - document.getElementById('headContainer')?.scrollHeight - 82
      if (th >= 40 && !Object.is(tableHeight,th)) {
        setTableHeight(th)
      }
    }
  })

  useLayoutEffect(() => {
    const el = document.getElementById('container');
    // 监听
    ro.observe(el)
    // 销毁
    return () => ro.unobserve(el);
  }, []);

  const handleTableChange=(pagination,filters,sorter,extra)=>{
    if (extra?.action==="sort" && sorter?.field) {
      props.handleClickSort(sorter?.columnKey)()
      setPageCurrent(1)
    }
    if (extra?.action==="paginate") {
      setPageCurrent(pagination.current)
    }
  }
  const handleClickStock = (record,event) => {
    setSelectedRowKeys([record.stockCode])
    props.handleClickStock(record,record.index - 1)()
  }



  if(props.isCollapse)
    return(
      <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
        <div  style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme?'rgba(61, 98, 133, 0.3)':'rgba(255, 255, 255, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:f14,minHeight:25}}>
            {L("Stocklist")}
            {/*props.SelectedList
              ?(props.SelectedList.type==='list'
                ? props.SelectedList.value.watchlistName
                : props.SelectedList.type==='screen'
                ? props.SelectedList.value.screenName
                : (props.SelectedList.type==='industry'||props.SelectedList.type==='sector')
                ? props.SelectedList.value
                :''
              )
              : ""*/}
          </div>
          <div onClick={props.handleCollapseComponent} className="mousehover">
           {props.isEndofRow?<MinimizeIcon theme={props.theme}/>:<MinimizeIcon2 theme={props.theme}/>}
          </div>
          <div className="drag-handle mousehover">
            <DragIcon theme={props.theme}/>
          </div>
        </div>
      </div>
    )
  return(
    <div className='dragcomponent' style={{display:'flex',alignItems:'center',flexDirection:'column',width:'100%',height:'100%'}}>
      <div id="container" style={{display:'flex',flexDirection:'column',height:'100%',width:'100%',backgroundColor: props.theme?'rgba(61, 98, 133, 0.3)':'rgba(255, 255, 255, 0.3)',borderRadius: '10px',padding:'8px',boxSizing: 'border-box'}}>
        <div id="headContainer" style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',marginBottom:'10px'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:"flex-end",minHeight:24}}>
            <div style={{fontSize:f14}}>
              {props.SelectedList
                ?(props.SelectedList.type==='list'
                  ? props.SelectedList.value.watchlistName
                  : props.SelectedList.type==='screen'
                  ? props.SelectedList.value.screenName
                  : (props.SelectedList.type==='industry'||props.SelectedList.type==='sector')
                  ? props.SelectedList.value
                  :''
                )
                : ""}
            </div>
            <div style={{fontSize:f14,marginLeft:10,position:'relative'}}>
              ({L('Total Result')} : <div style={{fontWeight:'bold',display:'inline-block'}}>{props.data.length}</div>)
            </div>
            <div style={{fontSize:f14,marginLeft:20,position:'relative'}}>
              {L("updatetime")} 
              {props.LastUpdatedTime?formatAMPM(props.LastUpdatedTime):'00:00 AM'}
            </div>
            <div className='mousehover' onClick={props.handleDirectAddNewList} style={{marginLeft:10,height:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
              <NewListIcon width={17} height={17} theme={props.theme}/>
            </div>
            {props.isfetchStock&&<div style={{marginLeft:10,position:'relative',height:'100%'}}>
              <div className='App-logo' style={{position:'absolute',top:0,left:0}}>{props.theme?<LoadingIcon1/>:<LoadingIcon2/>}</div>
            </div>}
            
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',fontSize:f14}}>
            <div onClick={props.handleClickLoop} className='mousehover' style={{backgroundColor:'#00000033',height:16,width:15,borderRadius:2,marginRight:15,display:'flex',justifyContent:'center',alignItems:'center'}}>
              {props.isLoop?<CheckIcon theme={props.theme}/>:<div/>}
            </div>
            <div style={{cursor: 'pointer',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'55px',height:'16px',backgroundColor:props.theme?'rgb(16,51,72)':'rgb(183,183,183)',position:'relative',borderRadius:2,marginRight:10}}>
              <div onClick={handleClickMenu} style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',height:'100%'}}>
                <div style={{marginRight:10}}>{props.LoopInterval}s</div>
                <DownTriangle theme={props.theme}/>
              </div>
              {isLoopMenuExpand&&
                <div style={{width:'100%',position:'absolute',top:'100%',left:0,backgroundColor:props.theme?'rgb(16,51,72)':'rgb(183,183,183)',display:'flex',flexDirection:'column',zIndex:2}}>
                  {[3,5,10,15,20,25,30].map((item,index)=>
                    <div onClick={handleSetLoop(item)} style={{height:'16px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                      <div style={{marginRight:10}}>{item}s</div>
                      <div style={{opacity:0}}><DownTriangle theme={props.theme}/></div>
                    </div>
                  )}
                </div>
              }
            </div>
            {isLoopMenuExpand&&<div onClick={handleClickMenu} style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:1}}/>}
            <div>{L("Loop")}</div>
            <div className='mousehover' onClick={props.handleClickPlaying} style={{marginLeft:15,marginRight:28,padding:5,paddingRight:0}}>
              {props.isPlaying
                ? <StopLoopIcon theme={props.theme}/>
                : <PlayIcon theme={props.theme}/>
              }
            </div>
            <div className='dragicons' style={{display:'flex',flexDirection:'row',alignItems:'center',overflowY:'scroll'}}>
              <div onClick={props.handleCollapseComponent} className="mousehover">
                {props.isEndofRow?<MinimizeIcon2 theme={props.theme}/>:<MinimizeIcon theme={props.theme}/>}
              </div>
              <div className="drag-handle mousehover" style={{marginLeft:15}}>
                <DragIcon theme={props.theme}/>
              </div>
            </div>
          </div>

          
        </div>
        
        <div style={{height:'90%',width:"100%"}}>
        <ConfigProvider prefixCls={prefixCls} locale={locale}> 
          <Table 
          rowKey={record => record.stockCode}
          columns={columns} 
          dataSource={props.data} 
          pagination={{ position: ["bottomCenter"], style:{margin:"5px 0"}, hideOnSinglePage: true,defaultCurrent:1,current:pageCurrent, defaultPageSize:20,size:"default", pageSizeOptions: ["20", "50", "100"],showSizeChanger: true}}  
          onChange={handleTableChange}
          scroll={{scrollToFirstRowOnChange:true,x:1400,y:tableHeight}}
          size="small"
          onRow={record => {
            return {
              onClick: event => {handleClickStock(record,event)}
            };
          }}
          rowSelection={{type:"radio",hideSelectAll:true,columnWidth: 0,selectedRowKeys:selectedRowKeys}}
          />
         </ConfigProvider>
        </div>
      </div>
    </div>
  )
}
