import React,{useState,useEffect,useRef} from 'react';
import './App.css';
import RGL, { WidthProvider } from 'react-grid-layout';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import 'antd/dist/antd.min.css';
import './antd.dark.css';
import _, { set } from 'lodash';
import {Resizable}from 'react-resizable';
import {INITLAYOUT,LAYOUT4,LH1,LH2,LH3,CW,UCW,MaxW,MinW,MinH,MaxH} from './quicklayouts'
import appConfig from './app_config.json'
//screens
import Header from './screens/Header'
import Screen from './screens/Screen'
import Chart from './screens/Chart'
import IndustrySector from './screens/IndustrySector'
import ListFolder from './screens/ListFolder'
import List from './screens/List'
import Criteria from './screens/Criteria'
import EditListPanel from './screens/EditListPanel'
import LoginPage from './screens/LoginPage'

//popup
import CreateFolderPopUp from './popups/CreateFolderPopUp'
import ModifyFolderPopUp from './popups/ModifyFolderPopUp'
import CreateWatchListPopUp from './popups/CreateWatchListPopUp'
import ModifyWatchListPopUp from './popups/ModifyWatchListPopUp'
import DeleteFolderPopup from './popups/DeleteFolderPopup'
import DeleteWatchListPopup from './popups/DeleteWatchListPopup'
import AddStockToWatchListPopUp from './popups/AddStockToWatchListPopUp'
import CreateScreenFolderPopUp from './popups/CreateScreenFolderPopUp'
import ModifyScreenFolderPopUp from './popups/ModifyScreenFolderPopUp'
import DeleteScreenFolderPopup from './popups/DeleteScreenFolderPopup'
import CreateScreenPopUp from './popups/CreateScreenPopUp'
import ModifyScreenPopUp from './popups/ModifyScreenPopUp'
import DeleteScreenPopup from './popups/DeleteScreenPopup'
import StockQueryPopup from './popups/StockQueryPopup'
//apis
import {ReqLogin} from './apis/ReqLogin'
import {ReqWatchlistList} from './apis/ReqWatchlistList'
import {RequestMarketList} from './apis/RequestMarketList'
import {RequestStockList} from './apis/RequestStockList'
import {ReqAddWatchlistFolder} from './apis/ReqAddWatchlistFolder'
import {ReqDeleteWatchlistFolder} from './apis/ReqDeleteWatchlistFolder'
import {ReqAddWatchlist} from './apis/ReqAddWatchlist'
import {ReqDeleteWatchlist} from './apis/ReqDeleteWatchlist'
import {ReqWatchlistStocks} from './apis/ReqWatchlistStocks'
import {ReqModWatchlistStocks} from './apis/ReqModWatchlistStocks'
import {ReqFinFigure} from './apis/ReqFinFigure'
import {ReqChartData} from './apis/ReqChartData'
import {ReqIndustrySummary} from './apis/ReqIndustrySummary'
import {ReqIndustryStockRanking} from './apis/ReqIndustryStockRanking'
import {ReqSectorStockRanking} from './apis/ReqSectorStockRanking'
import {ReqScreenList} from './apis/ReqScreenList'
import {ReqAddScreenFolder} from './apis/ReqAddScreenFolder'
import {ReqDeleteScreenFolder} from './apis/ReqDeleteScreenFolder'
import {ReqAddScreen} from './apis/ReqAddScreen'
import {ReqDeleteScreen} from './apis/ReqDeleteScreen'
import {ReqCriteriaList} from './apis/ReqCriteriaList'
import {ReqFavoriteCriteriaList} from './apis/ReqFavoriteCriteriaList'
import {ReqModScreenCriteria} from './apis/ReqModScreenCriteria'
import {ReqScreenCriteria} from './apis/ReqScreenCriteria'
import {ReqScreenFilteringResult} from './apis/ReqScreenFilteringResult'
import {ReqModFavoriteCriteriaList} from './apis/ReqModFavoriteCriteriaList'
import {ReqIndustryList} from './apis/ReqIndustryList'
import {ReqSectorList} from './apis/ReqSectorList'
import {ReqStockYearlyData} from './apis/ReqStockYearlyData'
import {ReqBlockFinFigure} from './apis/ReqBlockFinFigure'
import {ReqScreenPassFail} from './apis/ReqScreenPassFail'
import {ReqLangList} from './apis/ReqLangList'
import {ReqLogout} from './apis/ReqLogout'

import ResizeHandler from './screens/components/ResizeHandler'

//mobiles
import MobileFolders from './screens/MobileFolders'
import MobileList from './screens/MobileList'
import MobileStock from './screens/MobileStock'
import MobileSetting from './screens/MobileSetting'
import MobileCriteria from './screens/MobileCriteria'
import MobileEditList from './screens/MobileEditList'

//handlers
import {handleSelectLayout} from './handlers/handleSelectLayout'
import {handleClickLoop,handleClickPlaying}from './handlers/handlePlayStop'
import {handleLang} from './handlers/handleLang'
import {useUrlParams} from './handlers/handleUrlParams'

const ReactGridLayout = WidthProvider(RGL);


const MyHandle= React.forwardRef((props, ref) => <ResizeHandler innerRef={ref} {...props} />)



function App() {
  const urlParams = useUrlParams();
  const [isMobile,setisMobile]=useState(false)
  const isMobileRef=useRef(isMobile)
  isMobileRef.current=isMobile

  // Pterminal Login
  useEffect(() => {
    if(urlParams.sessionToken) {
      handleLogin()
    }
  }, [])

  useEffect(() => {
    if (!appConfig.isStartMobile) return
    setisMobile(window.innerWidth<=1024)
    isMobileRef.current=window.innerWidth<=1024
    function handleResize() {
      let isSame=isMobileRef.current===(window.innerWidth<=1024)
      setisMobile(window.innerWidth<=1024)
      isMobileRef.current=window.innerWidth<=1024
      setwidth(window.innerWidth)
      setheight(window.innerHeight)
      if(!isSame&&isLoginRef.current){
        if(!isEditingCriteriaRef.current&&!isEditListPanel.current){
          window.initOnReady(window.innerWidth<=1024,displayingStockRef.current.stockCode,tradingViewCallback,changeStockCallback,'tv_chart_container',lang.lang,theme?'Dark':'Light',MarketID)
        }else{
          if(isMobileRef.current)
            window.initOnReady(window.innerWidth<=1024,displayingStockRef.current.stockCode,tradingViewCallback,changeStockCallback,'tv_chart_container',lang.lang,theme?'Dark':'Light',MarketID)
        }
      }
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const [height,setheight]=useState(window.innerHeight)
  const [width,setwidth]=useState(window.innerWidth)

  const [layout,setlayout]=useState(INITLAYOUT)
  const [isDragging,setisDragging]=useState(false)
  const [theme,settheme]=useState(urlParams.theme || 0)//0:white 1:dark

  const [MarketID,setMarketID]=useState(1)
  const MarketIDRef=useRef(MarketID)
  MarketIDRef.current=MarketID

  //websocket variables
  const [websocket,setwebsocket]=useState(null)
  const websocketRef=useRef(websocket)
  websocketRef.current=websocket
  const [isConnected,setisConnected]=useState(false)
  const [isLogin,setisLogin]=useState(urlParams.sessionToken ? true : false)
  const isLoginRef=useRef(isLogin)
  isLoginRef.current=isLogin
  const [isLoginProcess,setisLoginProcess]=useState(false)
  const isLoginProcessRef=useRef(isLoginProcess)
  isLoginProcessRef.current=isLoginProcess
  const [LoginErrMessage,setLoginErrMessage]=useState(false)



  const [marketList,setmarketList]=useState([])
  const marketListRef=useRef(marketList)
  marketListRef.current=marketList

  const [HKstockList,setHKstockList]=useState([])
  const HKstockListRef=useRef(HKstockList)
  HKstockListRef.current=HKstockList
  const [HKstockListDict,setHKstockListDict]=useState({})
  const HKstockListDictRef=useRef(HKstockListDict)
  HKstockListDictRef.current=HKstockListDict

  const [stockList,setstockList]=useState([])
  const stockListRef=useRef(null)
  stockListRef.current=stockList

  const autoscrollRef = React.useRef([]);

  const [isfetchStock,setisfetchStock]=useState(false)

  const [stockCodeDict,setstockCodeDict]=useState({})
  const stockCodeDictRef=useRef(stockCodeDict)
  stockCodeDictRef.current=stockCodeDict


  const [chartDataBars,setchartDataBars]=useState([])


  const [industryData,setindustryData]=useState({})
  const industryDataRef=useRef(industryData)
  industryDataRef.current=industryData

  const [StockYearData,setStockYearData]=useState([])

  const [selectedPassFailScreen,setselectedPassFailScreen]=useState(null)

  const [industryList,setindustryList]=useState([])
  const industryListRef=useRef(industryList)
  industryListRef.current=industryList
  const [SectorList,setSectorList]=useState([])
  const SectorListRef=useRef(SectorList)
  SectorListRef.current=SectorList


  const [criteriaGroup,setcriteriaGroup]=useState([])
  const criteriaGroupRef=useRef(criteriaGroup)
  criteriaGroupRef.current=criteriaGroup
  const [criteriaDict,setcriteriaDict]=useState({})
  const criteriaDictRef=useRef(criteriaDict)
  criteriaDictRef.current=criteriaDict

  const [CriteriaFilteringResult,setCriteriaFilteringResult]=useState({})

  const [FavoriteCriteriaList,setFavoriteCriteriaList]=useState([])
  const FavoriteCriteriaListRef=useRef(FavoriteCriteriaList)
  FavoriteCriteriaListRef.current=FavoriteCriteriaList
  const [FavoriteCriteriaDict,setFavoriteCriteriaDict]=useState({})
  const FavoriteCriteriaDictRef=useRef(FavoriteCriteriaDict)
  FavoriteCriteriaDictRef.current=FavoriteCriteriaDict


  const [SelectedCritiaList,setSelectedCritiaList]=useState([])
  const SelectedCritiaListRef=useRef(SelectedCritiaList)
  SelectedCritiaListRef.current=SelectedCritiaList
  const [SelectedList,setSelectedList]=useState({})
  const SelectedListRef=useRef(SelectedList)
  SelectedListRef.current=SelectedList

  const [PassFailscreenResult,setPassFailscreenResult]=useState([])
  const [displayingStock,setdisplayingStock]=useState(null)
  const displayingStockRef=useState(displayingStock)
  displayingStockRef.current=displayingStock

  const [LangList,setLangList]=useState([])
  const LangListRef=useState(LangList)
  LangListRef.current=LangList
  const [lang,setlang]=useState({
    lang: urlParams.lang || 'EN'
  })

  const [LastUpdatedTime,setLastUpdatedTime]=useState(null)

  const [isPlaying,setisPlaying]=useState(false)
  const [isLoop,setisLoop]=useState(true)


  const [PassFailCritiaList,setPassFailCritiaList]=useState([])

  const [isEditPopUpMask,setisEditPopUpMask]=useState(false)

  const nextStock=()=>{
    if(!isEditingCriteria&&!isEditListPanel&&isPlaying&&stockList.length>0&&isLogin){
      for(let i in stockList){
        if(stockList[i].stockCode===displayingStock.stockCode){
          console.log(displayingStock.stockCode)
          let nextIndex=Number(i)+1
          if(nextIndex===stockList.length)
            if(isLoop)
              nextIndex=0
            else
              return
          if(!isEditingCriteria&&!isEditListPanel&&isLogin){
            autoscrollRef.current[nextIndex].scrollIntoView({behavior: 'smooth', block: 'start' })
            handleClickStock(stockList[nextIndex],nextIndex)()
          }
          return
        }
      }
    }
  }

  const handleMarket=(id)=>()=> {
    if (isfetchStock) return
    if (MarketIDRef.current == id) {
      return;
    }
    setMarketID(id)
    MarketIDRef.current=id
    window.setTradingMarketID(id)
    window.setStockCode(id === 1 ? '0388.HK' :'AAPL')

    if(isEditingCriteriaRef.current){
      ReqScreenFilteringResult(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,MarketIDRef.current)
    }
    if(SelectedListRef.current.type==='screen'){
      setstockList([])
      stockListRef.current=[]
      setisfetchStock(true)
      ReqBlockFinFigure(websocketRef.current,MarketIDRef.current,1,null,screenListFolderRef.current[SelectedListRef.current.index].screenList[SelectedListRef.current.index2].screenID,null,5)
    }
  }





  const [username,setusername]=useState('')
  const [password,setpassword]=useState('')
  const [sitename,setsitename]=useState(appConfig.siteName)
  const handleUsername=(e)=>{setusername(e.target.value)}
  const hanldePassword=(e)=>{setpassword(e.target.value)}

  const handleLogin=()=>{
    init()
  }
  const handleReqLogin=(ws)=>{
    const userSite = getUsernameAndSite(ws)
    if (userSite || urlParams.sessionToken) {
      if(urlParams.sessionToken) {
        ReqLogin(ws,lang.lang,urlParams.userName,'',urlParams.siteName,urlParams.sessionToken)
      } else {
        ReqLogin(ws,lang.lang,userSite.userName,password,userSite.siteName)
      }
      setisLoginProcess(true)
      isLoginProcessRef.current=true
    }
  }
  const handleLogout=()=>{
    displayingStockRef.current=null
    setdisplayingStock(null)
    ReqLogout(websocket)
    setwatchListFolder([])
    watchListFolderRef.current=[]
    setscreenListFolder([])
    setscreenListFolder.current=[]
    setsitename(appConfig.siteName)
    setpassword('')
    //let url = `/?lang=${lang.lang}&market=${MarketID}&theme=${theme}`;
    //window.location.href = url;
    //websocket.close()
    //setisLogin(false)
    //isLoginRef.current=false
  }

  const init=()=>{
    const ws = new WebSocket(appConfig.apiBaseUrl)

    ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected')
      setisConnected(true)
      setwebsocket(ws)
      websocketRef.current=ws
      handleReqLogin(ws)
    }

    ws.onmessage = evt => {
      // listen to data sent from the websocket server
      handleResponseMessage(evt.data,ws)

    }

    ws.onclose = () => {
      console.log('disconnected')
      setisConnected(false)

      // automatically try to reconnect on connection loss
      setTimeout(()=>{
        if(isLoginRef.current){
          //setisLogin(false)
          //isLoginRef.current=false
          init()
        }else{
          console.log('loging out....')
        }
      },1000 )

    }
  }
  const getUsernameAndSite = (ws)=> {
    let userName = ''
    let siteName = ''
    if (appConfig.whitelistEnable && username.indexOf('@') !== -1) {
      const userSite = username.split("@")
      if (userSite.length !== 2 || userSite[1].length === 0) {
        loginFailure(ws, 'Authentication failure.')
        return false
      }
      siteName = userSite[1]
      if (siteName.trim() === '') {
        loginFailure(ws, 'Authentication failure.')
        return false
      }
      const whitelist = appConfig.whitelist.filter(item => item === siteName)
      if (whitelist.length === 0) {
        loginFailure(ws, 'Authentication failure.')
        return false
      }
      userName = userSite[0]
      // setusername(userName)
      setsitename(siteName)
    } else {
      userName = username
      siteName = sitename
    }
    return { userName, siteName }
  }

  const loginFailure = (ws, error) => {
    setLoginErrMessage(error||'')
    console.log('Login Failed: '+error||'')
    setisLogin(false)
    try{
      if (ws !== null) {
        ws.close()
      }
    }catch(eee){
      console.log(eee)
    }
    setisLogin(false)
    isLoginRef.current=false
  }

  const tradingViewCallback=(e)=>{
    console.log('Trading View Request:')
    console.log(e)
    websocketRef.current.send(JSON.stringify(e))
  }
  const changeStockCallback=(stk)=>{
    console.log('stkcode',stk);
    if(stk.includes('HK')){
      setMarketID(1)
    }else{
      setMarketID(2)
    }
    setdisplayingStock({stockCode:stk})
    displayingStockRef.current={stockCode:stk}
    setindustryData({...{stockCode:stk}})
    ReqFinFigure(websocketRef.current,(stk.marketID||MarketIDRef.current),stk,6,701)
    ReqFinFigure(websocketRef.current,(stk.marketID||MarketIDRef.current),stk)
    ReqIndustryStockRanking(websocketRef.current,(stk.marketID||MarketIDRef.current),stk)
    ReqSectorStockRanking(websocketRef.current,(stk.marketID||MarketIDRef.current),stk)
    ReqChartData(websocketRef.current,(stk.marketID||MarketIDRef.current),stk,null,'D',Math.floor(new Date().getTime()/1000)-60*60*24*100)
    ReqStockYearlyData(websocketRef.current,(stk.marketID||MarketIDRef.current),stk)

  }


  const handleResponseMessage=(data,ws)=>{
    const json = JSON.parse(data)
    console.log('json.response',json.response)

    if(json.responseID<0){
      window.OnTradingViewMessage(json)
    }
    if(json.responseID>=10000&&json.responseID<=20000){
      console.log('Trading View Response Payload:')
      window.OnTradingViewMessage(json)
      return
    }
    //duplicate login error
    if(json.result===-640006||json.result===-640004){
      //todo fix here
      //alert('-640006')
      console.log(json.reason)
      if (json.responseID === -1 && json.response === "ResLogout") {
        alert(json.reason)
        ws.close()
        setisLogin(false)
        isLoginRef.current=false
        return
      }
      if(isLoginProcessRef.current)return
      handleReqLogin(ws)
      return
    }
    if(json.result<0&&(json.reason&&json.reason.includes('用戶未登入'))){
      //todo fix here
      console.log('Expired. Logout by system')
      if(isLoginProcessRef.current)return
      //alert('用戶未登入')
      handleReqLogin(ws)
      console.log('Re-Login now')
      return
    }
    switch(json.response) {

      case 'ResLogin':
        if(json.result===0){
          setisLogin(true)
          isLoginRef.current=true
          console.log('Login Success')
          setisLoginProcess(false)
          isLoginProcessRef.current=false
          setLoginErrMessage('')

          if(displayingStockRef.current===null){
            let stk="0388.HK"
            handleClickStock({stockCode:stk},null,true)()
            setdisplayingStock({stockCode:stk})
            displayingStockRef.current={stockCode:stk}
            setindustryData({...{stockCode:stk}})
            ReqFinFigure(ws,(stk.marketID||MarketIDRef.current),stk,6,701)
            ReqFinFigure(ws,(stk.marketID||MarketIDRef.current),stk)
            ReqIndustryStockRanking(ws,(stk.marketID||MarketIDRef.current),stk)
            ReqSectorStockRanking(ws,(stk.marketID||MarketIDRef.current),stk)
            ReqChartData(ws,(stk.marketID||MarketIDRef.current),stk,null,'D',Math.floor(new Date().getTime()/1000)-60*60*24*100)
            ReqStockYearlyData(ws,(stk.marketID||MarketIDRef.current),stk)
          }else{
            console.log('-----')
            console.log(JSON.stringify(displayingStockRef.current))
          }

          if(watchListFolderRef.current.length===0)
            ReqWatchlistList(ws)
          else
            console.log('No Refresh watchListFolder')

          if(marketListRef.current.length===0)
            RequestMarketList(ws)
          else
            console.log('No Refresh marketList')

          if(screenListFolderRef.current.length===0)
            ReqScreenList(ws)
          else
            console.log('No Refresh screenListFolder')

          if(criteriaGroupRef.current.length===0)
            ReqCriteriaList(ws)
          else
            console.log('No Refresh criteriaGroup')

          if(FavoriteCriteriaListRef.current.length===0)
            ReqFavoriteCriteriaList(ws)
          else
            console.log('No Refresh FavoriteCriteriaList')

          if(industryListRef.current.length===0)
            ReqIndustryList(ws,MarketIDRef.current)
          else
            console.log('No Refresh industryList')

          if(SectorListRef.current.length===0)
            ReqSectorList(ws,MarketIDRef.current)
          else
            console.log('No Refresh SectorList')

          if(HKstockListRef.current.length===0){
            setHKstockList([])
            RequestStockList(ws,1)
          } else
            console.log('No Refresh HKstockList')

          if(LangListRef.current.length===0){
            ReqLangList(ws,1)
          } else
            console.log('No Refresh LangListRef')


        }else{
          loginFailure(ws, json.reason)
          // setLoginErrMessage(json.reason||'')
          // console.log('Login Failed: '+json.reason||'')
          // setisLogin(false)
          // try{
          //    ws.close()
          //  }catch(eee){
          //   console.log(eee)
          //  }
          // setisLogin(false)
          // isLoginRef.current=false
        }
        break;
        //5.1.2 logout
      case 'ResLogout':
        //ReqLogin(ws,lang.lang||'EN',username,password)
        //console.log('Re-Login now')
        console.log('Logout now')
        ws.close()
        setisLogin(false)
        isLoginRef.current=false
        break
        //5.1.3
      case 'ResChangeLang':
        if(json.result===0){
          //todo fetch new list again
          ReqCriteriaList(ws)
          setHKstockList([])
          ReqIndustryList(ws,MarketIDRef.current)
          ReqSectorList(ws,MarketIDRef.current)
          RequestStockList(ws,1)
          ReqFinFigure(ws,(displayingStockRef.current.marketID||MarketIDRef.current),displayingStockRef.current.stockCode)
          //ReqIndustryStockRanking(websocket,1,displayingStockRef.current.stockCode)
          if(!isEditingCriteriaRef.current&&!isEditListPanel.current){
            if(isMobileRef.current){

            }else{
              window.restartTradingView()
            }
          }
          console.log('ResLangList Success')
        }else{
          console.log('ResLangList Failed')
        }
        break;
        //5.1.4
      case 'ResLangList':
        if(json.result===0){
          console.log('ResLangList Success')
        }else{
          console.log('ResLangList Failed')
        }
        break;
      case 'DownloadLangList':
        if(json.langList&&json.langList.length>0){
          console.log('DownloadLangList Success')
          setLangList(json.langList)
          const initialLang = json.langList[0]
          const lang = urlParams.lang
              ? json.langList.find((e) => e.lang === urlParams.lang) ?? initialLang
              : initialLang
          setlang(lang)
          LangListRef.current=json.langList

        }else{
          console.log('DownloadLangList Failed')
        }
        break;


        //5.2.1
      case 'ResMarketList':
        if(json.result===0){
          console.log('ResMarketList Success')
        }else{
          console.log('ResMarketList Failed')
        }
        break;
      case 'DownloadMarketList':
        if(json.marketList&&json.marketList.length>0){
          console.log('DownloadMarketList Success')
          for(let i of json.marketList)
            i.value=i.marketLongName
          setmarketList(json.marketList)
        }else{
          console.log('DownloadMarketList Failed')
        }
        break;
        //5.2.2
      case 'ResStockList':
        if(json.result===0){
          console.log('ResStockList Success')
        }else{
          console.log('ResStockList Failed')
        }
        break;
      case 'DownloadStockList':
        if(json.stockList&&json.stockList.length>0){
          //todo add check if marketid == hk stock market here
          console.log('DownloadStockList Success')
          let list=[...HKstockListRef.current,...json.stockList]
          let dict=HKstockListDictRef.current
          for(let i of json.stockList)
            dict[i.stockCode]=i
          setHKstockList(list)
          setHKstockListDict(dict)
        }else{
          console.log('DownloadStockList Failed')
        }
        break;


        //5.3.1
      case 'ResCriteriaList':
        if(json.result===0){
          console.log('ResCriteriaList Success')
        }else{
          alert(json.reason)
          console.log('ResCriteriaList Failed')
        }
        break;
      case 'DownloadCriteriaList':
        if(json.criteriaGroups&&json.criteriaList){
          console.log('DownloadCriteriaList Success')


          let group_dict={}
          for(let group of json.criteriaGroups)
            group_dict[group.criteriaGroupID]={...group,criteriaList:[]}
          let dict={}
          for(let crieria of json.criteriaList){
            group_dict[crieria.criteriaGroupID].criteriaList.push(crieria)
            dict[crieria.criteriaID]=crieria
          }

          let arr=[]
          for(let i in group_dict)
            arr.push(group_dict[i])
          setcriteriaGroup(arr)
          setcriteriaDict(dict)

        }else{
          alert(json.reason)
          console.log('DownloadCriteriaList Failed')
        }
        break;
        //5.3.2
      case 'ResFavoriteCriteriaList':
        if(json.result===0){
          console.log('ResFavoriteCriteriaList Success')
        }else{
          alert(json.reason)
          console.log('ResFavoriteCriteriaList Failed')
        }
        break;
      case 'DownloadFavoriteCriteriaList':
        if(json.criteriaList){
          console.log('DownloadFavoriteCriteriaList Success')
          setFavoriteCriteriaList(json.criteriaList)
          let dict={}
          for(let i of json.criteriaList)
            dict[i]={}
          setFavoriteCriteriaDict(dict)
        }else{
          alert(json.reason)
          console.log('DownloadFavoriteCriteriaList Failed')
        }
        break;
        //5.3.3
      case 'ResModFavoriteCriteriaList':
        if(json.result===0){
          console.log('ResModFavoriteCriteriaList Success')
          ReqFavoriteCriteriaList(ws)
        }else{
          alert(json.reason)
          console.log('ResModFavoriteCriteriaList Failed')
        }
        break;
        //5.3.4
      case 'ResScreenList':
        if(json.result===0){
          console.log('ResScreenList Success')
        }else{
          alert(json.reason)
          console.log('ResScreenList Failed')
        }
        break;
      case 'DownloadScreenList':
        if(json.folderList&&json.screenList){
          console.log('DownloadScreenList Success')

          let old_dict={}
          for(let i of screenListFolderRef.current)
            old_dict[i.folderID]=i

          let dict={
            null:{
              notFolder:true,
              screenList:[],
              folderName:'null'
            }
          }
          for(let folder of json.folderList)
            dict[folder.folderID]={...folder,screenList:[]}
          for(let list of json.screenList){
            if(list.folderID)
              dict[list.folderID].screenList.push(list)
            else
              dict['null'].screenList.push(list)
          }
          let folderList=[]
          for(let i in dict)
            folderList.push({...old_dict[dict[i].folderID]||{},...dict[i]})
          setscreenListFolder(folderList)

        }else{
          alert(json.reason)
          console.log('DownloadScreenList Failed')
        }
        break;
        //5.3.5
      case 'ResAddScreenFolder':
        if(json.result===0){
          console.log('ResAddScreenFolder Success')
          handeCancelCreateScreenFolder()
          ReqScreenList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResAddScreenFolder Failed')
        }
        break;
        //5.3.6
      case 'ResDeleteScreenFolder':
        if(json.result===0){
          console.log('ResDeleteScreenFolder Success')
          handeCancelDeleteScreenFolder()
          ReqScreenList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResDeleteScreenFolder Failed')
        }
        break;
        //5.3.7
      case 'ResAddScreen':
        if(json.result===0){
          console.log('ResAddScreen Success')
          handeCancelCreateScreen()
          ReqScreenList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResAddScreen Failed')
        }
        break;
        //5.3.8
      case 'ResDeleteScreen':
        if(json.result===0){
          console.log('ResDeleteScreen Success')
          handeCancelDeleteScreen()
          ReqScreenList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResDeleteScreen Failed')
        }
        break;
        //5.3.9
      case 'ResScreenCriteria':
        if(json.result===0){
          console.log('ResScreenCriteria Success')
        }else{
          alert(json.reason)
          console.log('ResScreenCriteria Failed')
        }
        break;
      case 'DownloadScreenCriteria':
        if(json.criteriaList){
          console.log('DownloadScreenCriteria Success')
          let list =json.criteriaList
          for(let i of list){
            if(criteriaDictRef.current[i.criteriaID]&&criteriaDictRef.current[i.criteriaID].valueType===2){
              if(i.maxValue)i.maxValue=i.maxValue*100
              if(i.minValue)i.minValue=i.minValue*100
            }
            if(criteriaDictRef.current[i.criteriaID]&&criteriaDictRef.current[i.criteriaID]&&criteriaDictRef.current[i.criteriaID].matchingType===0){
              if(criteriaDictRef.current[i.criteriaID].valueType===3)
                i.display=i.matchedValues[0]?'Yes':'No'
              else if(criteriaDictRef.current[i.criteriaID].valueType===4&&criteriaDictRef.current[i.criteriaID].possibleDisplayValues){
                let str=''
                for(let valueIndex in criteriaDictRef.current[i.criteriaID].possibleValues)
                  for(let k in i.matchedValues)
                    if(criteriaDictRef.current[i.criteriaID].possibleValues[valueIndex]===i.matchedValues[k]){
                      str+=criteriaDictRef.current[i.criteriaID].possibleDisplayValues[valueIndex]+' '
                    }
                i.display=str

              }else if(criteriaDictRef.current[i.criteriaID].valueType===5){
                for(let industry of industryListRef.current)
                  if(industry.industryCode===i.matchedValues[0]){
                    i.display=industry.industryName
                  }
              }else if(criteriaDictRef.current[i.criteriaID].valueType===6){
                for(let sector of SectorListRef.current)
                  if(sector.sectorCode===i.matchedValues[0])
                    i.display=sector.sectorName

              }else if(criteriaDictRef.current[i.criteriaID].valueType===7){
                for(let market of marketListRef.current)
                  if(market.marketID===i.matchedValues[0])
                    i.display=market.marketLongName
              }else{
                i.display=i.matchedValues[0]
              }
            }

          }
          if(json.responseID===801){
            setPassFailCritiaList(list)
          }else
            setSelectedCritiaList(list)

        }else{
          alert(json.reason)
          console.log('DownloadScreenCriteria Failed')
        }
        break;
        //5.3.10
      case 'ResModScreenCriteria':
        if(json.result===0){
          console.log('ResModScreenCriteria Success')
          //handleCancelEditCriteria()
          if(json.responseID===524){
            ReqScreenCriteria(ws,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID)
            ReqScreenFilteringResult(ws,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,MarketIDRef.current)
          }


          // if(json.responseID===522){
          //   handleViewScreenResult(true)()
          //   return
          // }

          // setstockList([])
          // setSelectedList({
          //   type:'screen',
          //   index:selectedScreenFolderIndexRef.current,
          //   index2:selectedScreenIndexRef.current,
          //   value:screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current]
          // })
          // ReqBlockFinFigure(ws,MarketIDRef.current,1,null,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,null,3)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResModScreenCriteria Failed')
        }
        break;
        //5.3.11
      case 'ResScreenPassFail':
        if(json.result===0){
          console.log('ResScreenPassFail Success')
          setPassFailscreenResult(json.screenResult)
        }else{
          console.log('ResScreenPassFail Failed')
        }
        break;
        //5.3.12
      case 'ResScreenFilteringResult':
        if(json.result===0){
          console.log('ResScreenFilteringResult Success')
          let dict={}
          for(let i of json.screenFilteringResult)
            dict[i.criteriaID]=i.stockCount
          setCriteriaFilteringResult(dict)
        }else{
          console.log('ResScreenFilteringResult Failed')
        }
        break;


        //5.4.1
      case 'ResFinFigure':
        if(json.figureResult&&json.figureResult.length>0){
          console.log('ResFinFigure Success')
          if(json.responseID===702){
            console.log(json)
            console.log('=========')
            return
          }
          if(json.responseID===701){
            let dict={}
            for(let j of json.figureResult)
              dict[j.fidID]=j.value
            json.data=dict
            let newjson=JSON.parse(JSON.stringify({...displayingStockRef.current,...json}))
            setdisplayingStock(newjson)
            displayingStockRef.current=newjson
            setindustryData(newjson)
            return
          }

          let dict={}
          for(let i of json.figureResult)
            dict[i.fidID]=i.value
          let D_=stockCodeDictRef.current
          D_[`${dict['8']}_${dict['0']}`]=dict
          if(dict['7'])
            ReqIndustrySummary(ws,dict['8']||1,dict['7'])
          setindustryData({
            ...industryDataRef.current,
            industryName:dict['4'],
            SectorCode:dict['5'],
            SectorName:dict['6'],
            IndustryCode:dict['7'],
          })
          setstockCodeDict(D_)


        }else{
          alert(json.reason||'no data')
          console.log('ResFinFigure Failed')
        }
        break;
        //5.4.2
      case 'ResChartData':
        if(json.result===0){
          console.log('ResChartData Success')
        }else{
          alert(json.reason)
          console.log('ResChartData Failed')
        }
        break;
      case 'DownloadChartData':
        if(json.chartDataBars){
          console.log('DownloadChartData Success')
          setchartDataBars(json.chartDataBars)
        }else{
          console.log('DownloadChartData Failed')
        }
        break;
        //5.4.3
      case 'ResStockYearlyData':
        if(json.result===0&&json.figureResult){
          console.log('ResStockYearlyData Success',json.figureResult.length)
          setStockYearData(json.figureResult)

        }else{
          alert(json.reason||'missing data')
          console.log('ResStockYearlyData Failed')
        }
        break;
        //5.4.4
      case 'ResBlockFinFigure':
        if(json.result===0){
          console.log('ResBlockFinFigure Success',json)
        }else{
          alert(json.reason)
          console.log('ResBlockFinFigure Failed')
        }
        break;
      case 'DownloadBlockFinFigure':
        if(json.resultData){
          console.log('DownloadBlockFinFigure Success')
          let index = 1
          if (stockListRef.current&&stockListRef.current.length>1) {
            index = stockListRef.current.length
          }
          for(let i of json.resultData){
            let dict={}
            for(let j of i.figureResult) {
              let valNum = Number(j.value)
              if (!isNaN(valNum)) {
                dict[j.fidID] = valNum
              } else{
                dict[j.fidID]=j.value
              }
            }
            // i.data=dict
            if(i.marketID===undefined){
              i.marketID=MarketIDRef.current
            }
            Object.assign(i,dict)
          }

          if(json.responseID===301){
            let list=[...editListPanelListRef.current,...json.resultData]
            seteditListPanelList(list)
            return
          }
          let list=[...stockListRef.current,...json.resultData]
          if(json.responseSeq===-1){
            if(list.length>0){
              autoscrollRef.current = autoscrollRef.current.slice(0, list.length);
              handleClickStock(list[0],0,false,(isEditingCriteriaRef.current||isEditListPanelRef.current),json.responseID===601)()
            }
            if(json.responseID!==601){
              handleCancelEditCriteria()
            }
            setisfetchStock(false)
          }
          setstockList(list)
          stockListRef.current=list
          setLastUpdatedTime(new Date())
        }else{
          console.log('DownloadBlockFinFigure Failed')
        }
        break;


        //5.5.1
      case 'ResWatchlist':
        if(json.result===0){
          console.log('ResWatchlist Success')
        }else{
          alert(json.reason)
          console.log('ResWatchlist Failed')
        }
        break;
      case 'DownloadWatchlist':
        if(json.watchlistFolderList&&json.watchlistList){
          console.log('DownloadWatchlist Success')

          let old_dict={}
          for(let i of watchListFolderRef.current)
            old_dict[i.watchlistFolderID]=i

          let dict={
            null:{
              notFolder:true,
              watchlistFolderName:'null',
              watchlist:[]
            }
          }
          for(let folder of json.watchlistFolderList)
            dict[folder.watchlistFolderID]={...folder,watchlist:[]}
          for(let list of json.watchlistList){
            if(list.watchlistFolderID)
              dict[list.watchlistFolderID].watchlist.push(list)
            else
              dict['null'].watchlist.push(list)
          }
          let folderList=[]
          for(let i in dict)
            folderList.push({...old_dict[dict[i].watchlistFolderID]||{},...dict[i]})
          setwatchListFolder(folderList)
          watchListFolderRef.current=folderList

        }else{
          alert(json.reason)
          console.log('DownloadWatchlist Failed')
        }
        break;
        //5.5.2
      case 'ResAddWatchlistFolder':
        if(json.result===0){
          console.log('ResAddWatchlistFolder Success')
          handeCancelCreateFolder()
          ReqWatchlistList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResAddWatchlistFolder Failed')
        }
        break;
        //5.5.3
      case 'ResDeleteWatchlistFolder':
        if(json.result===0){
          console.log('ResDeleteWatchlistFolder Success')
          handeCancelDeleteFolder()
          ReqWatchlistList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResDeleteWatchlistFolder Failed')
        }
        break;
        //5.5.4
      case 'ResAddWatchlist':
        if(json.result===0){
          console.log('ResAddWatchlist Success')
          if(json.responseID===901){
            //add stock list to this watchlist
            let array=[]
            for(let i of stockListRef.current)
              array.push({
                marketID:1,
                stockCode:i.stockCode
              })
            ReqModWatchlistStocks(ws,json.watchlistID,2,null,null,array,902)
          }
          handeCancelCreateWatchList()
          ReqWatchlistList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResAddWatchlist Failed')
        }
        break;
        //5.5.5
      case 'ResDeleteWatchlist':
        if(json.result===0){
          console.log('ResDeleteWatchlist Success')
          handeCancelDeleteWatchList()
          ReqWatchlistList(ws)
        }else{
          alert(json.reason||'unknown reason')
          console.log('ResDeleteWatchlist Failed')
        }
        break;
        //5.5.6
      case 'ResWatchlistStocks':
        if(json.result===0){
          console.log('ResWatchlistStocks Success')
        }else{
          console.log('ResWatchlistStocks Failed')
          alert(json.reason)
        }
        break;
      case 'DownloadWatchlistStocks':
        if(json.stockList){
          console.log('DownloadWatchlistStocks Success')
          let list=[...stockListRef.current,...json.stockList]
          setstockList(list)
          stockListRef.current=list
          setLastUpdatedTime(new Date())
          console.log(json.stockList)
        }else{
          console.log('DownloadWatchlistStocks Failed')
        }
        break;
        //5.5.7
      case 'ResModWatchlistStocks':
        if(json.result===0){
          console.log('ResModWatchlistStocks Success')
          let t1=isEditListPanelRef.current?editListPanelFolderIndexRef.current:selectedFolderIndexRef.current
          let t2=isEditListPanelRef.current?editListPanelListIndexRef.current:selectedWatchListIndexRef.current
          if(json.responseID===311){
            //handleCancelEditListPanel()
          }else if(json.responseID===902){
            return
          }else{
            handleCancelAddStocktoWatchList()
          }
          ReqWatchlistList(ws)

          handleSelectWatchList(
              watchListFolderRef.current[t1],
              t1,
              watchListFolderRef.current[t1].watchlist[t2],
              t2
          )()

        }else{
          console.log('ResModWatchlistStocks Failed')
          alert(json.reason)
        }
        break;




        //5.6.1
      case "ResIndustrySummary":
        if(json.result===0){
          console.log('ResIndustrySummary Success')
          // let industrySummaryResult={}
          // for (let i of json.industrySummaryResult)
          //   industrySummaryResult[i.fidID]=i.value
          // let data={
          //   industryName:json.industryName,
          //   industrySummaryResult:industrySummaryResult
          // }
          // console.log(data)
          // setindustryData(data)
        }else{
          console.log('ResIndustrySummary Failed')
          alert(json.reason)
        }
        break;
        //5.6.3
      case "ResIndustryStockRanking":
        if(json.result===0){
          console.log('ResIndustryStockRanking Success')
          // let industryStockRankingResult={}
          // for (let i of json.industryStockRankingResult)
          //   industryStockRankingResult[i.fidID]=i.value
          // let data={
          //   totalNumberOfStocks:json.totalNumberOfStocks,
          //   industryStockRankingResult:industryStockRankingResult
          // }
          // console.log(data)
          setindustryData({
            ...industryDataRef.current,
            totalNumberOfStocks:json.totalNumberOfStocks,
            //industryStockRankingResult:industryStockRankingResult
          })
        }else{
          console.log('ResIndustryStockRanking Failed')
          alert(json.reason)
        }
        break;
        //5.6.4
      case "ResSectorStockRanking":
        if(json.result===0){
          console.log('ResSectorStockRanking Success')
          //let industryStockRankingResult={}
          // for (let i of json.industryStockRankingResult)
          //   industryStockRankingResult[i.fidID]=i.value
          // let data={
          //   totalNumberOfStocks:json.totalNumberOfStocks,
          //   industryStockRankingResult:industryStockRankingResult
          // }
          // console.log(data)
          setindustryData({
            ...industryDataRef.current,
            totalNumberOfStocks_Sector:json.totalNumberOfStocks,
            //industryStockRankingResult:industryStockRankingResult
          })
        }else{
          console.log('ResSectorStockRanking Failed')
          alert(json.reason)
        }
        break;
        //5.6.5
      case "ResIndustryList":
        if(json.result===0){
          console.log('ResIndustryList Success')
        }else{
          console.log('ResIndustryList Failed')
          alert(json.reason)
        }
        break;
      case "DownloadIndustryList":
        if(json.industryList){
          console.log('DownloadIndustryList Success')
          for(let i of json.industryList)
            i.value=i.industryName
          setindustryList(json.industryList)
        }else{
          console.log('DownloadIndustryList Failed')
          alert(json.reason)
        }
        break;
        //5.6.6
      case "ResSectorList":
        if(json.result===0){
          console.log('ResSectorList Success')
        }else{
          console.log('ResSectorList Failed')
          alert(json.reason)
        }
        break;
      case "DownloadSectorList":
        if(json.sectorList){
          console.log('DownloadSectorList Success')
          for(let i of json.sectorList)
            i.value=i.sectorName
          setSectorList(json.sectorList)
        }else{
          console.log('DownloadSectorList Failed')
          alert(json.reason)
        }
        break;
      default:
        console.log('unknown response: '+json.response)
    }
  }




  function onResize(layout_, oldItem, newItem){
    setisDragging(false)
    const clonelayout = layout_.map(item => ({...item}));
    const item_right = _.find(clonelayout, function(item) {
      return item.h === oldItem.h && item.x===oldItem.w + oldItem.x;
    });
    try{
      clonelayout[_.findIndex(clonelayout,{'i':item_right.i})].w=clonelayout[_.findIndex(clonelayout,{'i':item_right.i})].w - newItem.w + oldItem.w;
      clonelayout[_.findIndex(clonelayout,{'i':item_right.i})].x=clonelayout[_.findIndex(clonelayout,{'i':item_right.i})].x + newItem.w - oldItem.w;
      if( newItem.x + newItem.w > item_right.w +item_right.x - 50 ) {
        for(let i in layout_) if(layout_[i].i===oldItem.i)layout_[i]=oldItem
        setlayout(layout_)
        return
      }else
        setlayout(clonelayout)
    }catch(e){
      console.log(e)
    }

  }

  function onDragStop(layout_, oldItem, newItem){
    setisDragging(false)
    console.log('onDragStop')
    const clonelayout=[...layout_]//_.cloneDeep(layout);
    const index=_.findIndex(layout,{'i':oldItem.i})

    let dragX=clonelayout[index].x
    let dragY=clonelayout[index].y
    let dragW=clonelayout[index].w

    //calculate whether move to next row of not
    let move_to_next_row=null
    if(newItem.i!=='adj_vertical'){
      if(oldItem.y==0){
        //item in first row
        let bottom_line=newItem.y+newItem.h
        let vertical_line=clonelayout[5].y+((LH1+LH2+LH3)-clonelayout[5].y)/2
        if(bottom_line>vertical_line){
          console.log('move to lower row')
          move_to_next_row='down'
        }
      }else{
        //item in second row
        let top_line=newItem.y
        let vertical_line=clonelayout[5].y/2
        if(top_line<vertical_line){
          console.log('move to upper row')
          move_to_next_row='up'
        }

      }
    }
    clonelayout[index].x=oldItem.x
    clonelayout[index].y=oldItem.y

    //handle adjust vertical line
    if(newItem.i==='adj_vertical'){
      console.log('new height:'+ dragY)
      let newHeight=dragY<MinH?MinH:dragY>MaxH?MaxH:dragY

      for(let i in clonelayout){
        let item=clonelayout[i]
        if(i!=index){
          if(item.y===0){
            item.h=newHeight
            item.minH=newHeight
            item.maxH=newHeight
          }else{
            item.y=newHeight+LH3
            item.h=(LH1+LH2)-newHeight
            item.minH=(LH1+LH2)-newHeight
            item.maxH=(LH1+LH2)-newHeight
          }
        }else{
          console.log('found line')
          item.y=newHeight
        }
      }
      resetResizeBar(clonelayout)
      setlayout(clonelayout)
      return

    }



    //handle Same row
    if(!move_to_next_row){
      for(let i in clonelayout){
        let item=clonelayout[i]
        //console.log(item)
        if(item.y===oldItem.y&&i!=index){
          if((dragX+dragW/2)>=item.x&&(dragX+dragW/2)<(item.x+item.w)){
            console.log('found move item:'+i)

            if(oldItem.x>item.x)
              clonelayout[index].x=item.x
            else
              clonelayout[index].x=item.x+item.w-clonelayout[index].w
            //need to move all items between dragx and item.x
            let Larger=oldItem.x>item.x?oldItem.x:item.x
            let Smaller=oldItem.x<item.x?oldItem.x:item.x
            console.log('Range: '+ Smaller+ ' -- '+ Larger)

            //start rearrange X
            for(let i_ in clonelayout){
              let item_=clonelayout[i_]
              if(item_.y===oldItem.y&&i_!=index){
                if(item_.x>=Smaller&&item_.x<=Larger){
                  if(oldItem.x>=item_.x){
                    item_.x+=dragW
                  }else{
                    item_.x-=dragW
                    if(item_.x<0){
                      alert('error')
                      console.log(oldItem.x)
                      console.log('1: '+ item_.x)
                      console.log('2: '+ dragW)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }else{
      //handle move to another row
      let up_count=0
      for(let i of clonelayout)
        if(i.y===0)
          up_count+=1

      if(move_to_next_row==='up'){
        if(up_count===4){
          console.log('only one item left in bottom row, dont move')
        }else{
          let copy_layout=JSON.parse(JSON.stringify(clonelayout))
          for(let i in clonelayout){
            let item=clonelayout[i]
            if(item.i==='adj_vertical'){
            }else if(i==index){
              item.x=0
              item.y=0
              item.h=clonelayout[5].y
              item.minH=clonelayout[5].y
              item.maxH=clonelayout[5].y
              item.w=900/(up_count+1)
            }else{
              if(item.y==0){
                let order_index=0
                for(let i_ in copy_layout){
                  let item_=copy_layout[i_]
                  if(i_!=index&&item_.y==0&&item_.x<=item.x)
                    order_index+=1
                }
                item.x=order_index*900/(up_count+1)
                item.w=900/(up_count+1)
              }else{
                let order_index=0
                for(let i_ in copy_layout){
                  let item_=copy_layout[i_]
                  if(i_!=index&&item_.y!=0&&i!=i_&&item_.i!=='adj_vertical'&&item_.x<=item.x)
                    order_index+=1
                }
                item.x=order_index*900/(4-up_count)
                item.w=900/(4-up_count)

              }
            }
          }
        }
      }else{
        if(up_count===1){
          console.log('only one item left on top row, dont move')
        }else{
          let copy_layout=JSON.parse(JSON.stringify(clonelayout))
          for(let i in clonelayout){
            let item=clonelayout[i]
            if(item.i==='adj_vertical'){
            }else if(i==index){
              item.x=0
              item.y=clonelayout[5].y+LH3
              item.h=(LH1+LH2)-clonelayout[5].y
              item.minH=(LH1+LH2)-clonelayout[5].y
              item.maxH=(LH1+LH2)-clonelayout[5].y
              item.w=900/(5-up_count+1)
            }else{
              if(item.y!==0&&item.i!=='adj_vertical'){
                let order_index=0
                for(let i_ in copy_layout){
                  let item_=copy_layout[i_]
                  if(i_!=index&&item_.y!==0&&item_.i!=='adj_vertical'&&item_.x<=item.x)
                    order_index+=1
                }
                item.x=order_index*900/(5-up_count+1)
                item.w=900/(5-up_count+1)
              }else if(item.i!=='adj_vertical'){
                let order_index=0
                for(let i_ in copy_layout){
                  let item_=copy_layout[i_]
                  if(i_!=index&&i!=i_&&item_.y==0&&item_.x<=item.x)
                    order_index+=1
                }
                item.x=order_index*900/(up_count-1)
                item.w=900/(up_count-1)

              }
            }
          }
        }
      }
    }
    resetResizeBar(clonelayout)
    setlayout(clonelayout)
  }
  function onDrag(layout_, oldItem, newItem){setisDragging(true)}
  function onResizeStart(layout_, oldItem, newItem){setisDragging(true)}

  function resetResizeBar(layout){
    for(let i in layout){
      if(layout[i].i!=='adj_vertical'&&layout[i].w+layout[i].x!==900)
        layout[i].resizeHandles=['e']
      else
        layout[i].resizeHandles=[]
    }
  }


  //Quick layout
  const [layoutoutIndex,setlayoutoutIndex]=useState(4)


  //Watch List folder Variables
  const [isEditListPanel,setisEditListPanel]=useState(false)
  const isEditListPanelRef=useRef(isEditListPanel)
  isEditListPanelRef.current=isEditListPanel
  const [editListPanelFolderIndex,seteditListPanelFolderIndex]=useState(null)
  const editListPanelFolderIndexRef=useRef(editListPanelFolderIndex)
  editListPanelFolderIndexRef.current=editListPanelFolderIndex
  const [editListPanelListIndex,seteditListPanelListIndex]=useState(null)
  const editListPanelListIndexRef=useRef(editListPanelListIndex)
  editListPanelListIndexRef.current=editListPanelListIndex
  const [editListPanelList,seteditListPanelList]=useState([])
  const editListPanelListRef=useRef(editListPanelList)
  editListPanelListRef.current=editListPanelList
  const handleStartEditListPanel=(item,index,watchlist,watchlist_index)=>()=>{
    //if(isfetchStock)return
    seteditListPanelList([])
    setisEditListPanel(true)
    isEditListPanelRef.current=true
    seteditListPanelFolderIndex(index)
    editListPanelFolderIndexRef.current=index
    seteditListPanelListIndex(watchlist_index)
    editListPanelListIndexRef.current=watchlist_index
    handleCancelEditCriteria()
    handleIsEditingFolder(item,index,true)()
    handleIsEditingWatchlist(item,index,watchlist,watchlist_index,true)()
    //setisfetchStock(true)
    ReqBlockFinFigure(websocket,MarketIDRef.current,2,null,null,watchlist.watchlistID,5,301)
  }
  const handleCancelEditListPanel=()=>{
    seteditListPanelList([])
    seteditListPanelFolderIndex(null)
    editListPanelFolderIndexRef.current=null
    seteditListPanelListIndex(null)
    editListPanelListIndexRef.current=null
    setisEditListPanel(false)
    isEditListPanelRef.current=false
  }
  const handleselectDeleteStock=(item,index)=>()=>{
    let list=[...editListPanelList]
    list[index].isDelete=!list[index].isDelete
    seteditListPanelList(list)
  }
  const handleDeleteStock=()=>{
    let array=[]
    for(let i of editListPanelList)
      if(i.isDelete)
        array.push({stockCode:i.stockCode,marketID:i.marketID||1})
    if(array.length===0){
      handleCancelEditListPanel()
      return
    }
    ReqModWatchlistStocks(websocket,watchListFolder[editListPanelFolderIndex].watchlist[editListPanelListIndex].watchlistID,3,null,null,array,311)
  }
  const [watchListFolder,setwatchListFolder]=useState([])
  const watchListFolderRef=useRef(watchListFolder)
  watchListFolderRef.current=watchListFolder
  const [isCreateListFolder,setisCreateListFolder]=useState(false)
  const [newWatchListFolderName,setnewWatchListFolderName]=useState('')
  const [isModifyListFolder,setisModifyListFolder]=useState(false)
  const [modifyListFolderIndex,setmodifyListFolderIndex]=useState(null)
  const handleStartModifyListFolder=(item,index)=>()=>{
    setisModifyListFolder(true)
    setnewWatchListFolderName(item.watchlistFolderName)
    setmodifyListFolderIndex(index)
    handleIsEditingFolder(item,index,true)()
  }
  const handleStartCreateFolder=()=>{
    setisCreateListFolder(true)
    setnewWatchListFolderName('')
  }
  const handeCancelCreateFolder=()=>{
    setisCreateListFolder(false)
    setisModifyListFolder(false)
    setmodifyListFolderIndex(null)
    setnewWatchListFolderName('')
  }
  const handleInputNewFolerName=(e)=>{ setnewWatchListFolderName(e.target.value) }
  const handleCreateFolder=()=>{
    if(isCreateListFolder)
      ReqAddWatchlistFolder(websocket,newWatchListFolderName)
    else
      ReqAddWatchlistFolder(websocket,newWatchListFolderName,watchListFolder[modifyListFolderIndex].watchlistFolderID)
  }
  const handleExpandFolder=(item,index)=>()=>{
    let l=[...watchListFolder]
    l[index].isExpanded=!l[index].isExpanded
    l[index].isEditing=false
    for(let i in l[index].watchlist)
      l[index].watchlist[i].isEditing=false
    for(let i in l)
      if(i!=index){
        l[i].isEditing=false
        l[i].isExpanded=false
      }
    setwatchListFolder(l)

    let sss=[...screenListFolder]
    for(let i of sss){
      i.isEditing=false
      for(let j of i.screenList)
        j.isEditing=false
    }
    setscreenListFolder(sss)



    setisEditPopUpMask(false)
    watchListFolderRef.current=l
  }

  const [isDeleteingFolder,setisDeleteingFolder]=useState(false)
  const [selectedFolderIndex,setselectedFolderIndex]=useState(null)
  const selectedFolderIndexRef=useRef(selectedFolderIndex)
  selectedFolderIndexRef.current=selectedFolderIndex
  const handleStartDeleteFolder=(item,index)=>()=>{
    setisDeleteingFolder(true)
    setselectedFolderIndex(index)
    selectedFolderIndexRef.current=index
    handleIsEditingFolder(item,index,true)()
  }
  const handeCancelDeleteFolder=()=>{
    setisDeleteingFolder(false)
    setselectedFolderIndex(null)
    selectedFolderIndexRef.current=null
  }
  const handleIsEditingFolder=(item,index,close=false)=>()=>{
    let sss=[...screenListFolder]
    for(let i of sss){
      i.isEditing=false
      for(let j of i.screenList)
        j.isEditing=false
    }
    setscreenListFolder(sss)


    let l=[...watchListFolder]
    for(let i in l){
      for(let j of l[i].watchlist)
        j.isEditing=false

      if(i!=index)
        l[i].isEditing=false
    }
    let boo=close?false:!l[index].isEditing
    l[index].isEditing=boo
    if(boo)
      setisEditPopUpMask(true)
    else
      setisEditPopUpMask(false)
    setwatchListFolder(l)
    watchListFolderRef.current=l
  }

  const handleDeleteFolder=()=>{ ReqDeleteWatchlistFolder(websocket,watchListFolder[selectedFolderIndex].watchlistFolderID) }
  const [isCreateWatchList,setisCreateWatchList]=useState(false)
  const [newWatchlistName,setnewWatchlistName]=useState('')
  const [newListFolderIndex,setnewListFolderIndex]=useState(null)
  const [isModifyWatchList,setisModifyWatchList]=useState(false)
  const [modifyWatchListIndex,setmodifyWatchListIndex]=useState(null)
  const [isDirectAddWatchList,setisDirectAddWatchList]=useState(false)

  const handleStartModifyWatchList=(item,index,watchlist,watchlist_index)=>()=>{
    setisModifyWatchList(true)
    setnewWatchlistName(watchlist.watchlistName)
    setnewListFolderIndex(index)
    setmodifyWatchListIndex(watchlist_index)
    handleIsEditingWatchlist(item,index,watchlist,watchlist_index,true)()
  }
  const handleSetnewListFolderIndex=(item,index)=>{
    setnewListFolderIndex(item.data)
  }


  const handleStartCreateWatchList=()=>{
    let index=null
    for(let i in watchListFolder)
      if(watchListFolder[i].isExpanded)
        index=i
    // if(index===null){
    //   alert('Please select a folder')
    //   return
    // }
    setisCreateWatchList(true)
    setnewWatchlistName('')
    setnewListFolderIndex(index)
  }
  const handeCancelCreateWatchList=()=>{
    setisDirectAddWatchList(false)
    setisCreateWatchList(false)
    setisModifyWatchList(false)
    setnewWatchlistName('')
    setmodifyWatchListIndex(null)
    setnewListFolderIndex(null)
  }
  const handleInputNewWatchlistName=(e)=>{ setnewWatchlistName(e.target.value) }
  const handleCreateWatchList=()=>{
    if(isDirectAddWatchList)
      ReqAddWatchlist(websocket,newWatchlistName,newListFolderIndex?watchListFolder[newListFolderIndex].watchlistFolderID:null,null,901)
    else if(isCreateWatchList)
      ReqAddWatchlist(websocket,newWatchlistName,newListFolderIndex?watchListFolder[newListFolderIndex].watchlistFolderID:null,null,1)
    else
      ReqAddWatchlist(websocket,newWatchlistName,watchListFolder[newListFolderIndex].watchlistFolderID===undefined?null:watchListFolder[newListFolderIndex].watchlistFolderID,watchListFolder[newListFolderIndex].watchlist[modifyWatchListIndex].watchlistID)
  }
  const [isDeleteingWatchList,setisDeleteingWatchList]=useState(false)
  const [selectedWatchListIndex,setselectedWatchListIndex]=useState(null)
  const selectedWatchListIndexRef=useRef(selectedWatchListIndex)
  selectedWatchListIndexRef.current=selectedWatchListIndex
  const handleStartDeleteWatchList=(item,index,watchlist,watchlist_index)=>()=>{
    setisDeleteingWatchList(true)
    setselectedWatchListIndex(watchlist_index)
    selectedWatchListIndexRef.current=watchlist_index
    setselectedFolderIndex(index)
    selectedFolderIndexRef.current=index
    handleIsEditingWatchlist(item,index,watchlist,watchlist_index,true)()
  }
  const handeCancelDeleteWatchList=()=>{
    setisDeleteingWatchList(false)
    setselectedWatchListIndex(null)
    selectedWatchListIndexRef.current=null
    setselectedFolderIndex(null)
    selectedFolderIndexRef.current=null
  }
  const handleIsEditingWatchlist=(item,index,watchlist,watchlist_index,close=false)=>()=>{
    let sss=[...screenListFolder]
    for(let i of sss){
      i.isEditing=false
      for(let j of i.screenList)
        j.isEditing=false
    }
    setscreenListFolder(sss)

    let l=[...watchListFolder]
    for(let i of l)
      i.isEditing=false
    for(let i in l[index].watchlist)
      if(i!=watchlist_index)
        l[index].watchlist[i].isEditing=false

    let boo=close?false:!l[index].watchlist[watchlist_index].isEditing
    l[index].watchlist[watchlist_index].isEditing=boo
    if(boo)
      setisEditPopUpMask(true)
    else
      setisEditPopUpMask(false)

    setwatchListFolder(l)
    watchListFolderRef.current=l
  }
  const handleDeleteWatchlist=()=>{
    ReqDeleteWatchlist(websocket,watchListFolder[selectedFolderIndex].watchlist[selectedWatchListIndex].watchlistID)
  }
  const handleSelectWatchList=(item,index,watchlist,watchlist_index)=>()=>{
    if(isfetchStock)return
    setstockList([])
    stockListRef.current=[]
    let slist={
      type:'list',
      index:index,
      index2:watchlist_index,
      value:watchlist
    }
    setSelectedList(slist)
    SelectedListRef.current=slist
    //ReqWatchlistStocks(websocket,watchlist.watchlistID)
    setisfetchStock(true)
    ReqBlockFinFigure(websocketRef.current,MarketIDRef.current,2,null,null,watchlist.watchlistID,5)
  }
  const [isAddStocktoWatchList,setisAddStocktoWatchList]=useState(false)
  const [newStockCode,setnewStockCode]=useState('')
  const handleStartAddStocktoWatchList=(item,index,watchlist,watchlist_index)=>()=>{
    setisAddStocktoWatchList(true)
    setselectedWatchListIndex(watchlist_index)
    selectedWatchListIndexRef.current=watchlist_index
    setselectedFolderIndex(index)
    selectedFolderIndexRef.current=index
    setnewStockCode('')
  }
  const handleCancelAddStocktoWatchList=()=>{
    setisAddStocktoWatchList(false)
    setselectedWatchListIndex(null)
    selectedWatchListIndexRef.current=null
    setselectedFolderIndex(null)
    selectedFolderIndexRef.current=null
    setnewStockCode('')
  }
  const handleInputnewStockCode=(e)=>{ setnewStockCode(e.target.value) }
  const handleAddStocktoWatchList=()=>{
    if(HKstockListDict[newStockCode]===undefined){
      alert('invalid stock code')
      return
    }
    ReqModWatchlistStocks(websocket,watchListFolder[selectedFolderIndex].watchlist[selectedWatchListIndex].watchlistID,0,1,newStockCode)
  }
  const handleDirectAddNewList=()=>{
    if(stockList.length===0){
      alert('No stock in the list')
      return
    }
    let index=null
    for(let i in watchListFolder)
      if(watchListFolder[i].isExpanded)
        index=i
    setisDirectAddWatchList(true)
    setnewWatchlistName('')
    setnewListFolderIndex(index)
  }

  //Screens folder Variables
  const [SelectedGroup,setSelectedGroup]=useState(-1)
  const [screenListFolder,setscreenListFolder]=useState([])
  const screenListFolderRef=useRef(screenListFolder)
  screenListFolderRef.current=screenListFolder

  const [isCreateScreenFolder,setisCreateScreenFolder]=useState(false)
  const [newScreenFolderName,setnewScreenFolderName]=useState('')
  const [isModifyScreenFolder,setisModifyScreenFolder]=useState(false)
  const [modifyScreenFolderIndex,setmodifyScreenFolderIndex]=useState(null)

  const handleSetnewScreenFolderIndex=(item,index)=>{
    setnewScreenFolderIndex(item.data)
  }
  const handleStartCreateScreenFolder=()=>{
    setisCreateScreenFolder(true)
    setnewScreenFolderName('')
  }
  const handleStartModifyScreenFolder=(item,index)=>()=>{
    setisModifyScreenFolder(true)
    setnewScreenFolderName(item.folderName)
    setmodifyScreenFolderIndex(index)
    handleIsEditingScreenFolder(item,index,true)()
  }
  const handeCancelCreateScreenFolder=()=>{
    setisCreateScreenFolder(false)
    setisModifyScreenFolder(false)
    setnewScreenFolderName('')
    setmodifyScreenFolderIndex(null)
  }
  const handleInputNewScreenFolerName=(e)=>{
    setnewScreenFolderName(e.target.value)
  }
  const handleCreateScreenFolder=()=>{
    if(isCreateScreenFolder)
      ReqAddScreenFolder(websocket,newScreenFolderName)
    else if(isModifyScreenFolder)
      ReqAddScreenFolder(websocket,newScreenFolderName,screenListFolder[modifyScreenFolderIndex].folderID)
  }
  const handleExpandScreenFolder=(item,index)=>()=>{
    let lll=[...watchListFolder]
    for(let i of lll){
      i.isEditing=false
      for(let j of i.watchlist)
        j.isEditing=false
    }
    setwatchListFolder(lll)



    let l=[...screenListFolder]

    l[index].isExpanded=!l[index].isExpanded
    l[index].isEditing=false
    for(let i in l[index].screenList)
      l[index].screenList[i].isEditing=false
    for(let i in l)
      if(i!=index){
        l[i].isEditing=false
        l[i].isExpanded=false
      }
    setscreenListFolder(l)
    setisEditPopUpMask(false)
  }
  const [isDeleteingScreenFolder,setisDeleteingScreenFolder]=useState(false)
  const [selectedScreenFolderIndex,setselectedScreenFolderIndex]=useState(null)
  const selectedScreenFolderIndexRef=useRef(selectedScreenFolderIndex)
  selectedScreenFolderIndexRef.current=selectedScreenFolderIndex
  const handleStartDeleteScreenFolder=(item,index)=>()=>{
    setisDeleteingScreenFolder(true)
    setselectedScreenFolderIndex(index)
    handleIsEditingScreenFolder(item,index,true)()
  }
  const handeCancelDeleteScreenFolder=()=>{
    setisDeleteingScreenFolder(false)
    setselectedScreenFolderIndex(null)
  }
  const handleIsEditingScreenFolder=(item,index,close=false)=>()=>{
    let lll=[...watchListFolder]
    for(let i of lll){
      i.isEditing=false
      for(let j of i.watchlist)
        j.isEditing=false
    }
    setwatchListFolder(lll)


    let l=[...screenListFolder]
    for(let i in l){
      for(let j of l[i].screenList)
        j.isEditing=false

      if(i!=index)
        l[i].isEditing=false
    }

    let boo=close?false:!l[index].isEditing
    l[index].isEditing=boo
    if(boo)
      setisEditPopUpMask(true)
    else
      setisEditPopUpMask(false)

    setscreenListFolder(l)
  }
  const handleDeleteScreenFolder=()=>{
    ReqDeleteScreenFolder(websocket,screenListFolder[selectedScreenFolderIndex].folderID)
  }
  const [isCreateScreen,setisCreateScreen]=useState(false)
  const [isModifyScreen,setisModifyScreen]=useState(false)
  const [newScreenName,setnewScreenName]=useState('')
  const [newScreenFolderIndex,setnewScreenFolderIndex]=useState(null)
  const [modifyScreenIndex,setmodifyScreenIndex]=useState(null)
  const handleStartCreateScreen=()=>{
    let index=null
    for(let i in screenListFolder)
      if(screenListFolder[i].isExpanded)
        index=i
    // if(index===null){
    //   alert('Please select a folder')
    //   return
    // }
    setisCreateScreen(true)
    setnewScreenName('')
    setnewScreenFolderIndex(index)
  }
  const handleStartModifyScreen=(item,index,screen,screen_index)=>()=>{
    setisModifyScreen(true)
    setnewScreenName(screen.screenName)
    setnewScreenFolderIndex(index)
    setmodifyScreenIndex(screen_index)
    handleIsEditingscreen(item,index,screen,screen_index,true)()
  }
  const handeCancelCreateScreen=()=>{
    setisCreateScreen(false)
    setisModifyScreen(false)
    setnewScreenFolderIndex(null)
    setmodifyScreenIndex(null)
    setnewScreenName('')
  }
  const handleInputNewScreenName=(e)=>{
    setnewScreenName(e.target.value)
  }
  const handleCreateScreen=()=>{
    if(isCreateScreen)
      ReqAddScreen(websocket,newScreenName,newScreenFolderIndex?screenListFolder[newScreenFolderIndex].folderID:null)
    else if(isModifyScreen)
      ReqAddScreen(websocket,newScreenName,screenListFolder[newScreenFolderIndex].folderID===undefined?null:screenListFolder[newScreenFolderIndex].folderID,screenListFolder[newScreenFolderIndex].screenList[modifyScreenIndex].screenID)
  }
  const [isDeleteingScreen,setisDeleteingScreen]=useState(false)
  const [selectedScreenIndex,setselectedScreenIndex]=useState(null)
  const selectedScreenIndexRef=useRef(selectedScreenIndex)
  selectedScreenIndexRef.current=selectedScreenIndex
  const handleStartDeletescreen=(item,index,screen,screen_index)=>()=>{
    setisDeleteingScreen(true)
    setselectedScreenIndex(screen_index)
    setselectedScreenFolderIndex(index)
    handleIsEditingscreen(item,index,screen,screen_index,true)()
  }
  const handeCancelDeleteScreen=()=>{
    setisDeleteingScreen(false)
    setselectedScreenIndex(null)
    setselectedScreenFolderIndex(null)
  }
  const handleIsEditingscreen=(item,index,screen,screen_index,close=false)=>()=>{
    let lll=[...watchListFolder]
    for(let i of lll){
      i.isEditing=false
      for(let j of i.watchlist)
        j.isEditing=false
    }
    setwatchListFolder(lll)


    let l=[...screenListFolder]
    for(let i of l)
      i.isEditing=false
    for(let i in l[index].screenList)
      if(i!=screen_index)
        l[index].screenList[i].isEditing=false

    let boo=close?false:!l[index].screenList[screen_index].isEditing
    l[index].screenList[screen_index].isEditing=boo
    if(boo)
      setisEditPopUpMask(true)
    else
      setisEditPopUpMask(false)

    setscreenListFolder(l)
  }
  const handleDeleteScreen=()=>{
    ReqDeleteScreen(websocket,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID)
  }
  const handleSelectScreen=(item,index,screen,screen_index)=>()=>{
    if(isfetchStock)return
    setstockList([])
    stockListRef.current=[]
    let slist={
      type:'screen',
      index:index,
      index2:screen_index,
      value:screen
    }
    setSelectedList(slist)
    SelectedListRef.current=slist
    setisfetchStock(true)
    ReqBlockFinFigure(websocket,MarketIDRef.current,1,null,screenListFolder[index].screenList[screen_index].screenID,null,5)
    //ReqScreenFilteringResult(websocket,screenListFolder[index].screenList[screen_index].screenID,MarketID)
  }
  const [isEditingCriteria,setisEditingCriteria]=useState(false)
  const isEditingCriteriaRef=useRef(isEditingCriteria)
  isEditingCriteriaRef.current=isEditingCriteria
  const handleStartEditCriteria=(item,index,screen,screen_index)=>()=>{
    setisEditingCriteria(true)
    isEditingCriteriaRef.current=true
    setselectedScreenIndex(screen_index)
    setselectedScreenFolderIndex(index)
    ReqScreenCriteria(websocket,screenListFolder[index].screenList[screen_index].screenID)
    handleIsEditingscreen(item,index,screen,screen_index,true)()
    setSelectedGroup(-1)
    handleCancelEditListPanel()
    ReqScreenFilteringResult(websocket,screenListFolder[index].screenList[screen_index].screenID,MarketID)
  }
  const handleCancelEditCriteria=()=>{
    setisEditingCriteria(false)
    isEditingCriteriaRef.current=false
    setselectedScreenIndex(null)
    setselectedScreenFolderIndex(null)
  }

  //Edit Panel Function
  const handelSetSelectedGroup=(item,index)=>()=>{
    setSelectedGroup(index)
  }
  const handleModifyFavCriteria=(item,index)=>()=>{
    if(FavoriteCriteriaList.includes(item.criteriaID))
      ReqModFavoriteCriteriaList(websocket,1,item.criteriaID)
    else
      ReqModFavoriteCriteriaList(websocket,0,item.criteriaID)
  }
  const [isQueryCreteria,setisQueryCreteria]=useState(false)
  const [QueryCreteria,setQueryCreteria]=useState({})
  const handleQueryCreteria=(item,index)=>()=>{
    setisQueryCreteria(true)
    setQueryCreteria(item)
  }
  const handleCancelQueryCreteria=()=>{
    setisQueryCreteria(false)
    setQueryCreteria({})
  }



  //List Function
  const handleClickStock=(item,index,init=false,boo=false,isViewScreen=false)=>()=>{
    setselectedPassFailScreen(null)
    setPassFailscreenResult([])
    // setdisplayingStock(item)
    // displayingStockRef.current=item
    // setindustryData({...item})
    // ReqFinFigure(websocketRef.current,item.marketID||1,item.stockCode)
    // ReqIndustryStockRanking(websocketRef.current,item.marketID||1,item.stockCode)
    // ReqChartData(websocketRef.current,item.marketID||1,item.stockCode,null,'D',Math.floor(new Date().getTime()/1000)-60*60*24*100)

    if(!isViewScreen){
      if(isEditingCriteriaRef.current||isEditListPanelRef.current||boo){
        setisEditingCriteria(false)
        isEditingCriteriaRef.current=false
        setisEditListPanel(false)
        isEditListPanelRef.current=false
        if(isMobileRef.current){

        } else {
          setTimeout(()=>{window.setStockCode(item.stockCode)},2000)
        }
      }else{

        if(init)
        {
          window.initOnReady(isMobileRef.current,item.stockCode,tradingViewCallback,changeStockCallback,'tv_chart_container',lang.lang,theme?'Dark':'Light',MarketID)
        }
        else if(isMobileRef.current){
          window.setStockCode(item.stockCode)
        }else{
          window.setStockCode(item.stockCode)

        }
      }
    }


  }

  const ForceUpdateStock=(stockCode)=>()=>{
    window.setStockCode(stockCode)
  }

  const handleCriteriaInput=(criteria,minmax=null)=>(e)=>{
    let list=[...SelectedCritiaList]
    let Numcheck=isNaN(e.target.value)
    //todo add num check
    // if(Numcheck){
    //   return
    // }
    // if(e.target.value===''){
    //     let arr=[]
    //     for(let i of list)
    //       if(i.criteriaID===criteria.criteriaID){
    //         i.matchingType=criteria.matchingType
    //         i.valueType=criteria.valueType
    //         if(minmax==='min'){
    //           i.minValue=''
    //           i.isUpdate=true
    //         } else if(minmax==='max'){
    //           i.maxValue=''
    //           i.isUpdate=true
    //         }
    //         if((!i.minValue&&!i.maxValue)||minmax===null){
    //           if(i.isNew)
    //             ;
    //           else{
    //             i.isDelete=true
    //             arr.push(i)
    //           }
    //         }else
    //           arr.push(i)
    //       } else
    //         arr.push(i)
    //     setSelectedCritiaList(arr)
    //     return
    // }
    for(let i of list)
      if(i.criteriaID===criteria.criteriaID){
        //found criteria
        i.matchingType=criteria.matchingType
        i.valueType=criteria.valueType
        i.isDelete=false
        i.isUpdate=true
        i.matchType=0
        if(minmax==='min')
          i.minValue=e.target.value
        else if(minmax==='max')
          i.maxValue=e.target.value
        else{
          i.matchedValues=[e.target.value]
          i.display=e.target.value
        }
        setSelectedCritiaList(list)
        return
      }
    //handle when not found criteria, add new to list
    let data={
      matchingType:criteria.matchingType,
      valueType:criteria.valueType,
      criteriaID:criteria.criteriaID,
      matchType:0,
      isNew:true,
      isDelete:false,
    }
    if(minmax==='min')
      data.minValue=e.target.value
    else if(minmax==='max')
      data.maxValue=e.target.value
    else{
      data.matchedValues=[e.target.value]
      data.display=e.target.value
    }
    list.push(data)
    setSelectedCritiaList(list)
  }

  const handleOnblur=(criteria)=>(e)=>{
    let isPercent=(criteria.valueType===2)
    if(criteria.matchingType===0){
      if(e.target.value===''){
        ReqModScreenCriteria(websocket,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID,1,[{criteriaID:criteria.criteriaID}],524)
        return
      }
      let criteriaList=[]
      let data=isPercent?(Number(e.target.value)/100) :e.target.value
      if(criteria.valueType===8){
        data=Number(e.target.value)||0
      }
      criteriaList.push({
        criteriaID:criteria.criteriaID,
        matchedValues:[data],
        matchType:0,
      })
      ReqModScreenCriteria(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,0,criteriaList,524)
    }else{
      let list=[...SelectedCritiaList]
      let arr=[]
      let boo=false
      for(let i of list){
        if(i.criteriaID===criteria.criteriaID){
          if((i.minValue===''||i.minValue===undefined)&&(i.maxValue===''||i.maxValue===undefined)){
            ReqModScreenCriteria(websocket,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID,1,[{criteriaID:i.criteriaID}],524)
            return
          }
          if((i.minValue!==''&&i.minValue!==undefined)||(i.maxValue!==''&&i.maxValue!==undefined)){
            let criteriaList=[]
            let d={
              criteriaID:i.criteriaID,
              matchType:0,
            }
            if(i.minValue!==''&&i.minValue!==undefined)d.minValue=(isPercent?Number(i.minValue)/100:Number(i.minValue))||0
            if(i.maxValue!==''&&i.maxValue!==undefined)d.maxValue=(isPercent?Number(i.maxValue)/100:Number(i.maxValue))||0
            criteriaList.push(d)
            ReqModScreenCriteria(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,0,criteriaList,524)
          }
          return
        }
      }
    }

  }

  const handleCriteriaMultiSelect=(criteria,criteria_index,item,index)=>()=>{
    let data=[]
    //check if alreay selecled

    for(let i of SelectedCritiaList)
      if(i.criteriaID===criteria.criteriaID)
        data=i.matchedValues



    let list=[...SelectedCritiaList]

    let arr=[]
    let remove=false
    for(let i of data)
      if(i===criteria.possibleValues[index]){
        remove=true
      }else{
        arr.push(i)
      }

    if(!remove)
      arr.push(criteria.possibleValues[index])
    let criteriaList=[]
    criteriaList.push({
      criteriaID:criteria.criteriaID,
      matchedValues:arr,
      matchType:0,
    })
    ReqModScreenCriteria(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,0,criteriaList,524)
  }

  const handleSelectCriteria=(criteria,criteria_index)=>(item,index)=>{
    let list=[...SelectedCritiaList]
    if(criteria.matchingType===0&&
        (criteria.valueType===3||criteria.valueType===4||criteria.valueType===5||criteria.valueType===6||criteria.valueType===7)
    ){
      let data=[]
      let display=item.value
      if(criteria.valueType===7){data=[item.marketID]}
      else if(criteria.valueType===3)data=[item.value==='Yes'?1:0]
      else if(criteria.valueType===4)data=[item.data]
      else if(criteria.valueType===5)data=[item.industryCode]
      else if(criteria.valueType===6)data=[item.sectorCode]
      //check if SelectedCritiaList already has this criteria
      // for(let i of list)
      //   if(i.criteriaID===criteria.criteriaID){
      //     //found criteria
      //     i.matchingType=criteria.matchingType
      //     i.valueType=criteria.valueType
      //     i.isUpdate=true
      //     i.matchType=0
      //     i.matchedValues=data
      //     i.display=display
      //     i.isDelete=false
      //     setSelectedCritiaList(list)
      //     return
      //   }
      //handle when not found criteria, add new to list
      // list.push({
      //   matchingType:criteria.matchingType,
      //   valueType:criteria.valueType,
      //   criteriaID:criteria.criteriaID,
      //   matchType:0,
      //   matchedValues:data,
      //   isNew:true,
      //   display:display,
      // })
      // setSelectedCritiaList(list)



      let criteriaList=[]
      if(criteria.valueType===8){
        for(let j in criteria.matchedValues)
          criteria.matchedValues[j]=Number(criteria.matchedValues[j])||0
      }
      criteriaList.push({
        criteriaID:criteria.criteriaID,
        matchedValues:data,
        matchType:0,
      })
      ReqModScreenCriteria(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,0,criteriaList,524)

    }else {
      alert('unknown')
    }
  }

  const handlePlusMinusButton=(criteria,boo=false)=>()=>{
    ReqModScreenCriteria(websocket,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID,1,[{criteriaID:criteria.criteriaID}],524)
    // let list=[...SelectedCritiaList]
    // let arr=[]
    // let boo=false
    // for(let i of list){
    //   if(i.criteriaID===criteria.criteriaID){
    //     boo=true
    //     if(i.isNew){
    //       ;//do not add to list
    //     }else{
    //       i.minValue=''
    //       i.maxValue=''
    //       i.matchedValues=''
    //       i.isHighlighted=false
    //       i.isDelete=true
    //       arr.push(i)
    //     }
    //   }else{
    //     arr.push(i)
    //   }
    // }
    // console.log(arr)
    // if(boo){
    //   setSelectedCritiaList(arr)
    //   return
    // }

    // let criteriaGroup[SelectedGroup].criteriaList[]
    // criteria.isHighlighted=true
    // setSelectedCritiaList(list)
  }
  const handleCriteriaReset=(criteria)=>()=>{
    handlePlusMinusButton(criteria)()
  }



  const handleViewScreenResult=(noDelete=false)=>()=>{
    if(isfetchStock)return
    setstockList([])
    stockListRef.current=[]
    let slist={
      type:'screen',
      index:selectedScreenFolderIndex,
      index2:selectedScreenIndex,
      value:screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex]
    }
    setSelectedList(slist)
    SelectedListRef.current=slist
    setisfetchStock(true)
    ReqBlockFinFigure(websocket,MarketIDRef.current,1,null,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID,null,5,601)
    //handleCancelEditCriteria()
    // let criteriaList=[]
    // let deletecriteriaList=[]
    // for(let i of SelectedCritiaListRef.current){
    //   if(i.isDelete){
    //     deletecriteriaList.push({
    //         criteriaID:i.criteriaID,
    //       })

    //   }else {
    //     if(i.isUpdate||i.isNew){
    //       if(i.matchingType===0){
    //         if(i.valueType===8){
    //           for(let j in i.matchedValues)
    //             i.matchedValues[j]=Number(i.matchedValues[j])||0
    //         }
    //         criteriaList.push({
    //           criteriaID:i.criteriaID,
    //           matchedValues:i.matchedValues,
    //           matchType:i.matchType,
    //         })
    //       }else if(i.matchingType===1){
    //         criteriaList.push({
    //           criteriaID:i.criteriaID,
    //           minValue:Number(i.minValue)||0,
    //           maxValue:Number(i.maxValue)||0,
    //           matchType:i.matchType,
    //         })
    //       }
    //     }
    //   }
    // }

    // if(!noDelete)
    //   if(deletecriteriaList.length>0){
    //     console.log(deletecriteriaList)
    //     ReqModScreenCriteria(websocket,screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenID,1,deletecriteriaList,criteriaList.length>0?522:521)
    //     return
    //   }

    // ReqModScreenCriteria(websocketRef.current,screenListFolderRef.current[selectedScreenFolderIndexRef.current].screenList[selectedScreenIndexRef.current].screenID,0,criteriaList,523)
  }


  const handleselectedPassFailScreen=(item,index)=>{
    if(displayingStock){
      setselectedPassFailScreen(item)
      ReqScreenPassFail(websocket,item.screenID,displayingStock.marketID||MarketIDRef.current,displayingStock.stockCode)

      ReqScreenCriteria(websocket,item.screenID,801)
    }else{
      console.log('none')
    }
  }


  const handleCollapseComponent=(id)=>()=>{
    let l=JSON.parse(JSON.stringify(layout))
    for(let component of l)
      if(component.i===id){

        //if only one element on the row, not allow any action
        let count=0
        for(let c of l)
          if(component.y===c.y)
            count+=1
        if(count===1)
          return


        if(!isCollapse(id)){
          if((component.x+component.w)===900){
            //find next component and upload the width and x coordinate
            for(let c of l)
              if(c.i!==id&&component.y===c.y&&(c.x+c.w)===(component.x)){
                c.w+=component.w-CW
              }
            component.x=900-CW
          }else{
            //find next component and upload the width and x coordinate
            for(let c of l)
              if(c.i!==id&&component.y===c.y&&c.x===(component.x+component.w)){
                c.w+=component.w-CW
                c.x=c.x-component.w+CW
              }
          }
          component.w=CW
          //component.maxW=CW
          //component.minW=CW

        }else{
          if((component.x+component.w)===900){
            //find next component and upload the width and x coordinate
            for(let c of l)
              if(c.i!==id&&component.y===c.y&&(c.x+c.w)===(component.x)){
                if(c.w-UCW+component.w<MinW){
                  console.log('not enough space to expand')
                  return
                }
                c.w=c.w-UCW+component.w
              }
            component.x-=UCW-component.w
            component.w=UCW
          }else{
            //find next component and upload the width and x coordinate
            for(let c of l)
              if(c.i!==id&&component.y===c.y&&c.x===(component.x+component.w)){
                if(c.w-UCW+component.w<MinW){
                  console.log('not enough space to expand')
                  return
                }
                c.w=c.w-UCW+component.w
                c.x=c.x+UCW-component.w
              }

            component.w=UCW
          }

          //component.maxW=MaxW
          //component.minW=MinW
        }
      }
    resetResizeBar(l)
    setlayout(l)

  }
  const isEndofRow=(id)=>{
    for(let component of layout)
      if(component.i===id)
        if(component.w+component.x===MaxW){
          return true
        }
    return false
  }
  const isCollapse=(id)=>{
    let threshold=10
    for(let component of layout)
      if(component.i===id)
        if(component.w<=(MinW+threshold)||component.w<=(CW+threshold)){
          return true
        }
    return false
  }
  const isVerticalCollapse=(id)=>{
    let threshold=10
    for(let component of layout)
      if(component.i===id)
        if(component.h<=(MinH+threshold)){
          return true
        }
    return false
  }
  const VerticalCollapse=()=>{
    let l=JSON.parse(JSON.stringify(layout))
    let isBottom=l[0].y===0?(l[0].h===MaxH):(l[0].y===(MaxH+LH3))
    for(let i of l)
      if(i.i==='adj_vertical'){
        if(isBottom){
          i.y=LH1
        }else{
          i.y=MaxH
        }
      }else if(i.y>0){
        if(isBottom){
          i.h=LH2
          i.minH=LH2
          i.maxH=LH2
          i.y=LH1+LH3
        }else{
          i.h=MinH
          i.minH=MinH
          i.maxH=MinH
          i.y=MaxH+LH3
        }
      }else if(i.y==0){
        if(isBottom){
          i.h=LH1
          i.minH=LH1
          i.maxH=LH1
        }else{
          i.h=MaxH
          i.minH=MaxH
          i.maxH=MaxH
        }
      }
    setlayout(l)
  }

  const isShowBottom=()=>{
    let l=JSON.parse(JSON.stringify(layout))
    let isBottom=l[0].y===0?(l[0].h===MaxH):(l[0].y===(MaxH+LH3))
    return isBottom;
  }

  const handleSelectIndustry=()=>{
    if(industryData.IndustryCode){
      if(isfetchStock)return
      setstockList([])
      stockListRef.current=[]
      let slist={
        type:'industry',
        index:null,
        index2:null,
        value:industryData.industryName
      }
      setSelectedList(slist)
      SelectedListRef.current=slist
      setisfetchStock(true)
      ReqBlockFinFigure(websocket,MarketIDRef.current,3,null,null,null,5,1,industryData.IndustryCode)
    }
  }
  const handleSelectSector=()=>{
    if(industryData.SectorCode){
      if(isfetchStock)return
      setstockList([])
      stockListRef.current=[]
      let slist={
        type:'sector',
        index:null,
        index2:null,
        value:industryData.SectorName
      }
      setSelectedList(slist)
      SelectedListRef.current=slist
      setisfetchStock(true)
      ReqBlockFinFigure(websocket,MarketIDRef.current,4,null,null,null,5,1,null,industryData.SectorCode)
    }
  }
  const restartTradingView=()=>{ window.restartTradingView();setTimeout(()=>window.setTradingTheme(theme?'Dark':'Light'),1000)}
  const handleTheme=()=>{
    if(!isEditingCriteria&&!isEditListPanel){
      if(isMobileRef.current){

      }else{
        window.setTradingTheme(theme?'Light':'Dark');
      }
    }
    settheme(theme?0:1);
  }
  const ForceUpdateTheme=(thm)=>{window.setTradingTheme(thm?'Dark':'Light')}



  //handle sorting...
  const [sortType,setsortType]=useState(null)
  const [sortOrder,setsortOrder]=useState(true)
  const handleClickSort=(type)=>()=>{
    setisfetchStock(true)
    let L=[...stockList]
    if(sortType!==type){
      setsortType(type)
      setsortOrder(true)

      if(type==='SYMBOL')
        L.sort((a, b)=>{ return a.stockCode < b.stockCode ? -1 : a.stockCode > b.stockCode ? 1 :0})
      else if(type==='NAME')
        L.sort((a, b)=>{
          let A= HKstockListDict[a.stockCode]&&HKstockListDict[a.stockCode].stockName||''
          let B= HKstockListDict[b.stockCode]&&HKstockListDict[b.stockCode].stockName||''
          return A < B ? -1 : A > B ? 1 :0
        })
      else {
        let list = L.filter(item=>item[type] !== undefined)
        list.sort((a, b)=>{
          let A= a[type]||0
          let B= b[type]||0
          return A < B ? -1 : A > B ? 1 :0
        })
        L = list.concat(L.filter(item=>item[type] === undefined))
      }

    }else{
      if(type==='SYMBOL'&&sortOrder){
        L.sort((a, b)=>{ return a.stockCode > b.stockCode ? -1 : a.stockCode < b.stockCode ? 1 :0})
      }else if(type==='NAME'&&sortOrder){
        L.sort((a, b)=>{
          let A= HKstockListDict[a.stockCode]&&HKstockListDict[a.stockCode].stockName||''
          let B= HKstockListDict[b.stockCode]&&HKstockListDict[b.stockCode].stockName||''
          return A > B ? -1 : A < B ? 1 :0
        })
      }else if(sortOrder){
        let list = L.filter(item=>item[type] !== undefined)
        list.sort((a, b)=>{
          let A= a[type]||0
          let B= b[type]||0
          return A > B ? -1 : A < B ? 1 :0
        })
        L = list.concat(L.filter(item=>item[type] === undefined))
      }else{
        setsortType(null)
        setsortOrder(true)
        L.sort((a, b)=>{ return a.stockCode < b.stockCode ? -1 : a.stockCode > b.stockCode ? 1 :0})
        setstockList(L)
        stockListRef.current=L
        setisfetchStock(false)
        return
      }
      setsortOrder(!sortOrder)
    }
    setstockList(L)
    stockListRef.current=L
    setisfetchStock(false)
  }

  const handleCancelMask=()=>{
    let lll=[...watchListFolder]
    for(let i of lll){
      i.isEditing=false
      for(let j of i.watchlist)
        j.isEditing=false
    }
    setwatchListFolder(lll)

    let sss=[...screenListFolder]
    for(let i of sss){
      i.isEditing=false
      for(let j of i.screenList)
        j.isEditing=false
    }
    setscreenListFolder(sss)

    setisEditPopUpMask(false)
  }

  useEffect(() => {
    const _handleKeyDown = (event) => {
      switch( event.keyCode ) {
        case 32:
          event.preventDefault();
          if(!isEditingCriteriaRef.current&&!isEditListPanelRef.current&&stockListRef.current.length>0&&isLoginRef.current){
            for(let i in stockListRef.current){
              if(stockListRef.current[i].stockCode===displayingStockRef.current.stockCode){
                let nextIndex=Number(i)+1
                if(nextIndex===stockListRef.current.length)
                  nextIndex=0
                if(!isEditingCriteriaRef.current&&!isEditListPanelRef.current&&isLoginRef.current){
                  autoscrollRef.current[nextIndex].scrollIntoView({block: "start", inline: "nearest"})
                  handleClickStock(stockListRef.current[nextIndex],nextIndex)()
                }
                return
              }
            }
          }
          break;
        default:
          break;
      }
    }
    window.addEventListener('keydown', _handleKeyDown)
    return _ => {
      window.removeEventListener('keydown', _handleKeyDown)
    }
  })


  //Loop interval
  const [LoopInterval,setLoopInterval]=useState(5)
  const SetListLoopInterval=(s)=>{setLoopInterval(s)}

  if(!isLogin)
    return (
        <LoginPage
            isMobile={isMobile}
            handleLogin={handleLogin}
            handleUsername={handleUsername}
            hanldePassword={hanldePassword}
            username={username}
            password={password}
            LoginErrMessage={LoginErrMessage}
        />
    )
  if(isMobile)
    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%',height:'100vh',backgroundColor:theme?'#173246':'#FBFBFB',color:theme?'white':'black'}}>
          <MobileStock
              nextStock={nextStock}
              theme={theme}
              lang={lang}
              restartTradingView={restartTradingView}
              chartDataBars={chartDataBars}
              ForceUpdateTheme={ForceUpdateTheme}

              data={industryData}
              handleSelectIndustry={handleSelectIndustry}
              handleSelectSector={handleSelectSector}
              HKstockListDict={HKstockListDict}
              StockYearData={StockYearData}
              MarketID={MarketID}
          />
          <MobileList
              autoscrollRef={autoscrollRef}
              lang={lang}
              theme={theme}
              isfetchStock={isfetchStock}
              data={stockList}
              SelectedList={SelectedList}
              handleClickStock={handleClickStock}
              HKstockListDict={HKstockListDict}
              isPlaying={isPlaying}
              isLoop={isLoop}
              displayingStock={displayingStock}
              LastUpdatedTime={LastUpdatedTime}
              handleClickLoop={handleClickLoop(setisLoop,isLoop)}
              handleClickPlaying={handleClickPlaying(setisPlaying,isPlaying)}
              sortType={sortType}
              sortOrder={sortOrder}
              handleClickSort={handleClickSort}
              SetListLoopInterval={SetListLoopInterval}
              LoopInterval={LoopInterval}
              handleDirectAddNewList={handleDirectAddNewList}
              ForceUpdateStock={ForceUpdateStock}



          />
          <MobileFolders
              lang={lang}
              theme={theme}

              criteriaDict={criteriaDict}
              screenListFolder={screenListFolder}
              handleselectedPassFailScreen={handleselectedPassFailScreen}
              PassFailscreenResult={PassFailscreenResult}
              selectedPassFailScreen={selectedPassFailScreen}
              PassFailCritiaList={PassFailCritiaList}


              SelectedList={SelectedList}
              screenListFolder={screenListFolder}
              handleStartCreateScreenFolder={handleStartCreateScreenFolder}
              handleExpandScreenFolder={handleExpandScreenFolder}
              handleIsEditingScreenFolder={handleIsEditingScreenFolder}
              handleStartDeleteScreenFolder={handleStartDeleteScreenFolder}
              handleStartCreateScreen={handleStartCreateScreen}
              handleSelectScreen={handleSelectScreen}
              handleIsEditingscreen={handleIsEditingscreen}
              handleStartDeletescreen={handleStartDeletescreen}
              handleStartEditCriteria={handleStartEditCriteria}
              handleStartModifyScreenFolder={handleStartModifyScreenFolder}
              handleStartModifyScreen={handleStartModifyScreen}
              handleCollapseComponent={handleCollapseComponent('screens')}
              isEditPopUpMask={isEditPopUpMask}
              handleCancelMask={handleCancelMask}

              handleExpandFolder={handleExpandFolder}
              watchListFolder={watchListFolder}
              handleStartCreateFolder={handleStartCreateFolder}
              handleStartCreateWatchList={handleStartCreateWatchList}
              handleStartDeleteFolder={handleStartDeleteFolder}
              handleIsEditingFolder={handleIsEditingFolder}
              handleIsEditingWatchlist={handleIsEditingWatchlist}
              handleStartDeleteWatchList={handleStartDeleteWatchList}
              handleSelectWatchList={handleSelectWatchList}
              handleStartAddStocktoWatchList={handleStartAddStocktoWatchList}
              handleStartEditListPanel={handleStartEditListPanel}
              handleStartModifyListFolder={handleStartModifyListFolder}
              handleStartModifyWatchList={handleStartModifyWatchList}


          />
          <MobileSetting
              theme={theme}
              handleTheme={handleTheme}
              LangList={LangList}
              lang={lang}
              handleLang={handleLang(websocket, setlang)}
              handleLogout={handleLogout}
              handleMarket={handleMarket}
              MarketID={MarketID}
          />
          {isEditingCriteria&&<MobileCriteria
              theme={theme}
              lang={lang}
              criteriaGroup={criteriaGroup}
              handleCancelEditCriteria={handleCancelEditCriteria}
              FavoriteCriteriaList={FavoriteCriteriaList}
              SelectedCritiaList={SelectedCritiaList}
              SelectedGroup={SelectedGroup}
              handelSetSelectedGroup={handelSetSelectedGroup}
              marketList={marketList}
              industryList={industryList}
              SectorList={SectorList}
              handleModifyFavCriteria={handleModifyFavCriteria}
              handleSelectCriteria={handleSelectCriteria}
              criteriaDict={criteriaDict}
              handleQueryCreteria={handleQueryCreteria}
              handleCriteriaInput={handleCriteriaInput}
              handlePlusMinusButton={handlePlusMinusButton}
              handleViewScreenResult={handleViewScreenResult}
              handleCriteriaReset={handleCriteriaReset}
              CriteriaFilteringResult={CriteriaFilteringResult}
              onBlur={handleOnblur}
              VerticalCollapse={VerticalCollapse}
              handleCriteriaMultiSelect={handleCriteriaMultiSelect}
          />}
          {
              isEditListPanel&&<MobileEditList
                  theme={theme}
                  lang={lang}
                  handleCancelEditListPanel={handleCancelEditListPanel}
                  editListPanelList={editListPanelList}
                  handleselectDeleteStock={handleselectDeleteStock}
                  handleDeleteStock={handleDeleteStock}
                  editListPanelListIndex={editListPanelListIndex}
                  editListPanelFolderIndex={editListPanelFolderIndex}
                  watchListFolder={watchListFolder}
                  HKstockListDict={HKstockListDict}
                  VerticalCollapse={VerticalCollapse}
              />
          }
          {isCreateListFolder&&<CreateFolderPopUp lang={lang} theme={theme} handeCancelCreateFolder={handeCancelCreateFolder} handleCreateFolder={handleCreateFolder} handleInput={handleInputNewFolerName} value={newWatchListFolderName}/>}
          {isModifyListFolder&&<ModifyFolderPopUp lang={lang} theme={theme} handeCancelCreateFolder={handeCancelCreateFolder} handleCreateFolder={handleCreateFolder} handleInput={handleInputNewFolerName} value={newWatchListFolderName} currentName={watchListFolder[modifyListFolderIndex].watchlistFolderName}/>}
          {(isCreateWatchList||isDirectAddWatchList)&&<CreateWatchListPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateWatchList} handleConfirm={handleCreateWatchList} handleInput={handleInputNewWatchlistName} value={newWatchlistName} newListFolderIndex={newListFolderIndex} handleSetnewListFolderIndex={handleSetnewListFolderIndex} watchListFolder={watchListFolder}/>}
          {isModifyWatchList&&<ModifyWatchListPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateWatchList} handleConfirm={handleCreateWatchList} handleInput={handleInputNewWatchlistName} value={newWatchlistName} folderName={watchListFolder[newListFolderIndex].watchlistFolderName} currentName={watchListFolder[newListFolderIndex].watchlist[modifyWatchListIndex].watchlistName}/>}
          {isDeleteingFolder&&<DeleteFolderPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteFolder} handleConfirm={handleDeleteFolder} name={watchListFolder[selectedFolderIndex].watchlistFolderName}/>}
          {isDeleteingWatchList&&<DeleteWatchListPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteWatchList} handleConfirm={handleDeleteWatchlist} name={watchListFolder[selectedFolderIndex].watchlist[selectedWatchListIndex].watchlistName}/>}
          {isAddStocktoWatchList&&<AddStockToWatchListPopUp lang={lang} theme={theme} handleCancel={handleCancelAddStocktoWatchList} handleConfirm={handleAddStocktoWatchList} handleInput={handleInputnewStockCode} value={newStockCode} />}
          {isCreateScreenFolder&&<CreateScreenFolderPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreenFolder} handleConfirm={handleCreateScreenFolder} handleInput={handleInputNewScreenFolerName} value={newScreenFolderName}/>}
          {isModifyScreenFolder&&<ModifyScreenFolderPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreenFolder} handleConfirm={handleCreateScreenFolder} handleInput={handleInputNewScreenFolerName} value={newScreenFolderName} currentName={screenListFolder[modifyScreenFolderIndex].folderName}/>}
          {isDeleteingScreenFolder&&<DeleteScreenFolderPopup lang={lang}theme={theme} handleCancel={handeCancelDeleteScreenFolder} handleConfirm={handleDeleteScreenFolder} name={screenListFolder[selectedScreenFolderIndex].folderName}/>}
          {isCreateScreen&&<CreateScreenPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreen} handleConfirm={handleCreateScreen}  handleInput={handleInputNewScreenName} value={newScreenName} screenListFolder={screenListFolder} newScreenFolderIndex={newScreenFolderIndex} handleSetnewScreenFolderIndex={handleSetnewScreenFolderIndex} screenListFolder={screenListFolder}/>}
          {isModifyScreen&&<ModifyScreenPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreen} handleConfirm={handleCreateScreen}  handleInput={handleInputNewScreenName} value={newScreenName} folderName={screenListFolder[newScreenFolderIndex].folderName} currentName={screenListFolder[newScreenFolderIndex].screenList[modifyScreenIndex].screenName}/>}
          {isDeleteingScreen&&<DeleteScreenPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteScreen} handleConfirm={handleDeleteScreen} name={screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenName}/>}
          {isQueryCreteria&&<StockQueryPopup lang={lang}theme={theme} handleCancel={handleCancelQueryCreteria} QueryCreteria={QueryCreteria}/>}
          {isEditPopUpMask&&<div onClick={handleCancelMask} style={{zIndex:1,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0}}/>}

        </div>
    )
  return (
      <div
          className="App"
          style={{
            color:theme?'white':'black',
            height:'100vh',
            minHeight:'100vh',width:'100vw',
            position: 'relative',
            background: theme?'linear-gradient(180deg, #0D2739 0%, #17354B 64.06%, #22577D 100%)':'linear-gradient(180deg, #FBFBFB 0%, #F5F5F5 47.4%, #E1E1E1 75.52%, #FEFEFE 97.4%)',
            overflow:'hidden'
          }}
      >
        <Header
            handleSelectLayout={handleSelectLayout(setlayoutoutIndex,setlayout)}
            layoutoutIndex={layoutoutIndex}
            theme={theme}
            handleTheme={handleTheme}
            LangList={LangList}
            lang={lang}
            handleLang={handleLang(websocket, setlang)}
            handleMarket={handleMarket}
            MarketID={MarketID}
            handleLogout={handleLogout}
        />
        <ReactGridLayout
            className="layout"
            preventCollision={true}
            allowOverlap={true}
            isDraggable={true}
            layout={layout}
            cols={900}
            rowHeight={(height-48)/(LH1+LH2+LH3)}
            margin={[10,0]}
            maxRows={(LH1+LH2+LH3)}
            onResizeStart={onResizeStart}
            onResizeStop={onResize}
            onDrag={onDrag}
            onDragStop={onDragStop}
            style={{minHeight:'90vh',margin:0,padding:0}}
            draggableHandle=".drag-handle"
            resizeHandle={<MyHandle theme={theme}/>}
        >
          <div key="screens" style={{zIndex:2}}>
            <Screen
                theme={theme}
                lang={lang}
                isEndofRow={isEndofRow('screens')}
                isCollapse={isCollapse('screens')}
                isVerticalCollapse={isVerticalCollapse('screens')}
                data={marketList}
                SelectedList={SelectedList}

                screenListFolder={screenListFolder}
                handleStartCreateScreenFolder={handleStartCreateScreenFolder}
                handleExpandScreenFolder={handleExpandScreenFolder}
                handleIsEditingScreenFolder={handleIsEditingScreenFolder}
                handleStartDeleteScreenFolder={handleStartDeleteScreenFolder}
                handleStartCreateScreen={handleStartCreateScreen}
                handleSelectScreen={handleSelectScreen}
                handleIsEditingscreen={handleIsEditingscreen}
                handleStartDeletescreen={handleStartDeletescreen}
                handleStartEditCriteria={handleStartEditCriteria}
                handleStartModifyScreenFolder={handleStartModifyScreenFolder}
                handleStartModifyScreen={handleStartModifyScreen}
                handleCollapseComponent={handleCollapseComponent('screens')}
                isEditPopUpMask={isEditPopUpMask}
                handleCancelMask={handleCancelMask}
            />
          </div>
          <div key="b" style={{}}>
            { isEditListPanel
                ?<EditListPanel
                    theme={theme}
                    lang={lang}
                    handleCancelEditListPanel={handleCancelEditListPanel}
                    editListPanelList={editListPanelList}
                    handleselectDeleteStock={handleselectDeleteStock}
                    handleDeleteStock={handleDeleteStock}
                    editListPanelListIndex={editListPanelListIndex}
                    editListPanelFolderIndex={editListPanelFolderIndex}
                    watchListFolder={watchListFolder}
                    HKstockListDict={HKstockListDict}
                    VerticalCollapse={VerticalCollapse}
                />
                :isEditingCriteria
                    ?<Criteria
                        theme={theme}
                        lang={lang}
                        criteriaGroup={criteriaGroup}
                        handleCancelEditCriteria={handleCancelEditCriteria}
                        FavoriteCriteriaList={FavoriteCriteriaList}
                        SelectedCritiaList={SelectedCritiaList}
                        SelectedGroup={SelectedGroup}
                        handelSetSelectedGroup={handelSetSelectedGroup}
                        marketList={marketList}
                        industryList={industryList}
                        SectorList={SectorList}
                        handleModifyFavCriteria={handleModifyFavCriteria}
                        handleSelectCriteria={handleSelectCriteria}
                        criteriaDict={criteriaDict}
                        handleQueryCreteria={handleQueryCreteria}
                        handleCriteriaInput={handleCriteriaInput}
                        handlePlusMinusButton={handlePlusMinusButton}
                        handleViewScreenResult={handleViewScreenResult}
                        handleCriteriaReset={handleCriteriaReset}
                        CriteriaFilteringResult={CriteriaFilteringResult}
                        onBlur={handleOnblur}
                        VerticalCollapse={VerticalCollapse}
                        handleCriteriaMultiSelect={handleCriteriaMultiSelect}
                    />
                    :
                    <Chart
                        nextStock={nextStock}
                        theme={theme}
                        lang={lang}
                        restartTradingView={restartTradingView}
                        chartDataBars={chartDataBars}
                        isDragging={isDragging}
                        VerticalCollapse={VerticalCollapse}
                        LoopInterval={LoopInterval}
                        isShowBottom={isShowBottom}
                    />
            }
          </div>
          <div key="c" style={{}}>
            <IndustrySector
                theme={theme}
                lang={lang}
                isEndofRow={isEndofRow('c')}
                isCollapse={isCollapse('c')}
                isVerticalCollapse={isVerticalCollapse('c')}
                handleCollapseComponent={handleCollapseComponent('c')}
                screenListFolder={screenListFolder}
                selectedPassFailScreen={selectedPassFailScreen}
                handleselectedPassFailScreen={handleselectedPassFailScreen}
                data={industryData}
                criteriaDict={criteriaDict}
                PassFailscreenResult={PassFailscreenResult}
                handleSelectIndustry={handleSelectIndustry}
                handleSelectSector={handleSelectSector}
                HKstockListDict={HKstockListDict}
                StockYearData={StockYearData}
                MarketID={MarketID}
                PassFailCritiaList={PassFailCritiaList}
            />
          </div>
          <div key="d" style={{zIndex:2}}>
            <ListFolder
                lang={lang}
                theme={theme}
                isEndofRow={isEndofRow('d')}
                isCollapse={isCollapse('d')}
                isVerticalCollapse={isVerticalCollapse('d')}
                handleCollapseComponent={handleCollapseComponent('d')}
                SelectedList={SelectedList}
                handleExpandFolder={handleExpandFolder}
                watchListFolder={watchListFolder}
                handleStartCreateFolder={handleStartCreateFolder}
                handleStartCreateWatchList={handleStartCreateWatchList}
                handleStartDeleteFolder={handleStartDeleteFolder}
                handleIsEditingFolder={handleIsEditingFolder}
                handleIsEditingWatchlist={handleIsEditingWatchlist}
                handleStartDeleteWatchList={handleStartDeleteWatchList}
                handleSelectWatchList={handleSelectWatchList}
                handleStartAddStocktoWatchList={handleStartAddStocktoWatchList}
                handleStartEditListPanel={handleStartEditListPanel}
                handleStartModifyListFolder={handleStartModifyListFolder}
                handleStartModifyWatchList={handleStartModifyWatchList}
                isEditPopUpMask={isEditPopUpMask}
                handleCancelMask={handleCancelMask}
            />
          </div>
          <div key="e" style={{}}>
            <List
                autoscrollRef={autoscrollRef}
                lang={lang}
                theme={theme}
                isfetchStock={isfetchStock}
                isEndofRow={isEndofRow('e')}
                isCollapse={isCollapse('e')}
                isVerticalCollapse={isVerticalCollapse('e')}
                handleCollapseComponent={handleCollapseComponent('e')}
                data={stockList}
                SelectedList={SelectedList}
                handleClickStock={handleClickStock}
                HKstockListDict={HKstockListDict}
                isPlaying={isPlaying}
                isLoop={isLoop}
                displayingStock={displayingStock}
                LastUpdatedTime={LastUpdatedTime}
                handleClickLoop={handleClickLoop(setisLoop,isLoop)}
                handleClickPlaying={handleClickPlaying(setisPlaying,isPlaying)}
                sortType={sortType}
                sortOrder={sortOrder}
                handleClickSort={handleClickSort}
                SetListLoopInterval={SetListLoopInterval}
                LoopInterval={LoopInterval}
                handleDirectAddNewList={handleDirectAddNewList}
                layout={layout}
            />
          </div>
          <div className="drag-handle" key="adj_vertical" style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative'}}>
            <div style={{cursor:'ns-resize',width:'100%',height:'1px',backgroundColor:theme?'rgba(255, 255, 255, 0.1)':'rgba(0, 0, 0, 0.1)'}}/>
          </div>
        </ReactGridLayout>
        {isCreateListFolder&&<CreateFolderPopUp lang={lang} theme={theme} handeCancelCreateFolder={handeCancelCreateFolder} handleCreateFolder={handleCreateFolder} handleInput={handleInputNewFolerName} value={newWatchListFolderName}/>}
        {isModifyListFolder&&<ModifyFolderPopUp lang={lang} theme={theme} handeCancelCreateFolder={handeCancelCreateFolder} handleCreateFolder={handleCreateFolder} handleInput={handleInputNewFolerName} value={newWatchListFolderName} currentName={watchListFolder[modifyListFolderIndex].watchlistFolderName}/>}
        {(isCreateWatchList||isDirectAddWatchList)&&<CreateWatchListPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateWatchList} handleConfirm={handleCreateWatchList} handleInput={handleInputNewWatchlistName} value={newWatchlistName} newListFolderIndex={newListFolderIndex} handleSetnewListFolderIndex={handleSetnewListFolderIndex} watchListFolder={watchListFolder}/>}
        {isModifyWatchList&&<ModifyWatchListPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateWatchList} handleConfirm={handleCreateWatchList} handleInput={handleInputNewWatchlistName} value={newWatchlistName} folderName={watchListFolder[newListFolderIndex].watchlistFolderName} currentName={watchListFolder[newListFolderIndex].watchlist[modifyWatchListIndex].watchlistName}/>}
        {isDeleteingFolder&&<DeleteFolderPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteFolder} handleConfirm={handleDeleteFolder} name={watchListFolder[selectedFolderIndex].watchlistFolderName}/>}
        {isDeleteingWatchList&&<DeleteWatchListPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteWatchList} handleConfirm={handleDeleteWatchlist} name={watchListFolder[selectedFolderIndex].watchlist[selectedWatchListIndex].watchlistName}/>}
        {isAddStocktoWatchList&&<AddStockToWatchListPopUp lang={lang} theme={theme} handleCancel={handleCancelAddStocktoWatchList} handleConfirm={handleAddStocktoWatchList} handleInput={handleInputnewStockCode} value={newStockCode} />}
        {isCreateScreenFolder&&<CreateScreenFolderPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreenFolder} handleConfirm={handleCreateScreenFolder} handleInput={handleInputNewScreenFolerName} value={newScreenFolderName}/>}
        {isModifyScreenFolder&&<ModifyScreenFolderPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreenFolder} handleConfirm={handleCreateScreenFolder} handleInput={handleInputNewScreenFolerName} value={newScreenFolderName} currentName={screenListFolder[modifyScreenFolderIndex].folderName}/>}
        {isDeleteingScreenFolder&&<DeleteScreenFolderPopup lang={lang}theme={theme} handleCancel={handeCancelDeleteScreenFolder} handleConfirm={handleDeleteScreenFolder} name={screenListFolder[selectedScreenFolderIndex].folderName}/>}
        {isCreateScreen&&<CreateScreenPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreen} handleConfirm={handleCreateScreen}  handleInput={handleInputNewScreenName} value={newScreenName} screenListFolder={screenListFolder} newScreenFolderIndex={newScreenFolderIndex} handleSetnewScreenFolderIndex={handleSetnewScreenFolderIndex} screenListFolder={screenListFolder}/>}
        {isModifyScreen&&<ModifyScreenPopUp lang={lang} theme={theme} handleCancel={handeCancelCreateScreen} handleConfirm={handleCreateScreen}  handleInput={handleInputNewScreenName} value={newScreenName} folderName={screenListFolder[newScreenFolderIndex].folderName} currentName={screenListFolder[newScreenFolderIndex].screenList[modifyScreenIndex].screenName}/>}
        {isDeleteingScreen&&<DeleteScreenPopup lang={lang} theme={theme} handleCancel={handeCancelDeleteScreen} handleConfirm={handleDeleteScreen} name={screenListFolder[selectedScreenFolderIndex].screenList[selectedScreenIndex].screenName}/>}
        {isQueryCreteria&&<StockQueryPopup lang={lang}theme={theme} handleCancel={handleCancelQueryCreteria} QueryCreteria={QueryCreteria}/>}
        {isEditPopUpMask&&<div onClick={handleCancelMask} style={{zIndex:1,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0}}/>}
      </div>
  );
}






export default App;
