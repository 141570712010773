import React from 'react';
export default class ResizeHandler extends React.Component {
  render() {
    const {handleAxis, innerRef, theme,  ...props} = this.props;
    return(
      <div ref={innerRef} className={`foo handle-${handleAxis}`} {...props}  style={{position:'absolute',top:0,right:-5,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'ew-resize'}}>
        <div  style={{width:1,height:'95%',backgroundColor:theme?'rgba(255,255,255,.1)':'rgba(0,0,0,.1)'}}/>
      </div>
    )
  }
}