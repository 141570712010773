import React,{useState,useEffect,useRef} from 'react';
import {formatDate, formatTime} from './../util'
import {CollapseIcon_UP,CollapseIcon_Down} from '../icons'
import lang from '../lang.json'
import {RiseIcon} from '../icons'
import {fidID_Dict} from '../fidID_Dict'
function numberWithCommas(x) {
	// update tag
	if (x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}
const f8='8px'
function useInterval(callback, delay) {
	const intervalRef = React.useRef(null);
	const savedCallback = React.useRef(callback);
	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	React.useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === 'number') {
			intervalRef.current = window.setInterval(tick, delay);
			return () => window.clearInterval(intervalRef.current);
		}
	}, [delay]);
	return intervalRef;
}



export default function MobileStock(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	useInterval(() => {
		props.nextStock()
	}, 5000);
	useEffect(() => {
		props.restartTradingView()
	},[]);
	React.useEffect(() => {
		props.ForceUpdateTheme(props.theme)
	}, [props.theme]);

	const [isCollapsed,setisCollapsed]=useState(false)
	const handleisCollapsed=()=>{setisCollapsed(!isCollapsed)}

	const [isGraph,setisGraph]=useState(true)
	const handleisGraph=(boo)=>()=>{setisGraph(boo)}

	return(
		<div style={isCollapsed?{display:'flex',flexDirection:'column',width:'100%',color:props.theme?'white':'black'}:{display:'flex',flexDirection:'column',width:'100%',flex:1,color:props.theme?'white':'black'}}>
			<div className='mousehover' onClick={handleisCollapsed} style={{fontSize:f8,display:'flex',flexDirection:'row',justifyContent:'center',padding:'10px 0 10px 0'}}>
				{!isCollapsed?<CollapseIcon_UP theme={props.theme}/>:<CollapseIcon_Down theme={props.theme}/> }
				<div style={{color:isCollapsed?(props.theme?'#FFFFFF99':'#00000099'):(props.theme?'#FFFFFF4D':'#0000004D'),marginLeft:5}}>{isCollapsed?'Expand':'Collapse'}</div>
			</div>
			<div style={{width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',display:(isCollapsed)?'none':'flex'}}>
				<div onClick={handleisGraph(true)} className='mousehover' style={{flex:1,textAlign:'center',fontSize:f8,display:'flex',flexDirection:'column',alignItems:'center'}}>
					{L('Graph')}
					<div style={{height:3,width:'70%',backgroundColor:isGraph?(props.theme?'#E7853F':'#47B4E3'):'transparent',marginTop:8}}/>
				</div>
				<div onClick={handleisGraph(false)} className='mousehover' style={{flex:1,textAlign:'center',fontSize:f8,display:'flex',flexDirection:'column',alignItems:'center'}}>
					{L('Stock Info.')}
					<div style={{height:3,width:'70%',backgroundColor:isGraph?'transparent':(props.theme?'#E7853F':'#47B4E3'),marginTop:8}}/>
				</div>
			</div>
			<div style={{position:'relative',boxSizing:'border-box',flex:1,display:(isCollapsed||!isGraph)?'none':'block'}}>
				<div id='tv_chart_container' style={{height:'100%',width:'100%'}}/>
			</div>
			{/*Stock Info*/}
			<div style={{position:'relative',boxSizing:'border-box',flex:1,display:(isCollapsed||isGraph)?'none':'flex'}}>
				<div style={{display:'flex',flexDirection:'column',flex:1,width:'100%',borderRadius: '10px',padding:'10px',boxSizing: 'border-box'}}>

					<div style={{position:'relative',display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
						<div style={{position:'absolute',top:0,bottom:0,left:0,right:0,display:'flex',flexDirection:'column',width:'100%',boxSizing:'border-box',overflow:'auto',justifyContent:'flex-start',alignItems:'flex-start'}}>

							<div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',borderRadius: '5px',boxSizing: 'border-box',padding:10,marginTop:5,alignItems:'flex-start',fontWeight:600,fontSize:f8,backgroundColor:props.theme?'rgba(13, 87, 97, 0.2)':'rgba(255,255,255,1)',boxSizing:'border-box'}}>
								{props.data.stockCode}&nbsp;&nbsp;&nbsp;&nbsp;{props.data.stockCode&&props.HKstockListDict&&props.HKstockListDict[props.data.stockCode]&&props.HKstockListDict[props.data.stockCode].stockName}
							</div>
							<div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',borderRadius: '5px',boxSizing: 'border-box'}}>
								{/*<div style={{fontSize:f8,width:'100%',boxSizing:'border-box',padding:5,fontWeight:600,marginTop:5}}>{L("Information")}</div>
	              <div style={{width:'calc( 100% - 20px )',height:1,backgroundColor:props.theme?'white':'black',opacity:.2,boxSizing:'border-box',margin:'0 10px 0 10px'}}/>*/}

								{
									props.StockYearData.length > 0?  <div style={{width:'calc( 100% - 20px )',boxSizing:'border-box',fontSize:f8,margin:10,display:'flex',flexDirection:'column',border:'0.5px solid #8C8C8C',}}>
										<div  style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',borderBottom:'0.5px solid #8C8C8C',textAlign:'center'}}>
											<div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
												<div>{L('Year')}</div>
											</div>
											<div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
												<div>{L('EPS')}</div>
												<div>{L('(CS)')}</div>
											</div>
											<div style={{flex:2,display:'flex',flexDirection:'column'}}>
												<div>{L('Price')}($)</div>
												<div style={{width:'100%',display:'flex',flexDirection:'row'}}>
													<div style={{flex:1}}>{L('High')}</div>
													<div style={{flex:1}}>{L('Low')}</div>
												</div>
											</div>
										</div>

										{props.StockYearData.map((item,index)=>
											<div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',backgroundColor:index%2===0?(props.theme?'rgba(196, 196, 196, 0.1)':'rgba(196, 196, 196, 0.2)'):'transparent',textAlign:'center'}}>
												<div style={{flex:1,display:'flex',flexDirection:'column'}}>
													{item.year}
												</div>
												<div style={{flex:1,display:'flex',flexDirection:'column',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

													<div style={{width:50}}>{Math.round(item.eps*100)/100}</div>{item.eps>0&&<RiseIcon/>}
												</div>
												<div style={{flex:1,display:'flex',flexDirection:'column'}}>
													{item.priceHigh!==undefined?Math.round(item.priceHigh*100)/100:''}
												</div>
												<div style={{flex:1,display:'flex',flexDirection:'column'}}>
													{item.priceLow!==undefined?Math.round(item.priceLow*100)/100:''}
												</div>
											</div>
										)}
									</div>:<div style={{display: 'none'}}></div>
								}


								{
									(props.StockYearData.length > 0&&props.StockYearData[0].peHigh===undefined)||props.StockYearData.length === 0?
										<div style={{display: 'none'}}></div>
										:<div style={{width:'calc( 100% - 20px )',boxSizing:'border-box',fontSize:f8,margin:10,marginTop:0,display:'flex',flexDirection:'column',border:'0.5px solid #8C8C8C',textAlign:'center'}}>
											<div  style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',borderBottom:'0.5px solid #8C8C8C'}}>
												<div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
													<div>{L('Year')}</div>
												</div>

												<div style={{flex:2,display:'flex',flexDirection:'column'}}>
													<div>{L('PE')}</div>
													<div style={{width:'100%',display:'flex',flexDirection:'row'}}>
														<div style={{flex:1}}>{L('High')}</div>
														<div style={{flex:1}}>{L('Low')}</div>
													</div>
												</div>
											</div>

											{props.StockYearData.map((item,index)=>
												<div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',backgroundColor:index%2===0?(props.theme?'rgba(196, 196, 196, 0.1)':'rgba(196, 196, 196, 0.2)'):'transparent'}}>
													<div style={{flex:1,display:'flex',flexDirection:'column'}}>
														{item.year}
													</div>

													<div style={{flex:1,display:'flex',flexDirection:'column'}}>
														{item.peHigh!==undefined?Math.round(item.peHigh*100)/100:''}
													</div>
													<div style={{flex:1,display:'flex',flexDirection:'column'}}>
														{item.peLow!==undefined?Math.round(item.peLow*100)/100:''}
													</div>
												</div>
											)}
										</div>
								}



								{[138,2,44,126,135,64,117,115,114,111,112].map((field,field_index)=>
									<div key={field_index} style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',padding:'0 10px 0 10px',display:'flex',justifyContent:'space-between'}}>
										<div>{L(fidID_Dict[field].d)}</div>
										{fidID_Dict[field].t==='Number'?
											<div style={{
												color:
													(props.data&&props.data.data)
														?fidID_Dict[field].color
															?(props.data.data[field]>=0?'#04D26F':'#FF3E3A')
															:(props.theme?'white':'black')
														:(props.theme?'white':'black')
											}}>
												{props.data&&props.data.data&&
													(fidID_Dict[field].isComma?
															numberWithCommas(props.data.data[field]):
															Math.round(props.data.data[field]*10000)/(fidID_Dict[field].percent?100:10000)
													)
												}
											</div>
											:<div style={{}}>{props.data&&props.data.data&&props.data.data[field]||'-'}</div>}
									</div>
								)}
							</div>

							<div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',backgroundColor: props.theme?'rgba(13, 87, 97, 0.2)':'rgba(255, 255, 255, 1)',borderRadius: '5px',boxSizing: 'border-box',padding:10,marginTop:10}}>
								<div style={{fontSize:f8,width:'100%',boxSizing:'border-box',padding:5,fontWeight:600,marginBottom:5}}>{L("Industry Group")}</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',textAlign:'left',textDecoration:'underline'}}>
									{props.data.industryName||'[Industry Name]'}
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between'}}>
									<div>{L("Industry Code")}</div>
									<div>{props.data.IndustryCode||'-'}</div>
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between'}}>
									<div>{L("Total Number of Stocks")}</div>
									<div>{props.data.totalNumberOfStocks||'-'}</div>
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
									<div>{L("Ranking")}</div>
									<div>{props.data&&props.data.data&&props.data.data[137]||'-'}</div>
								</div>
								<div onClick={props.handleSelectIndustry} className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',height:29,width:'100%',boxSizing:'border-box',color:'#2C323D',backgroundColor:props.theme?'#E5EAEF':'#E5EAEF',marginTop:10,borderRadius:4,fontSize:f8,textAlign:'center'}}>
									{L("View Stock in industry")}
								</div>

								<div style={{fontSize:f8,width:'100%',boxSizing:'border-box',padding:5,marginTop:5,fontWeight:600}}>{L("Sector")}</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',textAlign:'left',textDecoration:'underline'}}>
									{props.data.SectorName||'[Sector Name]'}
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between'}}>
									<div>{L("Sector Code")}</div>
									<div>{props.data.SectorCode||'-'}</div>
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
									<div>{L("Total Number of Stocks")}</div>
									<div>{props.data.totalNumberOfStocks_Sector||'-'}</div>
								</div>
								<div style={{fontSize:f8,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
									<div>{L("Ranking")}</div>
									<div>{props.data&&props.data.data&&props.data.data[136]||'-'}</div>
								</div>
								<div onClick={props.handleSelectSector} className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',height:29,width:'100%',boxSizing:'border-box',color:'#2C323D',backgroundColor:props.theme?'#E5EAEF':'#E5EAEF',marginTop:10,borderRadius:4,fontSize:f8,textAlign:'center'}}>
									{L("View Stock in Sector")}
								</div>
							</div>


						</div>

					</div>
				</div>

			</div>
		</div>
	)
}



