import React,{useState,useEffect,useRef} from 'react';
import {
 PullDownIcon,
 RemoveIcon
} from '../icons'
import lang from '../lang.json'
import {fidID_Dict} from '../fidID_Dict'
const f8='8px'
const f10='10px'
const f12='12px'
const f14='14px'
const f16='16px'
const f20='20px'
const f24='24px'

const getArray=(criteria)=>{
  let arr=[]
  for(let i in criteria.possibleDisplayValues)
    arr.push({value:criteria.possibleDisplayValues[i],data:criteria.possibleValues[i]})
  return arr
}
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 

export default function EditListPanel (props){
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
  return(
    <div style={{display:'flex',width:'100%',height:'100%',padding:'0',boxSizing: 'border-box',flexDirection:'column',alignItems:'flex-start'}}>
      <div style={{display:'flex',width:'100%',flex:1,boxSizing: 'border-box',flexDirection:'column',overflow:'hidden',backgroundColor:props.theme?'rgba(255,255,255,.05)':'rgba(0,0,0,.05)',borderRadius:'10px'}}>
        <div style={{width:'100%',boxSizing: 'border-box',display:'flex',flexDirection:'row',marginTop:'5px',flex:1,}}>

          <div style={{flex:6,width:'100%',boxSizing: 'border-box',display:'flex',flexDirection:'column',height:'100%'}}>
            <div style={{width:'100%',boxSizing: 'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'5px 10px 5px 10px'}}>
              <div style={{fontSize:f16,fontWeight:700,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                <div className="" style={{fontSize:f14,fontWeight:400}}>
                  {L("Currnet Stocks in list")}
                </div>
                { props.watchListFolder&&
                  props.watchListFolder[props.editListPanelFolderIndex]&&
                  props.watchListFolder[props.editListPanelFolderIndex].watchlist&&
                  props.watchListFolder[props.editListPanelFolderIndex].watchlist[props.editListPanelListIndex]&&
                  props.watchListFolder[props.editListPanelFolderIndex].watchlist[props.editListPanelListIndex].watchlistName}
              </div>

              <div style={{boxSizing:'border-box',display:'flex',flexDirection:'row'}}>
                <div className='mousehover' onClick={props.handleDeleteStock} style={{fontSize:f14,fontWeight:700,color:'white',border:props.theme?'1px solid #3E78B4':'1px solid #258CF4',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px',backgroundColor:props.theme?'#3E78B4':'#258CF4'}}>
                  {L("Confirm")}
                </div>
                <div className='mousehover' onClick={props.handleCancelEditListPanel} style={{marginLeft:10,fontSize:f14,fontWeight:700,border:props.theme?'1px solid #EFEFEF50':'1px solid #1F1F1F50',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
                  {L("Cancel")}
                </div>
              </div>
            </div>
            <div style={{width:'100%',boxSizing: 'border-box',display:'flex',flexDirection:'row',flex:1}}>
              <div style={{position:'relative',flex:3,display:'flex',flexDirection:'column',fontSize:'12px',textAlign:'left',height:'100%'}}>
                <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,display:'flex',flexDirection:'column',overflow:'auto',padding:'10px'}}>

                  <div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',overflow:'auto',height:'90%',overflow:'auto'}}>
                    {/*chart header*/}
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',width:'100%',boxSizing:'border-box',backgroundColor:props.theme?"rgba(0, 0, 0, 0.2)":'rgba(255, 255, 255, 0.6)',minHeight:'30px',alignItems:'center',textAlign:'center',borderBottom:'1px solid #FFFFFF50'}}>
                      <div style={{flex:2}}>{L("SYMBOL")}</div>
                      <div style={{flex:2}}>{L("NAME")}</div>
                      {/*props.data&&props.data.length>0&&props.data[0].figureResult&&props.data[0].figureResult.map((field,field_index)=>
                        <div style={{flex:2}}>{fidID_Dict[field.fidID].d}</div>
                      )*/}
                      <div style={{flex:2}}>{L("Price")}</div>
                      <div style={{flex:2}}>{L("Price $ Change")}</div>
                      <div style={{flex:2}}>{L("Price % Change")}</div>
                      <div style={{flex:2}}>{L("Volume")}</div>
                      <div style={{flex:2}}>{L("Stock RS")}</div>
                      <div style={{flex:2}}>{L("Industry RS")}</div>
                      <div style={{flex:2}}>{L("Vol % Chg vs 50-day")}</div>
                      <div style={{flex:1,height:'100%'}}></div>
                    </div>
                    {/*end of chart header*/}
                    {props.editListPanelList.map((item,index)=>
                      <div key={index} style={{ opacity:item.isDelete?.3:1,fontSize:f14,width:'100%',display:'flex',flexDirection:'row',minHeight:'30px',alignItems:'center',textAlign:'center',backgroundColor:props.theme?'rgba(29, 29, 29, 0.2)':'rgba(255, 255, 255, 0.2)',boxSizing:'border-box',marginTop:2}}>
                        <div style={{flex:2}}>{item.stockCode}</div>
                        <div style={{flex:2}}>{props.HKstockListDict&&props.HKstockListDict[item.stockCode]&&props.HKstockListDict[item.stockCode].stockName}</div>
                        {[44,126,135,64,117,115,134].map((field,field_index)=>
                          item.data && fidID_Dict[field].t==='Number'?
                          <div key={field_index} style={{
                            flex:2, 
                            color:fidID_Dict[field].color?(item.data[field]>=0?'#04D26F':'#FF3E3A'):(props.theme?'white':'black')
                          }}>
                            {fidID_Dict[field].isComma?
                              numberWithCommas(item.data[field]):
                              Math.round(item.data[field]*10000)/(fidID_Dict[field].percent?100:10000)
                            }
                          </div>
                          :<div key={field_index} style={{flex:2}}>{item.data?item.data[field]:''}</div>
                        )}
                        {/*<div style={{flex:4}}>{item.stockName}</div>
                        <div style={{flex:4}}></div>
                        <div style={{flex:2}}>{(item.data&&item.data['44'])?item.data['44']:'0'}</div>
                        <div style={{flex:2,color:(item.data&&item.data['125']>=0)?'#04D26F':'#FF3E3A'}}>{(item.data&&item.data['125'])?Math.round(item.data['125']*1000)/10:'0'}</div>
                        <div style={{flex:2,color:(item.data&&item.data['126']>=0)?'#04D26F':'#FF3E3A'}}>{(item.data&&item.data['126'])?Math.round(item.data['126']*10000)/10000:'0'}</div>
                        <div style={{flex:2}}>{item.data&&item.data['64']||'0'}</div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>
                        <div style={{flex:2}}></div>*/}
                        <div className='mousehover' onClick={props.handleselectDeleteStock(item,index)} style={{flex:1,height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}><RemoveIcon/></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mousehover' onClick={props.VerticalCollapse} style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',boxSizing:'border-box',padding:3}}>
        <PullDownIcon theme={props.theme}/>
      </div>
    </div>
  )
}