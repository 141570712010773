import React,{useState,useEffect,useRef} from 'react';
import {formatDate, formatTime} from './../util'
import {SettingIcon,LogOutIcon} from '../icons'
import lang from '../lang.json'
const f10='10px'
export default function MobileSetting(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	const [isExpanded,setisExpanded]=useState(false)
	const handleExpand=()=>{setisExpanded(!isExpanded)}



	return(
		<div>
			{isExpanded?
				<div style={{position:"absolute",top:0,right:0,left:0,height:142,backgroundColor:'rgba(53, 78, 125, 0.8)',display:'flex',flexDirection:'column',backdropFilter: 'blur(10px)'}}>
					<div style={{display:'flex',flexDirection:'row',width:'100%', justifyContent:'center',color:'white',marginTop:'50px',alignItems:'center'}}>
						<div style={{fontSize:f10,opacity:.5,marginLeft:20,marginRight:20,minWidth:60}}>{L("Language")}</div>
				        {props.LangList.map((item,index)=>
				          <div onClick={props.handleLang(item,index)} className='mousehover' key={index} style={{fontSize:f10,opacity:(props.lang&&(props.lang.lang===item.lang))?1:.5,marginLeft:10}}>{item.langName&&item.langName.slice(0,2)}</div>
				        )}
				   
					    <div style={{height:'26px',width:2,backgroundColor:props.theme?"white":'black',opacity:.2,marginLeft:40}}/>
				        <div style={{fontSize:f10,opacity:.5,marginLeft:20,minWidth:50}}>{L("Theme")}</div>
				        <div className='mousehover' onClick={props.handleTheme} style={{width:43,height:17,backgroundColor:props.theme?"#0D0D0D":'#E5E5E5',borderRadius:'11px',marginLeft:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
				          <div style={{backgroundColor:props.theme?'transparent':'#258CF4',height:12,width:12,borderRadius:'50%'}}/>
				          <div style={{backgroundColor:props.theme?'#005DA0':'transparent',height:12,width:12,borderRadius:'50%',marginLeft:10}}/>
				        </div>
			        </div>

			        <div style={{display:'flex',flexDirection:'row',width:'100%', justifyContent:'center',color:'white',marginTop:'20px',alignItems:'center'}}>
			        	<div className='mousehover' onClick={props.handleLogout} style={{width:'70%',height:23,border:"1px solid rgba(255,255,255,.2)",borderRadius:'5px',marginLeft:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
				          <div style={{fontSize:f10,color:"rgba(255,255,255,.5)",marginRight:10}}>{L("Logout")}</div>
				          <LogOutIcon theme={true}/>
				        </div>
			        </div>

					<div className='mousehover2' onClick={handleExpand} style={{position:"absolute",top:10,right:10}}>
			          <SettingIcon theme={props.theme}/>
			        </div>
				</div>
				:<div className='mousehover2' onClick={handleExpand} style={{position:"absolute",top:10,right:10}}>
		          <SettingIcon/>
		        </div>
	    	}
	    </div>
	)
}
