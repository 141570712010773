export const ReqIndustrySummary=(ws,marketID,industryCode)=>{
    let data ={
      request: "ReqIndustrySummary", 
      marketID:marketID,
      industryCode:industryCode,
      requestID: 1,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqIndustrySummary ERROR:'+ JSON.stringify(error)) // catch error
    }
  }