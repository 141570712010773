export const fidID_Dict={
1		: 	{n:'ExchangeName'			,t:'String'		,d:'Exchange'},
2		: 	{n:'MktCap'					,t:'Number'		,d:'Market Cap', isComma:true},
3		: 	{n:'NetIncomeFiscalYear'	,t:'Number'		,d:'Net Income (FY)'},
4		: 	{n:'SectorName'				,t:'String'		,d:'Sector Name'},
5		: 	{n:'SectorCode'				,t:'String'		,d:'Common Sector Code'},
6		: 	{n:'IndustryName'			,t:'String'		,d:'Industry Name'},
7		: 	{n:'IndustryCode'			,t:'String'		,d:'Common Industry Code'},
8		: 	{n:'MarketID'				,t:'Number'		,d:'Reference ID to the market'},
9		: 	{n:'AuditorOpinion'			,t:'Number'		,d:'Auditor Opinion'},
10		: 	{n:'Turnover'				,t:'Number'		,d:'Turnover'},
11		: 	{n:'TurnoverGrowth'			,t:'Number'		,d:'Turnover Growth'},
12		: 	{n:'TurnoverGrowth1'		,t:'Number'		,d:'Turnover Growth 1'},
13		: 	{n:'TurnoverGrowth2'		,t:'Number'		,d:'Turnover Growth 2'},
14		: 	{n:'TurnoverGrowth3'		,t:'Number'		,d:'Turnover Growth 3'},
15		: 	{n:'TurnoverGrowth4'		,t:'Number'		,d:'Turnover Growth 4'},
16		: 	{n:'TurnoverGrowth5'		,t:'Number'		,d:'Turnover Growth 5'},
17		: 	{n:'OperationProfit'		,t:'Number'		,d:'Operation Profit'},
18		: 	{n:'NetProfitGrowth'		,t:'Number'		,d:'Net Profit Growth'},
19		: 	{n:'NetProfitGrowth1'		,t:'Number'		,d:'Net Profit Growth 1'},
20		: 	{n:'NetProfitGrowth2'		,t:'Number'		,d:'Net Profit Growth 2'},
21		: 	{n:'NetProfitGrowth3'		,t:'Number'		,d:'Net Profit Growth 3'},
22		: 	{n:'NetProfitGrowth4'		,t:'Number'		,d:'Net Profit Growth 4'},
23		: 	{n:'NetProfitGrowth5'		,t:'Number'		,d:'Net Profit Growth 5'},
24		: 	{n:'EPS'					,t:'Number'		,d:'EPS (Last Reported Year)'},
25		: 	{n:'EPS1'					,t:'Number'		,d:'EPS (1 Year Ago)'},
26		: 	{n:'EPS2'					,t:'Number'		,d:'EPS (2 Years Ago)'},
27		: 	{n:'EPS3'					,t:'Number'		,d:'EPS (3 Years Ago)'},
28		: 	{n:'EPS4'					,t:'Number'		,d:'EPS (4 Years Ago)'},
29		: 	{n:'EPS5'					,t:'Number'		,d:'EPS (5 Years Ago)'},
30		: 	{n:'DividendYield'			,t:'Number'		,d:'Dividend Yield'},
31		: 	{n:'NetMargin'				,t:'Number'		,d:'Net Margin (TTM)'},
32		: 	{n:'OperatingMargin'		,t:'Number'		,d:'Operating Margin (TTM)'},
33		: 	{n:'PE'						,t:'Number'		,d:'P/E (Current)'},
34		: 	{n:'PEPercentileRank'		,t:'Number'		,d:'P/E (Percentile Rank)'},
35		: 	{n:'PEAverage5'				,t:'Boolean in 0 or 1'	,d:'P/E and 5-year Average P/E Comparison'},
36		: 	{n:'PELowest'				,t:'Boolean in 0 or 1'	,d:'P/E (lowest PE within the covered period)'},
37		: 	{n:'PEHighest'				,t:'Boolean in 0 or 1'	,d:'P/E (highest PE within the covered period)'},
38		: 	{n:'PEG'					,t:'Number'		,d:'PEG'},
39		: 	{n:'ROE'					,t:'Number'		,d:'ROE (Last Reported Year)'},
40		: 	{n:'ROA'					,t:'Number'		,d:'Return on Assets (TTM)'},
41		: 	{n:'TotalLiabilities'		,t:'Number'		,d:'Total Liabilities'},
42		: 	{n:'TotalAssets'			,t:'Number'		,d:'Total Assets'},
43		: 	{n:'IndustryMedian'			,t:'Number'		,d:'Industry Median'},
44		: 	{n:'Price'					,t:'Number'		,d:'Price'},
45		: 	{n:'High'					,t:'Number'		,d:'High'},
46		: 	{n:'Low'					,t:'Number'		,d:'Low'},
47		: 	{n:'Open'					,t:'Number'		,d:'Open'},
48		: 	{n:'WeekHigh52'				,t:'Number'		,d:'52 Week High'},
49		: 	{n:'MA10'					,t:'Number'		,d:'10 Day MA'},
50		: 	{n:'MA20'					,t:'Number'		,d:'20 Day MA'},
51		: 	{n:'MA50'					,t:'Number'		,d:'50 Day MA'},
52		: 	{n:'MA150'					,t:'Number'		,d:'150 Day MA'},
53		: 	{n:'MA200'					,t:'Number'		,d:'200 Day MA'},
54		: 	{n:'NewHighMonth1'			,t:'Number'		,d:'New 1-Month High'},
55		: 	{n:'NewLowMonth1'			,t:'Number'		,d:'New 1-Month Low'},
56		: 	{n:'NewHighMonth3'			,t:'Number'		,d:'New 3-Month High'},
57		: 	{n:'NewLowMonth3'			,t:'Number'		,d:'New 3-Month Low'},
58		: 	{n:'NewHighWeek52'			,t:'Number'		,d:'New 52 Week High'},
59		: 	{n:'NewLowWeek52'			,t:'Number'		,d:'New 52 Week Low'},
60		: 	{n:'NewHighMonth6'			,t:'Number'		,d:'New 6-Month High'},
61		: 	{n:'NewLowMonth6'			,t:'Number'		,d:'New 6-Month Low'},
62		: 	{n:'NewHighAllTime'			,t:'Number'		,d:'New All Time High'},
63		: 	{n:'NewLowAllTime'			,t:'Number'		,d:'New All Time Low'},
64		: 	{n:'Volume'					,t:'Number'		,d:'Volume', isComma:true},
65		: 	{n:'VolumeAverage50'		,t:'Number'		,d:'Volume (50 Day Average)'},
66		: 	{n:'VolumeAverage10'		,t:'Number'		,d:'Average Volume (10 day)'},
67		: 	{n:'VolumeAverage30'		,t:'Number'		,d:'Average Volume (30 day)'},
68		: 	{n:'VolumeAverage60'		,t:'Number'		,d:'Average Volume (60 day)'},
69		: 	{n:'VolumeAverage90'		,t:'Number'		,d:'Average Volume (90 day)'},
70		: 	{n:'ATR10'					,t:'Number'		,d:'Average True Range (10 Days)'},
71		: 	{n:'BollingerLower20'		,t:'Number'		,d:'Bollinger Lower Band (20)'},
72		: 	{n:'BollingerUpper20'		,t:'Number'		,d:'Bollinger Upper Band (20)'},
73		: 	{n:'ChaikinMoneyFlow20'		,t:'Number'		,d:'Chaikin Money Flow (20)'},
74		: 	{n:'EMA10'					,t:'Number'		,d:'Exponential Moving Average (10)'},
75		: 	{n:'EMA100'					,t:'Number'		,d:'Exponential Moving Average (100)'},
76		: 	{n:'EMA20'					,t:'Number'		,d:'Exponential Moving Average (20)'},
77		: 	{n:'EMA200'					,t:'Number'		,d:'Exponential Moving Average (200)'},
78		: 	{n:'EMA30'					,t:'Number'		,d:'Exponential Moving Average (30)'},
79		: 	{n:'EMA5'					,t:'Number'		,d:'Exponential Moving Average (5)'},
80		: 	{n:'EMA50'					,t:'Number'		,d:'Exponential Moving Average (50)'},
81		: 	{n:'IchimokuBase'			,t:'Number'		,d:'Ichimoku Cloud Base Line (9, 26,52, 26)'},
82		: 	{n:'IchimokuConversion'		,t:'Number'		,d:'Ichimoku Cloud Conversion Line (9, 26, 52, 26)'},
83		: 	{n:'IchimokuLead1'			,t:'Number'		,d:'Ichimoku Lead 1 (9, 26, 52, 26)'},
84		: 	{n:'IchimokuLead2'			,t:'Number'		,d:'Ichimoku Lead 2 (9, 26, 52, 26)'},
85		: 	{n:'KeltnerLower20'			,t:'Number'		,d:'Keltner Channels Lower Band (20)'},
86		: 	{n:'KeltnerUpper20'			,t:'Number'		,d:'Keltner Channels Upper Band (20)'},
87		: 	{n:'MACDLevel'				,t:'Number'		,d:'MACD Level (12, 26)'},
88		: 	{n:'MACDSignal'				,t:'Number'		,d:'MACD Signal (12, 26)'},
89		: 	{n:'Momentum10'				,t:'Number'		,d:'Momentum (10)'},
90		: 	{n:'MoneyFlow14'			,t:'Number'		,d:'Money Flow (14)'},
91		: 	{n:'ParabolicSAR'			,t:'Number'		,d:'Parabolic SAR'},
92		: 	{n:'RSI14'					,t:'Number'		,d:'Relative Strength Index (14)'},
93		: 	{n:'RSI7'					,t:'Number'		,d:'Relative Strength Index (7)'},
94		: 	{n:'SMA10'					,t:'Number'		,d:'Simple Moving Average (10)'},
95		: 	{n:'SMA100'					,t:'Number'		,d:'Simple Moving Average (100)'},
96		: 	{n:'SMA20'					,t:'Number'		,d:'Simple Moving Average (20)'},
97		: 	{n:'SMA200'					,t:'Number'		,d:'Simple Moving Average (200)'},
98		: 	{n:'SMA30'					,t:'Number'		,d:'Simple Moving Average (30)'},
99		: 	{n:'SMA5'					,t:'Number'		,d:'Simple Moving Average (5)'},
100		: 	{n:'SMA50'					,t:'Number'		,d:'Simple Moving Average (50)'},
101		: 	{n:'StochasticD'			,t:'Number'		,d:'Stochastic %D (14, 3, 3)'},
102		: 	{n:'StochasticK'			,t:'Number'		,d:'Stochastic %K (14, 3, 3)'},
103		: 	{n:'StochasticRSIFast'		,t:'Number'		,d:'Stochastic RSI Fast (3, 3, 14, 14)'},
104		: 	{n:'StochasticRSISlow'		,t:'Number'		,d:'Stochastic RSI Slow (3, 3, 14, 14)'},
105		: 	{n:'UltimateOscillator'		,t:'Number'		,d:'Ultimate Oscillator (7, 14, 28)'},
106		: 	{n:'Volatility'				,t:'Number'		,d:'Volatility'},
107		: 	{n:'VolatilityMonth'		,t:'Number'		,d:'Volatility Month'},
108		: 	{n:'VolatilityWeek'			,t:'Number'		,d:'Volatility Week'},
109		: 	{n:'VolumeWeightedAvgPrice'	,t:'Number'		,d:'Volume Weighted Average Price'},
110		: 	{n:'VolumeWeightedMA20'		,t:'Number'		,d:'Volume Weighted Moving Average (20)'},
111		: 	{n:'ROERating'				,t:'String'		,d:'ROE Rating'},
112		: 	{n:'ROARating'				,t:'String'		,d:'ROA Rating'},
113		: 	{n:'ADRating'				,t:'String'		,d:'A/D Rating'},
114		: 	{n:'SectorRS12'				,t:'String'		,d:'Sector(12) RS'},
115		: 	{n:'IndustryRS31'			,t:'String'		,d:'Industry RS'},
116		: 	{n:'SubSectorRS96'			,t:'String'		,d:'SubSector(96) RS'},
117		: 	{n:'StockRS'				,t:'Number'		,d:'Stock RS'},
118		: 	{n:'NorthboundRS'			,t:'Number'		,d:'Northbound RS'},
119		: 	{n:'Top20'					,t:'Number'		,d:'Top 20/50'},
120		: 	{n:'Top20InFlow'			,t:'Number'		,d:'Top 20/50 (fund in)'},
121		: 	{n:'PEAverage5'				,t:'Number'		,d:'5-year Average P/E'},
122		: 	{n:'PELowest'				,t:'Number'		,d:'P/E (lowest PE within the covered period)'},
123		: 	{n:'PEHighest'				,t:'Number'		,d:'P/E (highest PE within the covered period)'},
124		: 	{n:'VolumeVs VolumeAverage50PercChg',t:'Number',d:'Vol % Chg vs 50-day',color:true},
125		: 	{n:'PricePercChg'			,t:'Number'		,d:'Price % Change'		,color:true,},
126		: 	{n:"PriceChg"				,t:'Number'		,d:'Price $ Change'					,color:true},
134		: 	{n:'VolumeVs VolumeAverage50PercChg',t:'Number',d:'Vol % Chg vs 50-day',color:true,},
135		: 	{n:'PricePercChg'			,t:'Number'		,d:'Price % Change'		,color:true},

136		: 	{n:'RankInSector'			,t:'Number',	d:'Ranking',},
137		: 	{n:'RankInIndustry'			,t:'Number'		,d:'Ranking'		},
138		: 	{n:'PE'						,t:'Number'		,d:'PE'		},
}