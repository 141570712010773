import React,{useState,useEffect,useRef} from 'react';
import lang from '../lang.json'
export default function AddStockToWatchListPopUp(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	return (
	  <div style={{zIndex:5,boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.2)',backdropFilter:"blur(4px)",display:'flex',justifyContent:'center',alignItems:'center'}}>
	    <div style={{boxSizing:"border-box",backgroundColor:props.theme?'#0F4E5C':'white',display:'flex',flexDirection:'column',padding:'20px',alignItems:'flex-start',width:'500px',minHeight:'300px',maxWidth:'95%'}}>
	      <div style={{fontSize:"18px",fontWeight:700,lineHeight:'21px'}}>{L('Add Stock to Watchlist')}</div>
	      <input onChange={props.handleInput} value={props.value} style={{boxSizing:"border-box",fontSize:'16px',backgroundColor:props.theme?'#1D353D':'#DDDDDD',border:'transparent',padding:'7px',color:props.theme?'white':"black",marginTop:'20px',width:'100%'}} placeholder={L('Please enter a stock code e.g. 0001.HK')}/>
	      {/*<div style={{fontSize:'14px',color:props.theme?"#A0A0A0":'#787878',marginTop:"10px"}}>{L('*up to 50 characters')}</div>
	      <div style={{fontSize:'14px',color:props.theme?"#A0A0A0":'#787878',marginTop:"10px"}}> {L('tip3')}</div>*/}
	      <div style={{boxSizing:"border-box",flex:1,width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
	        <div onClick={props.handleCancel} className='mousehover' style={{backgroundColor:props.theme?'#2B5563':"#BABABA",color:'white',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Cancel')}
	        </div>
	        <div onClick={props.handleConfirm} className='mousehover' style={{marginLeft:20,backgroundColor:props.theme?'#043A4B':'#3A97F0',color:'white',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Save')}
	        </div>
	      </div>
	    </div>
	  </div>
	)
}