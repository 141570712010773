import React,{useState,useEffect,useRef} from 'react';
import {
  NewFolderIcon,
  DownArrowIcon,
  NewListIcon,
  DragIcon,
  MinimizeIcon,
  FolderIcon,
  DotIcon,
  UpArrowIcon,
  FolderIcon2,
  EditIcon,
  DotDotDotIcon,
  MinimizeIcon2
} from '../icons'
import lang from '../lang.json'
const f16='16px'
const f14='14px'
const f10='10px'

export default function Screen(props){
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}

  if(props.isCollapse)
    return(
      <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
        <div  style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme?'#1B3248':'rgba(219, 225, 231, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:f14,minHeight:22}}>{L('screen')}</div>
          <div onClick={props.handleCollapseComponent} className="mousehover">
           {props.isEndofRow?<MinimizeIcon theme={props.theme}/>:<MinimizeIcon2 theme={props.theme}/>}
          </div>
          <div className="drag-handle mousehover">
            <DragIcon theme={props.theme}/>
          </div>
        </div>
      </div>
    )
  return(
    <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',overflowX:'hidden',overflowY:'hidden'}}>
      <div  style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme?'#1B3248':'rgba(219, 225, 231, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box'}}>
        <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',marginBottom:'0'}}>
          <div style={{fontSize:f16,minHeight:22}}>{L('screen')}</div>
          <div className='dragicons' style={{flexDirection:'row'}}>
            <div onClick={props.handleCollapseComponent} className="mousehover">
             {props.isEndofRow?<MinimizeIcon2 theme={props.theme}/>:<MinimizeIcon theme={props.theme}/>}
            </div>
            <div className="drag-handle mousehover" style={{marginLeft:15}}>
              <DragIcon theme={props.theme}/>
            </div>
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,borderRadius: '5px',boxSizing: 'border-box',position:'relative'}}>
          <div style={{display:'flex',flexDirection:'column',position:'absolute',top:0,bottom:0,left:0,right:0,overflowX:'hidden',overflowY:'auto',boxSizing:'border-box',paddingRight:5}}>


            {props.screenListFolder.map((item,index)=>
               item.notFolder?<div key={index}/>:
              <div key={index} style={{width:'100%',marginTop:5,borderRadius:'5px',boxSizing:'border-box',backgroundColor:props.theme?'#0000004D':"rgba(255, 255, 255, 0.4)",padding:'5px 0 5px 0'}}>
                <div style={{position:'relative',fontSize:f14,padding:'6px 12px 6px 12px',boxSizing:'border-box',display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'space-between',paddingLeft:'10px',paddingRight:'10px',height:'25px',boxSizing:'border-box'}}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                     {item.isExpanded?<FolderIcon theme={props.theme}/>:<FolderIcon2 theme={props.theme}/>}
                    <div className='mousehover' onClick={props.handleExpandScreenFolder(item,index)} style={{marginLeft:5,fontSize:f14,whiteSpace: 'nowrap'}}>{item.folderName}</div>
                    <div className='mousehover' onClick={props.handleIsEditingScreenFolder(item,index)} style={{marginLeft:10,padding:5}}><EditIcon/></div>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div  style={{display:'flex',height:'100%',alignItems:'center',justifyContent:'center'}}>
                      {item.isEditing&&<div style={{position:'absolute',top:'100%',right:0,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#2E3538':'#DDDDDD',padding:'5px',zIndex:3,width:'100%',alignItems:'flex-end',fontSize:f14}}>
                        <div className='mousehover' onClick={props.handleStartModifyScreenFolder(item,index)}>{L("Change Name")}</div>
                        <div onClick={props.handleStartDeleteScreenFolder(item,index)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
                      </div>}
                    </div>
                    <div className='mousehover' onClick={props.handleExpandScreenFolder(item,index)} style={{position:'relative',marginLeft:10,padding:5,paddingRight:0}}>
                     { item.isExpanded?<UpArrowIcon width={13} height={7} theme={props.theme}/>:<DownArrowIcon theme={props.theme}/>}
                    </div>
                  </div>
                </div>
                {item.isExpanded&&item.screenList.map((screen,screenIndex)=>
                  <div key={screenIndex} style={{backgroundColor:(props.SelectedList.type==='screen'&&props.SelectedList.index===index&&props.SelectedList.index2===screenIndex)?'rgba(71, 180, 227, 0.3)':'transparent',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f14,boxSizing:'border-box',padding:'5px'}}>
                    <div className='mousehover' onClick={props.handleSelectScreen(item,index,screen,screenIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center",width:'100%',overflow:'hidden',height:'22px',boxSizing:'border-box'}}>
                      <div style={{margin:'0 10px 0 23px'}}><DotIcon theme={props.theme}/></div>
                      <div style={{whiteSpace: 'nowrap',fontSize:f14}}>{screen.screenName}</div>
                    </div>
                    <div  style={{position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <div className='mousehover' onClick={props.handleIsEditingscreen(item,index,screen,screenIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
                      {screen.isEditing&&<div style={{position:'absolute',top:'30px',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':'#DDDDDD',padding:'5px',zIndex:3,fontSize:f14,minWidth:93,alignItems:'flex-end'}}>
                        <div onClick={props.handleStartModifyScreen(item,index,screen,screenIndex)} className='mousehover'>{L("Change Name")}</div>
                        <div onClick={props.handleStartEditCriteria(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Screen Edit")}</div>
                        <div onClick={props.handleStartDeletescreen(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
            )}

            {props.screenListFolder.map((item,index)=>
               item.notFolder?
              <div key={index} style={{width:'100%',boxSizing:'border-box'}}>
                
                {item.screenList.map((screen,screenIndex)=>
                  <div key={screenIndex} style={{backgroundColor:(props.SelectedList.type==='screen'&&props.SelectedList.index===index&&props.SelectedList.index2===screenIndex)?'rgba(71, 180, 227, 0.3)':(props.theme?'#0000004D':"rgba(255, 255, 255, 0.4)"),width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f14,boxSizing:'border-box',padding:'5px',borderRadius:'5px',marginTop:5}}>
                    <div className='mousehover' onClick={props.handleSelectScreen(item,index,screen,screenIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center",width:'100%',overflow:'hidden',height:'22px',boxSizing:'border-box'}}>
                      <div style={{margin:'0 10px 0 23px'}}><DotIcon theme={props.theme}/></div>
                      <div style={{whiteSpace: 'nowrap',fontSize:f14}}>{screen.screenName}</div>
                    </div>
                    <div  style={{position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <div className='mousehover' onClick={props.handleIsEditingscreen(item,index,screen,screenIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
                      {screen.isEditing&&<div style={{position:'absolute',top:'30px',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':'#DDDDDD',padding:'5px',zIndex:3,fontSize:f14,minWidth:93,alignItems:'flex-end'}}>
                        <div onClick={props.handleStartModifyScreen(item,index,screen,screenIndex)} className='mousehover'>{L("Change Name")}</div>
                        <div onClick={props.handleStartEditCriteria(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Screen Edit")}</div>
                        <div onClick={props.handleStartDeletescreen(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
              :<div key={index}/>
            )}
            <div style={{width:'100%',minHeight:'100px'}}/>
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'row',overflow:'hidden'}}>
          <div className='mousehover' onClick={props.handleStartCreateScreenFolder} style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:120}}>
            <NewFolderIcon theme={props.theme}/>
            <div style={{fontSize:f14,marginLeft:'5px'}}>{L('Create Folder')}</div>
          </div>
          <div className='mousehover' onClick={props.handleStartCreateScreen} style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'20px'}}>
            <NewListIcon theme={props.theme}/>
            <div style={{fontSize:f14,marginLeft:'5px'}}>{L('Create Screen')}</div>
          </div>
        </div>
      </div>
      {props.isEditPopUpMask&&<div onClick={props.handleCancelMask} style={{zIndex:2,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0}}/>}
    </div>
  )
}