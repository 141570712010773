export const ReqScreenList=(ws)=>{
    let data ={
      request: "ReqScreenList", 
      requestID: 1,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log('ReqScreenList ERROR:'+ JSON.stringify(error)) // catch error
    }
  }