export const useUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    lang: urlParams.get('lang'),
    theme: urlParams.get('theme') != null ? Number(urlParams.get('theme')) : null,
    token: urlParams.get('token'),
    userName: urlParams.get('userName'),
    siteName: urlParams.get('siteName'),
    sessionToken: urlParams.get('sessionToken'),
  };
}
