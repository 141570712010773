export const ReqBlockFinFigure=(ws,marketID,requestType,stockCode,screenID,watchlistID,figureTemplateID=1,requestID=1,industryCode=null,sectorCode=null)=>{
    let data ={
      request: "ReqBlockFinFigure",
      requestType:requestType,
      figureTemplateID:figureTemplateID,
      requestID: requestID,
    }
    if(requestType===0){
        data.marketID=marketID
        data.stockCode=stockCode
    }
    if(requestType===1){
        data.marketID=marketID
        data.screenID=screenID
    }
    if(requestType===2)
        data.watchlistID=watchlistID
    if(requestType===3){
        data.marketID=marketID
        data.industryCode=industryCode
    }
    if(requestType===4){
        data.marketID=marketID
        data.sectorCode=sectorCode
    }
    console.log(data)
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqBlockFinFigure ERROR:'+ JSON.stringify(error)) // catch error
    }
  }