import React,{useState,useEffect,useRef} from 'react';
import {formatDate, formatTime} from './../util'
import {CollapseIcon_UP,CollapseIcon_Down} from '../icons'
import lang from '../lang.json'
import {
	AddIcon,
  MinusIcon,
  StarIcon,
  StarredIcon,
  InquiryIcon,
  PullDownIcon,
  MinusIcon2
} from '../icons'
import DropDownMenu from './components/DropDownMenu'
const f8='8px'
const f10='10px'
const f12='12px'
const f14='14px'


export default function MobileCriteria(props){
	const scrollRef= useRef(null)
  const getArray=(criteria)=>{
    if(criteria.matchingType===0&&criteria.valueType===3){
      return [{value:'Yes'},{value:'No'}]
    }else if(criteria.matchingType===0&&criteria.valueType===4){
      let arr=[]
      for(let i in criteria.possibleDisplayValues)
        arr.push({value:criteria.possibleDisplayValues[i],data:criteria.possibleValues[i]})
      return arr
    }else if((criteria.matchingType===0&&criteria.valueType===5)){
      return props.industryList
    }else if((criteria.matchingType===0&&criteria.valueType===6)){
      return props.SectorList
    }else if((criteria.matchingType===0&&criteria.valueType===7)){
      return props.marketList
    }
    return[]
    
  }
  const getDisplayValue=(criteria,minmax=null)=>{
    for(let i of props.SelectedCritiaList)
      if(i.criteriaID===criteria.criteriaID&&!i.isDelete)
        if(minmax==='min')
          return (i.minValue===undefined||i.minValue==='')?'':i.minValue
        else if(minmax==='max')
          return (i.maxValue===undefined||i.maxValue==='')?'':i.maxValue
        else
          return i.display!==undefined?i.display:i.matchedValues[0]

    if(criteria.matchingType===0&&criteria.valueType===3)return'Yes/No'
    if(criteria.matchingType===0&&criteria.valueType===4)return'Select'
    if(criteria.matchingType===0&&criteria.valueType===5)return'Industry ID'
    if(criteria.matchingType===0&&criteria.valueType===6)return'Sector ID'
    if(criteria.matchingType===0&&criteria.valueType===7)return'Market ID'
    if(criteria.matchingType===0&&criteria.valueType===8)return''
    if(minmax)return ''
    return'Select'
  }
  const isSelected=(criteria)=>{
    //if(criteria.isHighlighted)return true
    for(let i of props.SelectedCritiaList)
      if(i.criteriaID===criteria.criteriaID)
        if(i.isDelete)
          return false
        else
          return true
    return false
  }
  const countCriteria=(group)=>{
    let count=0
    for(let i of group.criteriaList)
      for(let j of props.SelectedCritiaList)
        if(i.criteriaID===j.criteriaID&&!j.isDelete)
          count+=1
    return count
  }
  const countFavCriteria=()=>{
    let count=0
    for(let i of props.FavoriteCriteriaList)
      for(let j of props.SelectedCritiaList)
        if(i===j.criteriaID&&!j.isDelete)
          count+=1
    return count
  }
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
  const isMultiSelected=(criteria,item)=>{
    for(let i of props.SelectedCritiaList)
      if(i.criteriaID===criteria.criteriaID)
        if(i.isDelete)
          return false
        else{
          for(let j of i.matchedValues)
            for(let k in criteria.possibleValues)
              if(criteria.possibleDisplayValues[k]===item&&j===criteria.possibleValues[k])
                return true
        }
    return false
  }
  const handelSetSelectedGroup=(gp,gpindex)=>()=>{
    scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start' })
    props.handelSetSelectedGroup(gp,gpindex)()
  }

  const getcriteriaGroupArray=()=>{
  	let arr=[{value:L('Favourite'),data:-1,group:null}]
  	for(let i in props.criteriaGroup)
  		arr.push({value:props.criteriaGroup[i].criteriaGroupName,data:i,group:props.criteriaGroup[i]})
  	return arr

  }
  const handleChooseGroup=(item,index)=>{
  	props.handelSetSelectedGroup(item.group,item.data)()
  }
	return(
		<div style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',color:props.theme?'white':'black',position:'absolute',top:0,bottom:0,left:0,right:0,backgroundColor:props.theme?'#17354B':'#FFFFFF',boxSizing:'border-box'}}>
			<div style={{display:'flex',flexDirection:'row',width:'100%',fontSize:f14,fontWeight:700,textAlign:'center',justifyContent:'center',padding:10,boxSizing:'border-box'}}>
				{L('edit panel')}
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',textAlign:'center',justifyContent:'space-between'}}>
				<DropDownMenu 
					style={{border:props.theme?'1px solid white':'1px solid black',fontSize:f10,textAlign:'left',width:'50%',padding:2,marginLeft:10,marginBottom:10}}
					dropboxstyle={{backgroundColor:props.theme?'#081F26':'#DDDDDD'}}
          itemstyle={{backgroundColor:props.theme?'#081F26':'#DDDDDD',display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
					onClick={handleChooseGroup} 
					data={getcriteriaGroupArray()} 
					value={props.SelectedGroup==-1?L('Favourite'):props.criteriaGroup[props.SelectedGroup].criteriaGroupName} 
				/>
				<div style={{boxSizing:'border-box',display:'flex',flexDirection:'column',marginRight:'20px',marginBottom:10}}>
          <div onClick={props.handleCancelEditCriteria} className='mousehover' style={{fontSize:f12,fontWeight:700,border:props.theme?'1px solid #EFEFEF50':'1px solid #1D1D1D50',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
            {L('Cancel')}
          </div>
        </div>
			</div>
			<div style={{display:'flex',position:'relative',flexDirection:'column',width:'100%',textAlign:'center',justifyContent:'flex-end',flex:3,backgroundColor:props.theme?'#0F4E5C':'#F3F3F3'}}>
				
				<div style={{position:'absolute',top:0,left:0,right:0,bottom:0,display:'flex',flexDirection:'column',overflow:'auto',padding:'10px'}}>
          <div ref={el => scrollRef.current= el} style={{}}/>
          {props.SelectedGroup==-1?
            <div>
              {/*{props.FavoriteCriteriaList.map((item,index)=>
                <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':'transparent'),borderRadius:4,padding:10,border: '1px solid #007A8A',marginTop:10}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria({criteriaID:item},0)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(props.criteriaDict[item].criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    <div className='mousehover'>{false?<div/>:<MinusIcon/>}</div>
                  </div>
                  <div>matchingType: {props.criteriaDict[item].matchingType} valueType:{props.criteriaDict[item].valueType}</div>
                  <div>minValue: {props.criteriaDict[item].minValue} maxValue:{props.criteriaDict[item].maxValue}</div>
                </div>
              )}*/}
              {props.FavoriteCriteriaList.map((item,index)=>
                props.criteriaDict[item].criteriaID===121||props.criteriaDict[item].criteriaID===122?
                <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:isSelected(props.criteriaDict[item])?(props.theme?"#043A4B":'#FFFFFF'):(props.theme?'#043A4BB3':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(props.criteriaDict[item],null)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(props.criteriaDict[item].criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(props.criteriaDict[item],null)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                  </div>
                  <div style={{display:'flex',flexFlow:'wrap',opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                    {props.criteriaDict[item].possibleDisplayValues.map((item1,index1)=>
                      <div key={index1} onClick={props.handleCriteriaMultiSelect(props.criteriaDict[item],null,item1,index1)} className='mousehover' style={{width:"33%",display:'flex',flexDirection:"row",marginTop:10,}}> 
                        <div style={{width:12,height:12,backgroundColor:!isMultiSelected(props.criteriaDict[item],item1)?'transparent':(props.theme?'white':'#258CF4'), border:props.theme?"0.5px solid white":"0.5px solid black",borderRadius:3,}}/>
                        &nbsp;&nbsp;{item1}
                      </div>
                    )}
                  </div>
                </div>:
                (props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===3)
                ||(props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===4)
                ||(props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===5)
                ||(props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===6)
                ||(props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===7)?
                <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:isSelected(props.criteriaDict[item])?(props.theme?"#043A4B":'#FFFFFF'):(props.theme?'#043A4BB3':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(props.criteriaDict[item],null)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(props.criteriaDict[item].criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(props.criteriaDict[item],null)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    {/*<div className='mousehover' onClick={props.handlePlusMinusButton(props.criteriaDict[item])}>{isSelected(props.criteriaDict[item])?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <DropDownMenu 
                    dropboxstyle={{backgroundColor:props.theme?'#081F26':(isSelected(props.criteriaDict[item])?'#DDDDDD':'#EDEDED')}}
                    itemstyle={{backgroundColor:props.theme?'#081F26':(isSelected(props.criteriaDict[item])?'#DDDDDD':'#EDEDED'),display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
                    onClick={props.handleSelectCriteria(props.criteriaDict[item],null)} 
                    data={getArray(props.criteriaDict[item])} 
                    value={getDisplayValue(props.criteriaDict[item])} 
                    style={{backgroundColor:(props.theme?'#143038':(isSelected(props.criteriaDict[item])?'#DDDDDD':'#EDEDED')),width:'100%',marginTop:10,fontSize:f14}}
                  />
                </div>
                :(props.criteriaDict[item].matchingType===0&&props.criteriaDict[item].valueType===8)?
                <div key={index} style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(props.criteriaDict[item])?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria({criteriaID:item},0)} className='mousehover' style={{marginRight:5,}}><StarredIcon/></div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(props.criteriaDict[item],null)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    {/*<div onClick={props.handlePlusMinusButton(props.criteriaDict[item])} className='mousehover'>{isSelected(props.criteriaDict[item])?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(props.criteriaDict[item])} type='number' value={getDisplayValue(props.criteriaDict[item])}  onChange={props.handleCriteriaInput(props.criteriaDict[item])} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Please Enter'/>
                    </div>
                  </div>
                </div>
                :(props.criteriaDict[item].matchingType===1&&props.criteriaDict[item].valueType===2)?
                <div key={index} style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(props.criteriaDict[item])?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria({criteriaID:item},0)} className='mousehover' style={{marginRight:5,}}><StarredIcon/></div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(props.criteriaDict[item],null)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                   {/* <div className='mousehover' onClick={props.handlePlusMinusButton(props.criteriaDict[item])}>{isSelected(props.criteriaDict[item])?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(props.criteriaDict[item])} type='number' value={getDisplayValue(props.criteriaDict[item],'min')} onChange={props.handleCriteriaInput(props.criteriaDict[item],'min')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Min'/>
                     %
                    </div>
                    <div style={{margin:'0 5px 0 5px'}}>to</div>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(props.criteriaDict[item])} type='number' value={getDisplayValue(props.criteriaDict[item],'max')} onChange={props.handleCriteriaInput(props.criteriaDict[item],'max')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Max'/>
                      %
                    </div>
                    <div className='mousehover' onClick={props.handleCriteriaReset(props.criteriaDict[item])} style={{opacity:.5,textDecoration:'underline',margin:'0 5px 0 5px'}}>Reset</div>
                  </div>
                </div>
                :(props.criteriaDict[item].matchingType===1&&props.criteriaDict[item].valueType===8)?
                <div key={index} style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(props.criteriaDict[item])?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria({criteriaID:item},0)} className='mousehover' style={{marginRight:5,}}><StarredIcon/></div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(props.criteriaDict[item],null)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    {/*<div className='mousehover' onClick={props.handlePlusMinusButton(props.criteriaDict[item])}>{isSelected(props.criteriaDict[item])?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                      
                     <input onBlur={props.onBlur(props.criteriaDict[item])} type='number' value={getDisplayValue(props.criteriaDict[item],'min')} onChange={props.handleCriteriaInput(props.criteriaDict[item],'min')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Min'/>
                    </div>
                    <div style={{margin:'0 5px 0 5px'}}>to</div>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                      
                     <input onBlur={props.onBlur(props.criteriaDict[item])} type='number' value={getDisplayValue(props.criteriaDict[item],'max')} onChange={props.handleCriteriaInput(props.criteriaDict[item],'max')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Max'/>
                    </div>
                    <div className='mousehover' onClick={props.handleCriteriaReset(props.criteriaDict[item])} style={{opacity:.5,textDecoration:'underline',margin:'0 5px 0 5px'}}>Reset</div>
                  </div>
                </div>
                :
                <div key={index} style={{opacity:isSelected(props.criteriaDict[item])?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(props.criteriaDict[item])?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(props.criteriaDict[item])?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria({criteriaID:item},0)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(props.criteriaDict[item].criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {props.criteriaDict[item].criteriaName}
                      <div className='mousehover' style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    {/*<div className='mousehover' onClick={props.handlePlusMinusButton(props.criteriaDict[item])}>{isSelected(props.criteriaDict[item])?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div>matchingType: {props.criteriaDict[item].matchingType} valueType:{props.criteriaDict[item].valueType}</div>
                  <div>minValue: {props.criteriaDict[item].minValue} maxValue:{props.criteriaDict[item].maxValue}</div>
                </div>
              )}
              
              {props.FavoriteCriteriaList.length===0&&
                <div style={{fontWeight:700,fontSize:f14}}>
                  {L('fav1')}
                  <br/><br/>
                  {L('fav2')}
                  <br/><br/>
                  {L('fav3')}
                </div>
              }
              <div style={{width:'100%',minHeight:200}}/>
            </div>

            :

            <div style={{display:'flex',flexDirection:'column',marginBottom:'20px'}}>
              
              {props.criteriaGroup[props.SelectedGroup].criteriaList.map((criteria,criteria_index)=>
                criteria.criteriaID===121||criteria.criteriaID===122?
                <div key={criteria_index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:isSelected(criteria)?(props.theme?"#043A4B":'#FFFFFF'):(props.theme?'#043A4BB3':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(criteria,criteria_index)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                  </div>
                  <div style={{display:'flex',flexFlow:'wrap',opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                    {criteria.possibleDisplayValues.map((item,index)=>
                      <div key={index} onClick={props.handleCriteriaMultiSelect(criteria,criteria_index,item,index)} className='mousehover' style={{width:"33%",display:'flex',flexDirection:"row",marginTop:10,}}> 
                        <div style={{width:12,height:12,backgroundColor:!isMultiSelected(criteria,item)?'transparent':(props.theme?'white':'#258CF4'), border:props.theme?"0.5px solid white":"0.5px solid black",borderRadius:3,}}/>
                        &nbsp;&nbsp;{item}
                      </div>
                    )}
                  </div>
                </div>:
                (criteria.matchingType===0&&criteria.valueType===3)
                ||(criteria.matchingType===0&&criteria.valueType===4)
                ||(criteria.matchingType===0&&criteria.valueType===5)
                ||(criteria.matchingType===0&&criteria.valueType===6)
                ||(criteria.matchingType===0&&criteria.valueType===7)?
                <div key={criteria_index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:isSelected(criteria)?(props.theme?"#043A4B":'#FFFFFF'):(props.theme?'#043A4BB3':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(criteria,criteria_index)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                    {/*<div className='mousehover' onClick={props.handlePlusMinusButton(criteria)}>{isSelected(criteria)?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <DropDownMenu
                    dropboxstyle={{backgroundColor:props.theme?'#081F26':(isSelected(criteria)?'#DDDDDD':'#EDEDED')}}
                    itemstyle={{backgroundColor:props.theme?'#081F26':(isSelected(criteria)?'#DDDDDD':'#EDEDED'),display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
                    onClick={props.handleSelectCriteria(criteria,criteria_index)} 
                    data={getArray(criteria)} 
                    value={getDisplayValue(criteria)} 
                    style={{backgroundColor:(props.theme?'#143038':(isSelected(criteria)?'#DDDDDD':'#EDEDED')),width:'100%',marginTop:10,fontSize:f14,}}
                  />
                </div>
                :(criteria.matchingType===0&&criteria.valueType===8)?
                <div key={criteria_index} style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(criteria)?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(criteria,criteria_index)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                   {/* <div className='mousehover' onClick={props.handlePlusMinusButton(criteria)}>{isSelected(criteria)?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(criteria)} type='number' value={getDisplayValue(criteria)}  onChange={props.handleCriteriaInput(criteria)} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Please Enter'/>
                    </div>
                  </div>
                </div>
                :(criteria.matchingType===1&&criteria.valueType===2)?
                <div key={criteria_index} style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(criteria)?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(criteria,criteria_index)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                   {/* <div className='mousehover' onClick={props.handlePlusMinusButton(criteria)}>{isSelected(criteria)?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(criteria)} type='number' value={getDisplayValue(criteria,'min')} onChange={props.handleCriteriaInput(criteria,'min')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Min'/>
                     %
                    </div>
                    <div style={{margin:'0 5px 0 5px'}}>to</div>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                     <input onBlur={props.onBlur(criteria)} type='number' value={getDisplayValue(criteria,'max')} onChange={props.handleCriteriaInput(criteria,'max')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Max'/>
                      %
                    </div>
                    <div className='mousehover' onClick={props.handleCriteriaReset(criteria)} style={{opacity:.5,textDecoration:'underline',margin:'0 5px 0 5px'}}>Reset</div>
                  </div>
                </div>
                :(criteria.matchingType===1&&criteria.valueType===8)?
                <div key={criteria_index} style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(criteria)?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' onClick={props.handleQueryCreteria(criteria,criteria_index)} style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                  {/*  <div className='mousehover' onClick={props.handlePlusMinusButton(criteria)}>{isSelected(criteria)?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                      
                     <input onBlur={props.onBlur(criteria)} type='number' value={getDisplayValue(criteria,'min')} onChange={props.handleCriteriaInput(criteria,'min')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Min'/>
                    </div>
                    <div style={{margin:'0 5px 0 5px'}}>to</div>
                    <div style={{flex:1,display:'flex',flexDirection:'row',border:(props.theme?'1px solid white':'1px solid transparent'),backgroundColor:props.theme?'transparent':'#E9E9E9',borderRadius:4,height:29,boxSizing:'border-box',alignItems:'center',justifyContent:'space-between',padding:'0 10px 0 10px'}}>
                      
                     <input onBlur={props.onBlur(criteria)} type='number' value={getDisplayValue(criteria,'max')} onChange={props.handleCriteriaInput(criteria,'max')} style={{width:'90%',backgroundColor:'transparent',border:'none',color:(props.theme?'white':'black'),boxSizing:'border-box',paddingLeft:5,outlineWidth: 0}} placeholder='Max'/>
                    </div>
                    <div className='mousehover' onClick={props.handleCriteriaReset(criteria)} style={{opacity:.5,textDecoration:'underline',margin:'0 5px 0 5px'}}>Reset</div>
                  </div>
                </div>
                :
                <div key={criteria_index} style={{opacity:isSelected(criteria)?1:.7,width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column',backgroundColor:false?"transparent":(props.theme?'#043A4B':isSelected(criteria)?'white':'transparent'),borderRadius:4,padding:10,border: isSelected(criteria)?(props.theme?'1px solid #007A8A':'1.5px solid #73B1F0'):(props.theme?'1px solid #043A4B':'1px solid #DFDFDF'),marginTop:5}}>
                  <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{fontSize:f14,display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div onClick={props.handleModifyFavCriteria(criteria,criteria_index)} className='mousehover' style={{marginRight:5,}}>{props.FavoriteCriteriaList.includes(criteria.criteriaID)?<StarredIcon theme={props.theme}/>:<StarIcon theme={props.theme}/>}</div>
                      {criteria.criteriaName}
                      <div className='mousehover' style={{marginLeft:5,position:'relative',top:3}}><InquiryIcon theme={props.theme}/></div>
                    </div>
                   {/* <div className='mousehover' onClick={props.handlePlusMinusButton(criteria)}>{isSelected(criteria)?<MinusIcon/>:<div/>}</div>*/}
                  </div>
                  <div>matchingType: {criteria.matchingType} valueType:{criteria.valueType}</div>
                  <div>minValue: {criteria.minValue} maxValue:{criteria.maxValue}</div>
                </div>
              )}
            </div>
          }
          <div style={{width:'100%',minHeight:200}}/>
        </div>




			</div>
			<div style={{display:'flex',position:'relative',flexDirection:'column',width:'100%',textAlign:'center',justifyContent:'flex-end',flex:2,backgroundColor:props.theme?'#0F4E5C':'#F3F3F3',borderTop:props.theme?'5px solid #12222F':'5px solid white'}}>
			
				<div style={{position:'absolute',top:0,left:0,right:0,bottom:0,display:'flex',flexDirection:'column',overflow:'auto',padding:'10px'}}>
					<div style={{position:'absolute',display:'flex',flexDirection:'column',top:0,left:0,right:0,bottom:0,overflow:'auto'}}>
            <div style={{fontSize:f10,fontWeight:700,marginBottom:'10px',textAlign:'left',marginLeft:40}}>{L('Preview')}</div>
            <div style={{fontSize:f10,fontWeight:400,color:props.theme?'#BFBFBF':'#667082',display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'space-between',textAlign:'left'}}>
              <div  style={{width:40}}></div>
              <div style={{flex:3}}>{L('CRITERIA')}</div>
              <div style={{flex:4}}>{L("VALUE")}</div>
              <div style={{flex:2}}>{L("REMAINING")}</div>
            </div>
            {props.SelectedCritiaList.map((item,index)=>
              item.isDelete?
              <div key={index}/>
              :<div key={index} style={{fontSize:f8,fontWeight:400,display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'space-between',marginTop:10,textAlign:'left'}}>
                <div className='mousehover' onClick={props.handlePlusMinusButton(item,true)} style={{width:40,display:'flex',justifyContent:'center'}}><MinusIcon2/></div>
                <div style={{flex:3,color:props.theme?'white':'#2C323D'}}>{props.criteriaDict[item.criteriaID]&&props.criteriaDict[item.criteriaID].criteriaName||'unknown'}</div>
                <div style={{flex:4,color:props.theme?'white':'#4D5563'}}>{
                  ((props.criteriaDict[item.criteriaID]&&props.criteriaDict[item.criteriaID].matchingType===1&&props.criteriaDict[item.criteriaID].valueType===8)||
                    (props.criteriaDict[item.criteriaID]&&props.criteriaDict[item.criteriaID].matchingType===1&&props.criteriaDict[item.criteriaID].valueType===2))?
                  `${(item.minValue!==undefined&&item.minValue!=='')?`>${item.minValue} `:''}${(item.maxValue!==undefined&&item.maxValue!=='')?`<${item.maxValue}`:''}`
                  :item.display
                }</div>
                <div style={{flex:2,color:props.theme?'white':'#4D5563'}}>{props.CriteriaFilteringResult&&props.CriteriaFilteringResult[item.criteriaID]||0}</div>
              </div>
            )}
          </div>
				</div>
			</div>
			<div style={{display:'flex',position:'relative',flexDirection:'column',width:'100%',textAlign:'center',justifyContent:'flex-end',padding:20,boxSizing:'border-box'}}>
				<div style={{marginBottom:'10px',fontSize:f14,fontWeight:700,display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'space-between'}}>
          <div style={{}}>{L('Results')}</div>
          <div style={{}}>{
            props.CriteriaFilteringResult
            &&props.SelectedCritiaList[props.SelectedCritiaList.length-1]
            &&props.CriteriaFilteringResult[props.SelectedCritiaList[props.SelectedCritiaList.length-1].criteriaID]||0}</div>
        </div>
        <div style={{fontSize:f14,fontWeight:700,display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'space-between'}}>
          <div className='mousehover' onClick={props.handleViewScreenResult()} style={{minHeight:'35px',color:'#FFFFFF',backgroundColor:props.theme?'#3E78B4':'#258CF4',borderRadius:4,flex:4,display:'flex',justifyContent:'center',alignItems:'center'}}>
            {L('View Screen Results')}
          </div>
        </div>
			</div>
		</div>
	)
}



