import React from "react"
import './DropDownMenu.css'
import {DownArrowIcon} from '../../icons'

function DropDownMenu(props){
	const [isExpand,setisExpand]= React.useState(false)
	return (
		<div className='dropbody' style={{padding:'5px 10px 5px 10px',...props.style}} onClick={()=>{setisExpand(!isExpand)}}>
			{isExpand&&<div className='dropmask' onClick={()=>{setisExpand(false)}} />}
			<div className='selectedtext'>
				{props.value||'item'}
				<DownArrowIcon/>
			</div>
			<div style={{position: 'absolute',width: '100%',top:`26px`,left:0,display:'flex',flexDirection:'column','boxSizing': 'border-box',zIndex: 6,maxHeight:150,}}>
				<div style={{width:'100%',height:'100%',overflow:'auto',display:'flex',flexDirection:'column','boxSizing': 'border-box',borderRadius:4,...props.dropboxstyle}}>
				{isExpand&&props.data.map((item,index)=>
					<div 
						className='dropitem' 
						key={index} 
						onClick={()=>{props.onClick(item,index);setisExpand(false)}} 
						style={{...props.itemstyle||{}}}>
						{item.value}
					</div>
				)}
				</div>
			</div>
		</div>
	)
}

export default DropDownMenu