export const ReqAddScreenFolder=(ws,folderName,folderID=null)=>{
    let data ={
      request: "ReqAddScreenFolder", 
      folderName:folderName,
      requestID: 1,
    }
    if(folderID!==null)
        data.folderID=folderID
    console.log(data)

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqAddScreenFolder ERROR:'+ JSON.stringify(error)) // catch error
    }
  }