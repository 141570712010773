export const ReqIndustryList=(ws,marketID)=>{
    let data ={
      request: "ReqIndustryList", 
      marketID:marketID,
      requestID: 1,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqIndustryList ERROR:'+ JSON.stringify(error)) // catch error
    }
  }