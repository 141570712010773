
export const ReqScreenCriteria=(ws,screenID,requestID=1)=>{
    let data ={
      request: "ReqScreenCriteria", 
      screenID:screenID,
      requestID: requestID,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqScreenCriteria ERROR:'+ JSON.stringify(error)) // catch error
    }
  }