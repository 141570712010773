//uppper layer height
const LH_1=120
//bottom layer height
const LH_2=80
//vertical line height
const LH_3=1
//width for collapsed components
const C_W=30
//default width after uncollapse
const UC_W=160
//min and max Width
const min_W=30
const max_W=900

const min_H=10
const max_H=190

export const LH1=LH_1
export const LH2=LH_2
export const LH3=LH_3
export const CW=C_W
export const UCW=UC_W
export const MinW=min_W
export const MaxW=max_W
export const MinH=min_H
export const MaxH=max_H

export const INITLAYOUT=[
      {i: 'screens', x: 0, y: 0, w: 160, h: LH1, minW: min_W, maxW: 900, minH: LH1, maxH: LH1, resizeHandles: ['e']},
      {i: 'b', x: 160, y: 0, w: 580, h: LH1, minW: min_W, maxW: 900, minH: LH1, maxH: LH1, resizeHandles: ['e']},
      {i: 'c', x: 740, y: 0, w: 160, h: LH1, minW: min_W, maxW: 900, minH: LH1, maxH: LH1, resizeHandles: []},
      {i: 'd', x: 0, y: LH1+LH3, w: 140, h: LH2, minW: min_W, maxW: 900, minH: LH2, maxH: LH2, resizeHandles: ['e']},
      {i: 'e', x: 140, y: LH1+LH3, w: 760, h: LH2, minW: min_W, maxW: 900, minH: LH2, maxH: LH2, resizeHandles: []},
      {i: 'adj_vertical', x: 0, y: LH1, w: 900, h: LH3, minW: 900, maxW: 900, minH: LH3, maxH: LH3, resizeHandles: []}
    ]

export const LAYOUT1=[
    {i: 'screens', x: 0, y: max_H+LH_3, w: 180, h: min_H, minW: min_W, maxW: 900, minH: min_H, maxH: min_H, resizeHandles: ['e']},
    {i: 'b', x: 0, y: 0, w: 900, h: max_H, minW: min_W, maxW: 900, minH: max_H, maxH: max_H, resizeHandles: []},
    {i: 'c', x: 360, y: max_H+LH_3, w: 180, h: min_H, minW: min_W, maxW: 900, minH: min_H, maxH: min_H, resizeHandles: ['e']},
    {i: 'd', x: 180, y: max_H+LH_3, w: 180, h: min_H, minW: min_W, maxW: 900, minH: min_H, maxH: min_H, resizeHandles: ['e']},
    {i: 'e', x: 540, y: max_H+LH_3, w: 360, h: min_H, minW: min_W, maxW: 900, minH: min_H, maxH: min_H, resizeHandles: []},
    {i: 'adj_vertical', x: 0, y: max_H, w: 900, h: LH_3, minW: 900, maxW: 900, minH: LH_3, maxH: LH_3, resizeHandles: []}
  ]

export const LAYOUT2=[
        {i: 'screens', x: 0, y: 0, w: min_W, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: ['e']},
        {i: 'b', x: min_W, y: 0, w: 900-min_W*2, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: ['e']},
        {i: 'c', x: 900-min_W, y: 0, w: min_W, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: []},
        {i: 'd', x: 0, y: LH_1+LH_3, w: min_W, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: ['e']},
        {i: 'e', x: min_W, y: LH_1+LH_3, w: 900-min_W, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: []},
        {i: 'adj_vertical', x: 0, y: LH_1, w: 900, h: LH_3, minW: 900, maxW: 900, minH: LH_3, maxH: LH_3, resizeHandles: []}
      ]

export const LAYOUT3=[
        {i: 'screens', x: 0, y: LH_1+LH_3, w: 180, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: ['e']},
        {i: 'b', x: 0, y: 0, w: 900, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: []},
        {i: 'c', x: 360, y: LH_1+LH_3, w: 180, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: ['e']},
        {i: 'd', x: 180, y: LH_1+LH_3, w: 180, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: ['e']},
        {i: 'e', x: 540, y: LH_1+LH_3, w: 360, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: []},
        {i: 'adj_vertical', x: 0, y: LH_1, w: 900, h: LH_3, minW: 900, maxW: 900, minH: LH_3, maxH: LH_3, resizeHandles: []}
      ]

export const LAYOUT4=[
        {i: 'screens', x: 0, y: 0, w: 160, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: ['e']},
        {i: 'b', x: 160, y: 0, w: 580, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: ['e']},
        {i: 'c', x: 740, y: 0, w: 160, h: LH_1, minW: min_W, maxW: 900, minH: LH_1, maxH: LH_1, resizeHandles: []},
        {i: 'd', x: 0, y: LH_1+LH_3, w: 140, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: ['e']},
        {i: 'e', x: 140, y: LH_1+LH_3, w: 760, h: LH_2, minW: min_W, maxW: 900, minH: LH_2, maxH: LH_2, resizeHandles: []},
        {i: 'adj_vertical', x: 0, y: LH_1, w: 900, h: LH_3, minW: 900, maxW: 900, minH: LH_3, maxH: LH_3, resizeHandles: []}
      ]


