import React,{useState,useEffect,useRef} from 'react';
import {formatDate, formatTime} from './../util'
import {
	CollapseIcon_Down,
	CollapseIcon_UP,
	ChecklistIcon,
	ScreensIcon,
	WatchListIcon,

	NewFolderIcon,
  DownArrowIcon,
  NewListIcon,
  DragIcon,
  MinimizeIcon,
  FolderIcon,
  DotIcon,
  UpArrowIcon,
  FolderIcon2,
  EditIcon,
  DotDotDotIcon,
  MinimizeIcon2

} from '../icons'
import lang from '../lang.json'
import DropDownMenu from './components/DropDownMenu'
const f8='8px'

export default function MobileFolders(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}

	const [isCollapsed,setisCollapsed]=useState(false)
	const handleisCollapsed=()=>{setisCollapsed(!isCollapsed)}

	const [type,settype]=useState('checklist')
	const handleSelectType=(type)=>()=>{settype(type)}

	const getSelectArray=()=>{
    let folder=props.screenListFolder
    let arr=[]
    for(let i in folder)
      for(let j in folder[i].screenList)
        arr.push({value:folder[i].screenList[j].screenName ,screenID:folder[i].screenList[j].screenID,folderIndex:i,screenIndex:j})
		return arr
	}
	const countPass=()=>{
		let count=0
		for(let i of props.PassFailscreenResult)
		  if(i.pass)
		    count+=1
		return count
	}
	const calPassPercent=()=>{
		if(props.PassFailscreenResult.length===0)return 0
		let count=0
		for(let i of props.PassFailscreenResult)
		  if(i.pass)
		    count+=1
		return Math.round(count/props.PassFailscreenResult.length*100)
	}
	const findValue=(c,ci)=>{
		for(let i of props.PassFailCritiaList)
		  if(i.criteriaID===c.criteriaID)
		    return ((props.criteriaDict[i.criteriaID]&&props.criteriaDict[i.criteriaID].matchingType===1&&props.criteriaDict[i.criteriaID].valueType===8)||
		        (props.criteriaDict[i.criteriaID]&&props.criteriaDict[i.criteriaID].matchingType===1&&props.criteriaDict[i.criteriaID].valueType===2))?
		      `${(i.minValue!==undefined&&i.minValue!=='')?`>${i.minValue} `:''}${(i.maxValue!==undefined&&i.maxValue!=='')?`<${i.maxValue}`:''}`
		      :i.display
		    return ''
	}

	return(
		<div style={{display:'flex',flexDirection:'column',width:'100%',flex:isCollapsed?0:1,backgroundColor:props.theme?'#0F4E5C':'#D8D8D8',}}>
			 <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'center',boxSizing:'border-box',alignItems:'center'}}>
				<div className='mousehover' onClick={handleisCollapsed} style={{fontSize:f8,display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px 0 10px 0'}}>
					{!isCollapsed?<CollapseIcon_Down theme={props.theme}/>:<CollapseIcon_UP theme={props.theme}/> }
					<div style={{color:isCollapsed?(props.theme?'#FFFFFF99':'#00000099'):(props.theme?'#FFFFFF4D':'#0000004D'),marginLeft:5}}>{isCollapsed?'Expand':'Collapse'}</div>
				</div>
			</div>
			<div style={{width:'100%',flex:1,display:isCollapsed?'none':'flex',flexDirection:'row'}}>
				<div style={{fontSize:f8,width:'60px',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'center'}}>
					<div className='mousehover' onClick={handleSelectType('checklist')} style={{backgroundColor:type==='checklist'?(props.theme?'#043A4B':'#E3E3E3'):'transparent',display:'flex',justifyContent:'center',alignItems:'center',flex:1,flexDirection:'column',width:'100%'}}>
						<ChecklistIcon theme={props.theme}/>
						{L('CheckList')}
					</div>
					<div className='mousehover' onClick={handleSelectType('screen')} style={{backgroundColor:type==='screen'?(props.theme?'#043A4B':'#E3E3E3'):'transparent',display:'flex',justifyContent:'center',alignItems:'center',flex:1,flexDirection:'column',width:'100%'}}>
						<ScreensIcon theme={props.theme}/>
						{L('screen')}
					</div>
					<div className='mousehover' onClick={handleSelectType('watchlist')} style={{backgroundColor:type==='watchlist'?(props.theme?'#043A4B':'#E3E3E3'):'transparent',display:'flex',justifyContent:'center',alignItems:'center',flex:1,flexDirection:'column',width:'100%'}}>'
						<WatchListIcon theme={props.theme}/>
						{L('List')}
					</div>
				</div>
				<div style={{flex:1,backgroundColor:props.theme?'#043A4B':'#E3E3E3',position:'relative',display:'flex',height:'100%',flexDirection:'column'}}>
					<div style={{position:'absolute',top:0,bottom:0,left:0,right:0,overflowY:'auto',display:'flex'}}>
						{
							type==='checklist'?
							<div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
					            <div style={{fontSize:f8,marginBottom:5}}>{L("Base your Checklist on this screen")}</div>
					            <DropDownMenu 
					              dropboxstyle={{backgroundColor:props.theme?'#294F4C':'#DEEEED'}}
					              itemstyle={{backgroundColor:props.theme?'#294F4C':'#DEEEED',display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
					              value={(props.selectedPassFailScreen&&props.selectedPassFailScreen.value)||L('Please Select')} 
					              data={getSelectArray()} onClick={props.handleselectedPassFailScreen} 
					              style={{width:'100%',backgroundColor:props.theme?'#294F4C':' #DEEEED',fontSize:f8,fontWeight:500}}
					            />
					            <div style={{fontSize:f8,fontWeight:500,marginTop:20}}>{countPass()} of {props.PassFailscreenResult.length} ({calPassPercent()}%)</div>
					            <div style={{fontSize:f8,fontWeight:300}}>{L("items meet the benchmark criteria")}</div>

					            <div style={{fontSize:f8,position:'relative',display:'flex',flexDirection:'column',width:'100%',flex:1,backgroundColor: props.theme?'#0000004D':'#F3F3F3',borderRadius: '5px',marginTop:5}}>
					              <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,overflowY:'scroll'}}>
					                {props.PassFailscreenResult.map((c,ci)=>
					                  <div key={ci} style={{padding:10,display:'flex',flexDirection:'row',width:'100%',boxSizing:'border-box',justifyContent:'space-between',alignItems:'center',color:c.pass?(props.theme?"white":'black'):'#FF434F'}}>
					                    <div style={{textAlign:'left'}}>
					                      {props.criteriaDict[c.criteriaID]&&props.criteriaDict[c.criteriaID].criteriaName||'unknown'}
					                      &nbsp;{findValue(c,ci)}
					                    </div>
					                    <div>{c.pass?'Pass':'Fail'}</div>
					                  </div>
					                )}
					              </div>
					            </div>
					        </div>
							:type==='screen'?
							<div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
								<div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',minHeight:25,width:'100%'}}>
									<div className='mousehover' onClick={props.handleStartCreateScreenFolder} style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:80}}>
							            <NewFolderIcon height={14} width={14}  theme={props.theme}/>
							            <div style={{fontSize:f8,marginLeft:'5px'}}>{L('Create Folder')}</div>
							          </div>
							          <div className='mousehover' onClick={props.handleStartCreateScreen} style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'20px'}}>
							            <NewListIcon height={14} width={14} theme={props.theme}/>
							            <div style={{fontSize:f8,marginLeft:'5px'}}>{L('Create Screen')}</div>
							          </div>
								</div>
								{props.screenListFolder.map((item,index)=>
					               item.notFolder?<div key={index}/>:
					              <div key={index} style={{width:'100%',marginTop:5,borderRadius:'5px',boxSizing:'border-box',backgroundColor:props.theme?'#0000004D':"rgba(255, 255, 255, 0.4)",padding:'5px 0 5px 0'}}>
					                <div style={{position:'relative',fontSize:f8,padding:'6px 12px 6px 12px',boxSizing:'border-box',display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'space-between',paddingLeft:'10px',paddingRight:'10px',height:'25px',boxSizing:'border-box'}}>
					                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
					                     {item.isExpanded?<FolderIcon theme={props.theme}/>:<FolderIcon2 theme={props.theme}/>}
					                    <div className='mousehover' onClick={props.handleExpandScreenFolder(item,index)} style={{marginLeft:5,fontSize:f8,whiteSpace: 'nowrap'}}>{item.folderName}</div>
					                    <div className='mousehover' onClick={props.handleIsEditingScreenFolder(item,index)} style={{marginLeft:10,padding:5}}><EditIcon/></div>
					                  </div>
					                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
					                    <div  style={{display:'flex',height:'100%',alignItems:'center',justifyContent:'center'}}>
					                      {item.isEditing&&<div style={{position:'absolute',top:'100%',right:0,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#2E3538':'#DDDDDD',padding:'5px',zIndex:3,width:'100%',alignItems:'flex-end',fontSize:f8}}>
					                        <div className='mousehover' onClick={props.handleStartModifyScreenFolder(item,index)}>{L("Change Name")}</div>
					                        <div onClick={props.handleStartDeleteScreenFolder(item,index)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                    <div className='mousehover' onClick={props.handleExpandScreenFolder(item,index)} style={{position:'relative',marginLeft:10,padding:5,paddingRight:0}}>
					                     { item.isExpanded?<UpArrowIcon width={13} height={7} theme={props.theme}/>:<DownArrowIcon theme={props.theme}/>}
					                    </div>
					                  </div>
					                </div>
					                {item.isExpanded&&item.screenList.map((screen,screenIndex)=>
					                  <div key={screenIndex} style={{backgroundColor:(props.SelectedList.type==='screen'&&props.SelectedList.index===index&&props.SelectedList.index2===screenIndex)?'rgba(71, 180, 227, 0.3)':'transparent',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f8,boxSizing:'border-box',padding:'5px'}}>
					                    <div className='mousehover' onClick={props.handleSelectScreen(item,index,screen,screenIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center",width:'100%',overflow:'hidden',height:'22px',boxSizing:'border-box'}}>
					                      <div style={{margin:'0 10px 0 23px'}}><DotIcon theme={props.theme}/></div>
					                      <div style={{whiteSpace: 'nowrap',fontSize:f8}}>{screen.screenName}</div>
					                    </div>
					                    <div  style={{position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
					                      <div className='mousehover' onClick={props.handleIsEditingscreen(item,index,screen,screenIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
					                      {screen.isEditing&&<div style={{position:'absolute',top:'30px',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':'#DDDDDD',padding:'5px',zIndex:3,fontSize:f8,minWidth:93,alignItems:'flex-end'}}>
					                        <div onClick={props.handleStartModifyScreen(item,index,screen,screenIndex)} className='mousehover'>{L("Change Name")}</div>
					                        <div onClick={props.handleStartEditCriteria(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Screen Edit")}</div>
					                        <div onClick={props.handleStartDeletescreen(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                  </div>
					                )}
					              </div>
					            )}

					            {props.screenListFolder.map((item,index)=>
					               item.notFolder?
					              <div key={index} style={{width:'100%',boxSizing:'border-box'}}>
					                
					                {item.screenList.map((screen,screenIndex)=>
					                  <div key={screenIndex} style={{backgroundColor:(props.SelectedList.type==='screen'&&props.SelectedList.index===index&&props.SelectedList.index2===screenIndex)?'rgba(71, 180, 227, 0.3)':(props.theme?'#0000004D':"rgba(255, 255, 255, 0.4)"),width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f8,boxSizing:'border-box',padding:'5px',borderRadius:'5px',marginTop:5}}>
					                    <div className='mousehover' onClick={props.handleSelectScreen(item,index,screen,screenIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center",width:'100%',overflow:'hidden',height:'22px',boxSizing:'border-box'}}>
					                      <div style={{margin:'0 10px 0 23px'}}><DotIcon theme={props.theme}/></div>
					                      <div style={{whiteSpace: 'nowrap',fontSize:f8}}>{screen.screenName}</div>
					                    </div>
					                    <div  style={{position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
					                      <div className='mousehover' onClick={props.handleIsEditingscreen(item,index,screen,screenIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
					                      {screen.isEditing&&<div style={{position:'absolute',top:'30px',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':'#DDDDDD',padding:'5px',zIndex:3,fontSize:f8,minWidth:93,alignItems:'flex-end'}}>
					                        <div onClick={props.handleStartModifyScreen(item,index,screen,screenIndex)} className='mousehover'>{L("Change Name")}</div>
					                        <div onClick={props.handleStartEditCriteria(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Screen Edit")}</div>
					                        <div onClick={props.handleStartDeletescreen(item,index,screen,screenIndex)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                  </div>
					                )}
					              </div>
					              :<div key={index}/>
					            )}
					             <div style={{width:'100%',minHeight:'100px'}}/>

							</div>
							:<div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
								<div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',minHeight:25,width:'100%'}}>
									<div className='mousehover' onClick={props.handleStartCreateFolder} style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:80}}>
							            <NewFolderIcon height={14} width={14}  theme={props.theme}/>
							            <div style={{fontSize:f8,marginLeft:'5px'}}>{L('Create Folder')}</div>
							          </div>
							          <div className='mousehover' onClick={props.handleStartCreateWatchList} style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'20px'}}>
							            <NewListIcon height={14} width={14} theme={props.theme}/>
							            <div style={{fontSize:f8,marginLeft:'5px'}}>{L('Create Screen')}</div>
							          </div>
								</div>
								{props.watchListFolder.map((item,index)=>
					              item.notFolder?<div key={index}/>:
					              <div key={index} style={{width:'100%',marginTop:2,border:item.isExpanded?'1px solid #ffffff40':'1px solid transparent',borderRadius:'5px'}}>
					                <div style={{position:'relative',fontSize:f8,padding:'6px 12px 6px 12px',backgroundColor:props.theme?'#0000004D':'rgba(255, 255, 255, 0.3)',boxSizing:'border-box',display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'space-between',paddingLeft:'10px',paddingRight:'10px',borderRadius:'5px'}}>
					                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
					                     <div className='mousehover' onClick={props.handleExpandFolder(item,index)} style={{marginLeft:5,fontSize:f8}}>{item.watchlistFolderName}</div>
					                     <div className='mousehover' onClick={props.handleIsEditingFolder(item,index)} style={{marginLeft:10,padding:4}}><EditIcon/></div>
					                  </div>

					                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
					                    <div  style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}>
					                       {item.isEditing&&<div style={{position:'absolute',top:'100%',right:0,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#2E3538':"#DDDDDD",padding:'5px',zIndex:3,width:'100%',alignItems:'flex-end',fontSize:f8,boxSizing:'border-box'}}>
					                        <div onClick={props.handleStartModifyListFolder(item,index)} className='mousehover'>{L("Change Name")}</div>
					                        <div onClick={props.handleStartDeleteFolder(item,index)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                    <div className='mousehover' onClick={props.handleExpandFolder(item,index)} style={{position:'relative',padding:4,paddingRight:0}}>
					                       { item.isExpanded?<UpArrowIcon width={13} height={7} theme={props.theme}/>:<DownArrowIcon theme={props.theme}/>}
					                    </div>
					                  </div>
					                </div>
					                {item.isExpanded&&item.watchlist.map((watchlist,watchlistIndex)=>
					                  <div key={watchlistIndex} style={{backgroundColor:(props.SelectedList.type==='list'&&props.SelectedList.index===index&&props.SelectedList.index2===watchlistIndex)?'rgba(71, 180, 227, 0.3)':'transparent',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f8,boxSizing:'border-box',padding:'5px'}}>
					                    <div className='mousehover' onClick={props.handleSelectWatchList(item,index,watchlist,watchlistIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
					                      <div style={{margin:'0 10px 0 15px'}}><DotIcon theme={props.theme}/></div>
					                      {watchlist.watchlistName}
					                    </div>
					                    <div  style={{position:'relative',display:'flex',height:'100%'}}>
					                      <div className='mousehover' onClick={props.handleIsEditingWatchlist(item,index,watchlist,watchlistIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
					                      {watchlist.isEditing&&<div style={{position:'absolute',top:'calc( 100% + 5px )',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':"#DDDDDD",padding:'5px',zIndex:3, minWidth:'100px',alignItems:'flex-end',fontSize:f8}}>
					                        <div className='mousehover' onClick={props.handleStartAddStocktoWatchList(item,index,watchlist,watchlistIndex)}>{L("Add Stock")}</div>
					                        <div className='mousehover' onClick={props.handleStartModifyWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("Change Name")}</div>
					                        <div  className='mousehover' onClick={props.handleStartEditListPanel(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("List Edit")}</div>
					                        <div onClick={props.handleStartDeleteWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}} className='mousehover'>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                  </div>
					                )}
					              </div>
					            )}
					            {props.watchListFolder.map((item,index)=>
					              item.notFolder?
					              <div key={index} style={{width:'100%'}}>
					                {item.watchlist.map((watchlist,watchlistIndex)=>
					                  <div key={watchlistIndex} style={{backgroundColor:(props.SelectedList.type==='list'&&props.SelectedList.index===index&&props.SelectedList.index2===watchlistIndex)?'rgba(71, 180, 227, 0.3)':(props.theme?'#0000004D':'rgba(255, 255, 255, 0.3)'),width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f8,boxSizing:'border-box',padding:'5px',borderRadius:'5px',marginTop:2}}>
					                    <div className='mousehover' onClick={props.handleSelectWatchList(item,index,watchlist,watchlistIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
					                      <div style={{margin:'0 10px 0 15px'}}><DotIcon theme={props.theme}/></div>
					                      {watchlist.watchlistName}
					                    </div>
					                    <div  style={{position:'relative',display:'flex',height:'100%'}}>
					                      <div className='mousehover' onClick={props.handleIsEditingWatchlist(item,index,watchlist,watchlistIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
					                      {watchlist.isEditing&&<div style={{position:'absolute',top:'calc( 100% + 5px )',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':"#DDDDDD",padding:'5px',zIndex:3, minWidth:'100px',alignItems:'flex-end',fontSize:f8}}>
					                        <div className='mousehover' onClick={props.handleStartAddStocktoWatchList(item,index,watchlist,watchlistIndex)}>{L("Add Stock")}</div>
					                        <div className='mousehover' onClick={props.handleStartModifyWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("Change Name")}</div>
					                        <div  className='mousehover' onClick={props.handleStartEditListPanel(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("List Edit")}</div>
					                        <div onClick={props.handleStartDeleteWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}} className='mousehover'>{L("Delete")}</div>
					                      </div>}
					                    </div>
					                  </div>
					                )}
					              </div>
					              :<div key={index}/>
					            )}
					            <div style={{width:'100%',minHeight:'100px'}}/>

							</div>
						}
					</div>
				</div>
			</div>
			{props.isEditPopUpMask&&<div onClick={props.handleCancelMask} style={{zIndex:2,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0}}/>}
		</div>
	)
}
