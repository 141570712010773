export const RequestStockList=(ws,marketID)=>{
    let data ={
      request: "ReqStockList", 
      marketID:marketID,
      requestID: 1,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('RequestStockList ERROR:'+ JSON.stringify(error)) // catch error
    }
  }