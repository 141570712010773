import {LAYOUT1,LAYOUT2,LAYOUT3,LAYOUT4} from '../quicklayouts'
export const handleSelectLayout=(setlayoutoutIndex,setlayout)=>(num)=>()=>{
  if(num===1){
    setlayout(LAYOUT1)
  }else if(num===2){
    setlayout(LAYOUT2)
  }else if(num===3){
    setlayout(LAYOUT3)
  }else if(num===4){
    setlayout(LAYOUT4)
  }
  setlayoutoutIndex(num)
}

