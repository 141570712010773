export const ReqLogin=(ws,lang,username,password,siteName,sessionToken)=>{
  let login_data={
    request: "ReqLogin",
    requestID: 1,
    userName: username,//"ssweb-dev",
    siteName : siteName,
    password: password,//"123456",
    sessionToken: sessionToken,
    lang: lang
  }
  try {
    ws.send(JSON.stringify(login_data)) //send data to the server
  } catch (error) {
    console.log('ReqLogin ERROR:'+ JSON.stringify(error)) // catch error
  }
}