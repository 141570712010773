 export const ReqModWatchlistStocks=(ws,watchlistID,requestType,marketID=null,stockCode=null,stockList=[],requestID=1)=>{
    let data ={
      request: "ReqModWatchlistStocks", 
      watchlistID:watchlistID,
      requestType:requestType,
      requestID: requestID,
    }
    if(requestType===0||requestType===1){
        data.marketID=marketID
        data.stockCode=stockCode
    }else if(requestType===2||requestType===3){
        data.stockList=stockList
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqModWatchlistStocks ERROR:'+ JSON.stringify(error)) // catch error
    }
  }