export const ReqDeleteScreen=(ws,screenID)=>{
    let data ={
      request: "ReqDeleteScreen", 
      screenID:screenID,
      requestID: 1,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqDeleteScreen ERROR:'+ JSON.stringify(error)) // catch error
    }
  }