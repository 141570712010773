export const ReqScreenPassFail=(ws,screenID,marketID,stockCode)=>{
    let data ={
      request: "ReqScreenPassFail", 
      screenID: screenID,
      marketID: marketID,
      stockCode: stockCode,
      requestID: 1,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log('ReqScreenPassFail ERROR:'+ JSON.stringify(error)) // catch error
    }
  }