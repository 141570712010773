 export const ReqAddWatchlistFolder=(ws,watchlistFolderName,watchlistFolderID=null)=>{
    let data ={
      request: "ReqAddWatchlistFolder", 
      watchlistFolderName:watchlistFolderName,
      requestID: 1,
    }
    if(watchlistFolderID!==null)
        data.watchlistFolderID=watchlistFolderID
    console.log(data)

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('watchlistFolderName ERROR:'+ JSON.stringify(error)) // catch error
    }
  }