export function formatAMPM(date){
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0'+minutes : minutes;
	var strTime = hours + ':' + minutes + '' + ampm;
	return strTime;
}
export function formatTime(date,second=false){
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	hours = hours < 10 ? '0'+hours : hours;
	minutes = minutes < 10 ? '0'+minutes : minutes;
	seconds = seconds < 10 ? '0'+seconds : seconds;
	var strTime = second?(hours + ':' + minutes+":"+seconds):(hours + ':' + minutes) 
	return strTime;
}
export function formatDate(date,short=false){
	//const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
	const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
	if(short) return `${date.getDate()}-${months[date.getMonth()]}`
	return `${date.getDate()<10?`0${date.getDate()}`:date.getDate()}/${months[date.getMonth()]}/${date.getFullYear()}`
}
export function formatWeekday(date){
	const weekdays = [ "Sun","Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
	return `${weekdays[date.getDay()]}`
}

function swap(items, leftIndex, rightIndex){
	var temp = items[leftIndex];
	items[leftIndex] = items[rightIndex];
	items[rightIndex] = temp;
}
function partition(items, left, right,field) {
	var pivot = items[Math.floor((right + left) / 2)][field]===undefined?'':items[Math.floor((right + left) / 2)][field], //middle element
	i = left, //left pointer
	j = right; //right pointer
	while (i <= j) {
		while ((items[i][field]===undefined?'':items[i][field]) < pivot) {
		i++;
	}
	while ((items[j][field]===undefined?'':items[j][field]) > pivot) {
		j--;
	}
	if (i <= j) {
		swap(items, i, j); //sawpping two elements
			i++;
			j--;
		}
	}
	return i;
}
const quickSort_ = (items, left, right, field) =>{
	var index;
	if (items.length > 1) {
		index = partition(items, left, right,field); //index returned from partition
		if (left < index - 1) { //more elements on the left side of the pivot
			quickSort_(items, left, index - 1,field);
		}
		if (index < right) { //more elements on the right side of the pivot
			quickSort_(items, index, right,field);
		}
	}
	return items;
}

export var quickSort = quickSort_

function getMax(arr,field2){
	let max= arr[0][field2]===undefined?'':arr[0][field2]

	for(let i=1;i<arr.length;i++){
		if(arr[i][field2]>max){
			max=arr[i][field2]
			console.log(max)
		}
	}
	return max
}
function partition2(items, left, right, field, field2) {
	let arr = items[Math.floor((right + left) / 2)][field]
	var pivot = arr.length===0?'':getMax(arr,field2), //middle element
	i = left, //left pointer
	j = right; //right pointer
	while (i <= j) {
		while ((items[i][field].length===0?'':getMax(items[i][field],field2))< pivot) {
		i++;
	}
	while ((items[j][field].length===0?'':getMax(items[j][field],field2)) > pivot) {
		j--;
	}
	if (i <= j) {
		swap(items, i, j); //sawpping two elements
			i++;
			j--;
		}
	}
	return i;
}
const quickSort2_ = (items, left, right, field, field2) =>{
	var index;
	if (items.length > 1) {
		index = partition2(items, left, right,field, field2); //index returned from partition
		if (left < index - 1) { //more elements on the left side of the pivot
			quickSort2_(items, left, index - 1, field, field2);
		}
		if (index < right) { //more elements on the right side of the pivot
			quickSort2_(items, index, right, field, field2);
		}
	}
	return items;
}

export var quickSort2 = quickSort2_

export function generateTimeFormat(string,dateOnly=false){                                        
	var date = new Date(string);
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	hours = hours < 10 ? '0'+hours : hours;
	minutes = minutes < 10 ? '0'+minutes : minutes;
	seconds = seconds < 10 ? '0'+seconds : seconds;
	var strTime = (hours + ':' + minutes+":"+seconds)
	if(dateOnly)
		return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`

	return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${strTime}`
}

