 export const ReqAddWatchlist=(ws,watchlistName,watchlistFolderID,watchlistID=null,requestID=1)=>{
    let data ={
      request: "ReqAddWatchlist", 
      watchlistName:watchlistName,
      watchlistFolderID:watchlistFolderID,
      requestID: requestID,
    }
    if(watchlistFolderID===null)
        delete data.watchlistFolderID
    if(watchlistID!==null)
        data.watchlistID=watchlistID
    console.log(data)

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqAddWatchlist ERROR:'+ JSON.stringify(error)) // catch error
    }
  }