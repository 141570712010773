import React,{useState,useEffect,useRef} from 'react';
import {formatDate, formatTime} from './../util'
import {PullDownIcon,PullUpIcon} from '../icons'



function useInterval(callback, delay) {
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}



export default function Chart(props){
  useInterval(() => {
    props.nextStock()
  }, props.LoopInterval*1000);
  useEffect(() => {
    props.restartTradingView()
  },[]);

  return(
    
    
    <div style={{display:'flex',width:'100%',height:'100%',borderRadius: '0px 10px 10px 0px',padding:'5px',boxSizing: 'border-box',flexDirection:'column',alignItems:'flex-start'}}>
      <div className="" style={{display:'flex',width:'100%',height:'100%',boxSizing: 'border-box',flexDirection:'column',overflowY:'auto',overflowX:'hidden',justifyContent:'space-between'}}>
        <div style={{width:'100%',boxSizing: 'border-box',textAlign:'left'}}></div>
        <div style={{position:'relative',boxSizing:'border-box',flex:1,display:props.isDragging?'none':'block'}}>
          <div id='tv_chart_container' style={{height:'100%',width:'100%'}}/>
        </div>
        <div onClick={props.VerticalCollapse} className='mousehover' style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',boxSizing:'border-box',padding:3}}>
          {props.isShowBottom()?<PullUpIcon theme={props.theme}/>:<PullDownIcon theme={props.theme}/>}
        </div>
        {/*<div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'column'}}>
          <div style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',fontSize:'10px'}}>
            <div style={{flex:1}}>Close</div>
            <div style={{flex:1}}>High</div>
            <div style={{flex:1}}>Low</div>
            <div style={{flex:1}}>Open</div>
            <div style={{flex:1}}>Time</div>
            <div style={{flex:1}}>Turnover</div>
            <div style={{flex:1}}>Volume</div>
          </div>
          {props.chartDataBars.map((item,index)=>
            <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',fontSize:'10px'}}>
              <div style={{flex:1}}>{item.close}</div>
              <div style={{flex:1}}>{item.high}</div>
              <div style={{flex:1}}>{item.low}</div>
              <div style={{flex:1}}>{item.open}</div>
              <div style={{flex:1}}>{formatDate(new Date(item.time*1000))} {formatTime(new Date(item.time*1000))}</div>
              <div style={{flex:1}}>{item.turnover}</div>
              <div style={{flex:1}}>{item.volume}</div>
            </div>
          )}
        </div>*/}
      </div>
    </div>
  )
}