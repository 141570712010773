export const ReqModScreenCriteria=(ws,screenID,requestType=0,criteriaList,requestID=1)=>{
    let data ={
      request: "ReqModScreenCriteria", 
      screenID:screenID,
      requestType:requestType,
      criteriaList:criteriaList,
      requestID: requestID,

    }
    console.log(data)

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqModScreenCriteria ERROR:'+ JSON.stringify(error)) // catch error
    }
  }