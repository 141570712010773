import React,{useState,useEffect,useRef} from 'react';
import {
  MinimizeIcon,
  MinimizeIcon2,
  DragIcon,
  LoadingIcon1,
  LoadingIcon2,
  SortIcon,
  SortIcon_Down,
  SortIcon_Up,
  DownTriangle,
  NewListIcon,

  CollapseIcon_Middle,
  CollapseIcon_Down
} from '../icons'
import {fidID_Dict} from '../fidID_Dict'
import{StopLoopIcon,CheckIcon,PlayIcon}from '../icons'
import {formatAMPM} from '../util'
import lang from '../lang.json'
const f16='16px'
const f14='14px'
const f12='12px'
const f10='10px'
const f8='8px'
const f7='7px'
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 
export default function MobileList(props){

	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	const [isLoopMenuExpand,setisLoopMenuExpand]=useState(false)
	const handleClickMenu=()=>{
	setisLoopMenuExpand(!isLoopMenuExpand)
	}
	const handleSetLoop=(item)=>()=>{
	setisLoopMenuExpand(!isLoopMenuExpand)
	props.SetListLoopInterval(item)
	}

	// useEffect(() => {
	// 	if(props.displayingStock&&props.displayingStock.stockCode)
	// 		props.ForceUpdateStock(props.displayingStock.stockCode)
	// },[props.displayingStock]);

	const [isCollapsed,setisCollapsed]=useState(false)
	const handleisCollapsed=()=>{setisCollapsed(!isCollapsed)}

  return(
    <div style={{display:'flex',alignItems:'center',flexDirection:'column',width:'100%',flex:isCollapsed?0:1,color:props.theme?'white':'black'}}>
      <div  style={{display:'flex',flexDirection:'column',height:'calc( 100% )',width:'100%',backgroundColor: props.theme?'#11486A':'#E9E9EA',padding:'8px',boxSizing: 'border-box'}}>
        <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',alignItems:'center'}}>
        	<div style={{fontSize:f12,fontWeight:700,width:200}}>
              {props.SelectedList
                ?(props.SelectedList.type==='list'
                  ? props.SelectedList.value.watchlistName
                  : props.SelectedList.type==='screen'
                  ? props.SelectedList.value.screenName
                  : (props.SelectedList.type==='industry'||props.SelectedList.type==='sector')
                  ? props.SelectedList.value
                  :''
                )
                : ""}
            </div>

            <div className='mousehover' onClick={handleisCollapsed} style={{fontSize:f8,display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px 0 10px 0'}}>
				{!isCollapsed?<CollapseIcon_Middle theme={props.theme}/>:<CollapseIcon_Down theme={props.theme}/> }
				<div style={{color:isCollapsed?(props.theme?'#FFFFFF99':'#00000099'):(props.theme?'#FFFFFF4D':'#0000004D'),marginLeft:5}}>{isCollapsed?'Expand':'Collapse'}</div>
			</div>

			<div style={{width:200}}/>
        </div>
        

        <div style={{display:isCollapsed?'none':'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',marginBottom:'10px'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:"flex-end",minHeight:24}}>
            <div style={{fontSize:f8,position:'relative'}}>
              ({L('Total Result')} : <div style={{fontWeight:'bold',display:'inline-block'}}>{props.data.length}</div>)
            </div>
            <div style={{fontSize:f8,marginLeft:20,position:'relative'}}>
              {L("updatetime")} 
              {props.LastUpdatedTime?formatAMPM(props.LastUpdatedTime):'00:00 AM'}
            </div>
            <div className='mousehover' onClick={props.handleDirectAddNewList} style={{marginLeft:10,height:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
              <NewListIcon width={14} height={14} theme={props.theme}/>
            </div>
            {props.isfetchStock&&<div style={{marginLeft:10,position:'relative',height:'100%'}}>
              <div className='App-logo' style={{position:'absolute',top:0,left:0}}>{props.theme?<LoadingIcon1/>:<LoadingIcon2/>}</div>
            </div>} 
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',fontSize:f10}}>

            <div onClick={props.handleClickLoop} className='mousehover' style={{backgroundColor:'#00000033',height:16,width:15,borderRadius:2,marginRight:15,display:'flex',justifyContent:'center',alignItems:'center'}}>
              {props.isLoop?<CheckIcon theme={props.theme}/>:<div/>}
            </div>
            <div style={{cursor: 'pointer',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'55px',height:'16px',backgroundColor:props.theme?'rgb(16,51,72)':'rgb(183,183,183)',position:'relative',borderRadius:2,marginRight:10}}>
              <div onClick={handleClickMenu} style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',height:'100%'}}>
                <div style={{marginRight:10}}>{props.LoopInterval}s</div>
                <DownTriangle theme={props.theme}/>
              </div>
              {isLoopMenuExpand&&
                <div style={{width:'100%',position:'absolute',top:'100%',left:0,backgroundColor:props.theme?'rgb(16,51,72)':'rgb(183,183,183)',display:'flex',flexDirection:'column',zIndex:2}}>
                  {[3,5,10,15,20,25,30].map((item,index)=>
                    <div onClick={handleSetLoop(item)} style={{height:'16px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                      <div style={{marginRight:10}}>{item}s</div>
                      <div style={{opacity:0}}><DownTriangle theme={props.theme}/></div>
                    </div>
                  )}
                </div>
              }
            </div>
            {isLoopMenuExpand&&<div onClick={handleClickMenu} style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:1}}/>}
            <div>{L("Loop")}</div>
            <div className='mousehover' onClick={props.handleClickPlaying} style={{marginLeft:15,marginRight:15,padding:5,paddingRight:0,overflowY:'scroll'}}>
              {props.isPlaying
                ? <StopLoopIcon theme={props.theme}/>
                : <PlayIcon theme={props.theme}/>
              }
            </div>
            
          </div>

          
        </div>
        
        
        <div style={{display:isCollapsed?'none':'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',height:'90%',}}>
          {/*chart header*/}
          <div style={{display:'flex',flexDirection:'column',width:'100%',boxSizing: 'border-box',position:'relative',overflowY:'scroll'}}>
            <div style={{fontSize:f7,display:'flex',flexDirection:'row',width:'100%',boxSizing:'border-box',backgroundColor:props.theme?"rgba(0, 0, 0, 0.2)":'rgba(255, 255, 255, 0.6)',minHeight:'38px',alignItems:'center',textAlign:'center',border:'1px solid rgba(53, 53, 53, 0.05)',borderBottom:'1px solid #FFFFFF50',}}>
              <div className='mousehover' onClick={props.handleClickSort('SYMBOL')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("SYMBOL")} 
                <div style={{marginLeft:5}} >
                  {props.sortType==='SYMBOL'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('NAME')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("NAME")}
                <div  style={{marginLeft:5}} >
                  {props.sortType==='NAME'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('44')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Price")}
                <div style={{marginLeft:5}} >
                  {props.sortType==='44'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('126')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Price $ Change")}
                <div style={{marginLeft:5}} >
                  {props.sortType==='126'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('125')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Price % Change")}
                <div style={{marginLeft:5}} >
                  {props.sortType==='125'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('64')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Volume")}
                <div style={{marginLeft:5}} >
                  {props.sortType==='64'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('117')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Stock RS")}
                <div  style={{marginLeft:5}} >
                  {props.sortType==='117'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('115')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Industry RS")}
                <div style={{marginLeft:5}}>
                  {props.sortType==='115'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div className='mousehover' onClick={props.handleClickSort('124')} style={{flex:2,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {L("Vol % Chg vs 50-day")}
                <div style={{marginLeft:5}} >
                  {props.sortType==='124'
                    ?(props.sortOrder?<SortIcon_Down theme={props.theme}/>:<SortIcon_Up theme={props.theme}/>)
                    :<SortIcon theme={props.theme}/>}
                </div>
              </div>
              <div style={{width:40,backgroundColor:props.theme?'#FFFFFF08':'#00000008',height:'100%'}}></div>

            </div>
          </div>
          {/*end of chart header*/}
          <div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',position:'relative'}}>
            <div style={{display:'flex',flexDirection:'column',width:'100%',top:0,left:0,right:0,bottom:0,boxSizing: 'border-box',overflow:'scroll',position:'absolute'}}>
              {props.data.map((item,index)=>
                <div ref={el => props.autoscrollRef.current[index] = el} key={index} className='mousehover' onClick={props.handleClickStock(item,index)} style={{ fontSize:f7,width:'100%',display:'flex',flexDirection:'row',minHeight:'38px',alignItems:'center',textAlign:'center',backgroundColor:(props.displayingStock&&(props.displayingStock.stockCode ===item.stockCode))? (props.theme?'rgba(206, 206, 206, 0.2)':'rgba(71, 180, 227, 0.15)'):(props.theme?'rgba(29, 29, 29, 0.2)':'rgba(255, 255, 255, 0.2)'),boxSizing:'border-box',border:'1px solid rgba(53, 53, 53, 0.05)'}}>
                  <div style={{flex:2}}>{item.stockCode}</div>
                  <div style={{flex:2}}>{props.HKstockListDict&&props.HKstockListDict[item.stockCode]&&props.HKstockListDict[item.stockCode].stockName}</div>
                  {[44,126,135,64,117,115,134].map((field,field_index)=>
                    fidID_Dict[field].t==='Number'?
                    <div key={field_index} style={{
                      flex:2, 
                      color:fidID_Dict[field].color?(item.data[field]>=0?'#04D26F':'#FF3E3A'):(props.theme?'white':'black')
                    }}>
                      {fidID_Dict[field].isComma?
                        numberWithCommas(item.data[field]):
                        Math.round(item.data[field]*10000)/(fidID_Dict[field].percent?100:10000)
                      }
                    </div>
                    :<div key={field_index} style={{flex:2}}>{item.data[field]}</div>
                  )}

                  <div style={{width:40,backgroundColor:props.theme?'#FFFFFF08':'#00000008',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    {(props.displayingStock&&(props.displayingStock.stockCode ===item.stockCode))&&<PlayIcon theme={props.theme}/>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
