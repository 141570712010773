import React,{useState,useEffect,useRef} from 'react';
import {
  NewFolderIcon,
  DownArrowIcon,
  NewListIcon,
  MinimizeIcon,
  DragIcon,
  UpArrowIcon,
  DotIcon,
  DotDotDotIcon,
  EditIcon,
  MinimizeIcon2
} from '../icons'
import lang from '../lang.json'
const f16='16px'
const f14='14px'
const f10='10px'

export default function ListFolder(props){
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
  if(props.isCollapse)
    return(
      <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
        <div  style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme?'rgba(61, 98, 133, 0.3)':'rgba(241, 248, 255, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:f14,minHeight:22}}>{L('List')}</div>
          <div onClick={props.handleCollapseComponent} className="mousehover">
           {props.isEndofRow?<MinimizeIcon theme={props.theme}/>:<MinimizeIcon2 theme={props.theme}/>}
          </div>
          <div className="drag-handle mousehover">
            <DragIcon theme={props.theme}/>
          </div>
        </div>
      </div>
    )
  return(
    <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',overflowX:'hidden',overflowY:'hidden'}}>
     
      <div style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme?'rgba(61, 98, 133, 0.3)':'rgba(241, 248, 255, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box'}}>
        <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',marginBottom:'0'}}>
          <div style={{fontSize:f16,minHeight:22}}>{L('List')}</div>
          <div className='dragicons' style={{flexDirection:'row'}}>
            <div onClick={props.handleCollapseComponent} className="mousehover">
             {props.isEndofRow?<MinimizeIcon2 theme={props.theme}/>:<MinimizeIcon theme={props.theme}/>}
            </div>
            <div className="drag-handle mousehover" style={{marginLeft:15}}>
              <DragIcon theme={props.theme}/>
            </div>
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,borderRadius: '5px',boxSizing: 'border-box',position:'relative'}}>
          <div style={{display:'flex',flexDirection:'column',position:'absolute',top:0,bottom:0,left:0,right:0,overflowX:'hidden',overflowY:'auto',boxSizing:'border-box',paddingRight:5}}>
            {props.watchListFolder.map((item,index)=>
              item.notFolder?<div key={index}/>:
              <div key={index} style={{width:'100%',marginTop:2,border:item.isExpanded?'1px solid #ffffff40':'1px solid transparent',borderRadius:'5px'}}>
                <div style={{position:'relative',fontSize:f14,padding:'6px 12px 6px 12px',backgroundColor:props.theme?'#0000004D':'rgba(255, 255, 255, 0.3)',boxSizing:'border-box',display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'space-between',paddingLeft:'10px',paddingRight:'10px',borderRadius:'5px'}}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                     <div className='mousehover' onClick={props.handleExpandFolder(item,index)} style={{marginLeft:5,fontSize:f14}}>{item.watchlistFolderName}</div>
                     <div className='mousehover' onClick={props.handleIsEditingFolder(item,index)} style={{marginLeft:10,padding:4}}><EditIcon/></div>
                  </div>

                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div  style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}>
                       {item.isEditing&&<div style={{position:'absolute',top:'100%',right:0,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#2E3538':"#DDDDDD",padding:'5px',zIndex:3,width:'100%',alignItems:'flex-end',fontSize:f14,boxSizing:'border-box'}}>
                        <div onClick={props.handleStartModifyListFolder(item,index)} className='mousehover'>{L("Change Name")}</div>
                        <div onClick={props.handleStartDeleteFolder(item,index)} className='mousehover' style={{marginTop:5}}>{L("Delete")}</div>
                      </div>}
                    </div>
                    <div className='mousehover' onClick={props.handleExpandFolder(item,index)} style={{position:'relative',padding:4,paddingRight:0}}>
                       { item.isExpanded?<UpArrowIcon width={13} height={7} theme={props.theme}/>:<DownArrowIcon theme={props.theme}/>}
                    </div>
                  </div>
                </div>
                {item.isExpanded&&item.watchlist.map((watchlist,watchlistIndex)=>
                  <div key={watchlistIndex} style={{backgroundColor:(props.SelectedList.type==='list'&&props.SelectedList.index===index&&props.SelectedList.index2===watchlistIndex)?'rgba(71, 180, 227, 0.3)':'transparent',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f14,boxSizing:'border-box',padding:'5px'}}>
                    <div className='mousehover' onClick={props.handleSelectWatchList(item,index,watchlist,watchlistIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
                      <div style={{margin:'0 10px 0 15px'}}><DotIcon theme={props.theme}/></div>
                      {watchlist.watchlistName}
                    </div>
                    <div  style={{position:'relative',display:'flex',height:'100%'}}>
                      <div className='mousehover' onClick={props.handleIsEditingWatchlist(item,index,watchlist,watchlistIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
                      {watchlist.isEditing&&<div style={{position:'absolute',top:'calc( 100% + 5px )',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':"#DDDDDD",padding:'5px',zIndex:3, minWidth:'100px',alignItems:'flex-end',fontSize:f14}}>
                        <div className='mousehover' onClick={props.handleStartAddStocktoWatchList(item,index,watchlist,watchlistIndex)}>{L("Add Stock")}</div>
                        <div className='mousehover' onClick={props.handleStartModifyWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("Change Name")}</div>
                        <div  className='mousehover' onClick={props.handleStartEditListPanel(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("List Edit")}</div>
                        <div onClick={props.handleStartDeleteWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}} className='mousehover'>{L("Delete")}</div>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
            )}
            {props.watchListFolder.map((item,index)=>
              item.notFolder?
              <div key={index} style={{width:'100%'}}>
                {item.watchlist.map((watchlist,watchlistIndex)=>
                  <div key={watchlistIndex} style={{backgroundColor:(props.SelectedList.type==='list'&&props.SelectedList.index===index&&props.SelectedList.index2===watchlistIndex)?'rgba(71, 180, 227, 0.3)':(props.theme?'#0000004D':'rgba(255, 255, 255, 0.3)'),width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:f14,boxSizing:'border-box',padding:'5px',borderRadius:'5px',marginTop:2}}>
                    <div className='mousehover' onClick={props.handleSelectWatchList(item,index,watchlist,watchlistIndex)} style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
                      <div style={{margin:'0 10px 0 15px'}}><DotIcon theme={props.theme}/></div>
                      {watchlist.watchlistName}
                    </div>
                    <div  style={{position:'relative',display:'flex',height:'100%'}}>
                      <div className='mousehover' onClick={props.handleIsEditingWatchlist(item,index,watchlist,watchlistIndex)} style={{marginRight:5,padding:2,paddingRight:0}}><DotDotDotIcon/></div>
                      {watchlist.isEditing&&<div style={{position:'absolute',top:'calc( 100% + 5px )',right:-5,display:'flex',flexDirection:'column',backgroundColor:props.theme?'#202020':"#DDDDDD",padding:'5px',zIndex:3, minWidth:'100px',alignItems:'flex-end',fontSize:f14}}>
                        <div className='mousehover' onClick={props.handleStartAddStocktoWatchList(item,index,watchlist,watchlistIndex)}>{L("Add Stock")}</div>
                        <div className='mousehover' onClick={props.handleStartModifyWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("Change Name")}</div>
                        <div  className='mousehover' onClick={props.handleStartEditListPanel(item,index,watchlist,watchlistIndex)} style={{marginTop:5}}>{L("List Edit")}</div>
                        <div onClick={props.handleStartDeleteWatchList(item,index,watchlist,watchlistIndex)} style={{marginTop:5}} className='mousehover'>{L("Delete")}</div>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
              :<div key={index}/>
            )}
            <div style={{width:'100%',minHeight:'100px'}}/>
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'row',overflow:'hidden'}}>
          <div className='mousehover' onClick={props.handleStartCreateFolder} style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:120}}>
            <NewFolderIcon theme={props.theme}/>
            <div style={{fontSize:f14,marginLeft:'5px'}}>{L('Create Folder')}</div>
          </div>
          <div className='mousehover' onClick={props.handleStartCreateWatchList} style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'20px'}}>
            <NewListIcon theme={props.theme}/>
            <div style={{fontSize:f14,marginLeft:'5px'}}>{L('Create List')}</div>
          </div>
        </div>
      </div>
      {props.isEditPopUpMask&&<div  onClick={props.handleCancelMask} style={{zIndex:2,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0}}/>}
    </div>
  )
}