export const ReqIndustryStockRanking=(ws,marketID,stockCode)=>{
    let data ={
      request: "ReqIndustryStockRanking", 
      marketID:marketID,
      stockCode:stockCode,
      requestID: 1,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqIndustryStockRanking ERROR:'+ JSON.stringify(error)) // catch error
    }
  }