export const ReqChartData=(ws,marketID,stockCode,interval,intervalDWM,startTime,endTime=null)=>{
    let data ={
      request: "ReqChartData", 
      marketID:marketID,
      stockCode:stockCode,
      startTime:startTime,
      requestID: 1,
    }

    if(interval)data.interval=interval
    else data.intervalDWM=intervalDWM
    if(endTime)data.endTime=endTime
    try {
        console.log(data)
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqChartData ERROR:'+ JSON.stringify(error)) // catch error
    }
  }