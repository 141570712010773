 export const ReqChangeLang=(ws,lang)=>{
    let data ={
      request: "ReqChangeLang", 
      lang:lang,
      requestID: 1,
    }
    console.log(data)

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqChangeLang ERROR:'+ JSON.stringify(error)) // catch error
    }
  }