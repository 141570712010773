export const ReqModFavoriteCriteriaList=(ws,requestType,criteriaID)=>{
    let data ={
      request: "ReqModFavoriteCriteriaList", 
      requestType:requestType,
      requestID: 1,
      criteriaID:criteriaID
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log('ReqModFavoriteCriteriaList ERROR:'+ JSON.stringify(error)) // catch error
    }
  }