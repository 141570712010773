import React,{useState,useEffect,useRef} from 'react';
import lang from '../lang.json'
export default function DeleteWatchListPopup(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	return (
	  <div style={{zIndex:5,color:'white',boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.2)',backdropFilter:"blur(4px)",display:'flex',justifyContent:'center',alignItems:'center'}}>
	    <div style={{boxSizing:"border-box",backgroundColor:'#590D0D',display:'flex',flexDirection:'column',padding:'20px',alignItems:'flex-start',width:'500px',minHeight:'300px',maxWidth:'95%'}}>
	      <div style={{fontSize:"18px",fontWeight:700,lineHeight:'21px'}}>{L('Confirm Delete')}</div>
	      <div style={{fontSize:"14px",fontWeight:400,lineHeight:'16px',marginTop:'20px'}}>{L('Delete the list')}: “{props.name}”?</div>
	      <div style={{fontSize:"14px",fontWeight:400,lineHeight:'16px',marginTop:'5px',color:'#A0A0A0'}}>
	      	<div style={{display:'inline',color:'#FF0000'}}>{L('IMPORTANT')}: </div>
	      	{L('This action cannot be undo.')}
	      </div>
	      <div style={{boxSizing:"border-box",flex:1,width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
	        <div onClick={props.handleCancel} className='mousehover' style={{backgroundColor:'#6B6B6B',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Cancel')}
	        </div>
	        <div onClick={props.handleConfirm} className='mousehover' style={{marginLeft:20,backgroundColor:'#E34747',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Delete')}
	        </div>
	      </div>
	    </div>
	  </div>
	)
}