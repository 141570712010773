export const ReqWatchlistList=(ws)=>{
    let data ={
      request: "ReqWatchlist", 
      requestID: 20,
    }

    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log('ReqWatchlist ERROR:'+ JSON.stringify(error)) // catch error
    }
  }