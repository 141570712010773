import React,{useState,useEffect,useRef} from 'react';
import {
  LogOutIcon,
  LayoutIcon1,
  LayoutIcon2,
  LayoutIcon3,
  LayoutIcon4,
  Logo,
  Logo_W
} from '../icons'
import lang from '../lang.json'
const f10='10px'
const f12='12px'
const f14='14px'
export default function Header(props){
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
  return(
    <div style={{display:'flex',justifyContent:'space-between',width:'100%',height:'48px',padding:'5px 20px 5px 20px',boxSizing:'border-box',fontSize:'18px',alignItems:'center'}}>
      <Logo_W width={127} height={22}/>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',height:'100%'}}>
        <div style={{fontSize:f14,opacity:.5,marginLeft:20,marginRight:20,minWidth:60}}>{L("Market")}</div>
        <div onClick={props.handleMarket(1)} className='mousehover' style={{fontSize:f14,opacity:props.MarketID===1?1:.5,marginLeft:10}}>{L('HK')}</div>
        <div onClick={props.handleMarket(2)} className='mousehover' style={{fontSize:f14,opacity:props.MarketID===2?1:.5,marginLeft:10}}>{L('US')}</div>
        
        <div style={{height:'70%',width:2,backgroundColor:props.theme?"white":'black',opacity:.2,marginLeft:40}}/>
        <div style={{fontSize:f14,opacity:.5,marginLeft:20}}>{L('Quick Layout')}</div>
        <div className='mousehover' onClick={props.handleSelectLayout(1)} style={{marginLeft:10,opacity:props.layoutoutIndex===1?1:.3}}><LayoutIcon1 theme={props.theme}/></div>
        <div className='mousehover' onClick={props.handleSelectLayout(2)} style={{marginLeft:10,opacity:props.layoutoutIndex===2?1:.3}}><LayoutIcon2 theme={props.theme}/></div>
        <div className='mousehover' onClick={props.handleSelectLayout(3)} style={{marginLeft:10,opacity:props.layoutoutIndex===3?1:.3}}><LayoutIcon3 theme={props.theme}/></div>
        <div className='mousehover' onClick={props.handleSelectLayout(4)} style={{marginLeft:10,opacity:props.layoutoutIndex===4?1:.3}}><LayoutIcon4 theme={props.theme}/></div>
        <div style={{height:'70%',width:2,backgroundColor:props.theme?"white":'black',opacity:.2,marginLeft:40}}/>
        <div style={{fontSize:f14,opacity:.5,marginLeft:20,marginRight:20,minWidth:60}}>{L("Language")}</div>
        {props.LangList.map((item,index)=>
          <div onClick={props.handleLang(item,index)} className='mousehover' key={index} style={{fontSize:f14,opacity:(props.lang&&(props.lang.lang===item.lang))?1:.5,marginLeft:10}}>{item.langName&&item.langName.slice(0,2)}</div>
        )}
        <div style={{height:'70%',width:2,backgroundColor:props.theme?"white":'black',opacity:.2,marginLeft:40}}/>
        <div style={{fontSize:f14,opacity:.5,marginLeft:20,minWidth:50}}>{L("Theme")}</div>
        <div className='mousehover' onClick={props.handleTheme} style={{width:43,height:17,backgroundColor:props.theme?"#0D0D0D":'#E5E5E5',borderRadius:'11px',marginLeft:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          <div style={{backgroundColor:props.theme?'transparent':'#258CF4',height:12,width:12,borderRadius:'50%'}}/>
          <div style={{backgroundColor:props.theme?'#005DA0':'transparent',height:12,width:12,borderRadius:'50%',marginLeft:10}}/>
        </div>
        <div style={{height:'70%',width:2,backgroundColor:props.theme?"white":'black',opacity:.2,marginLeft:20}}/>
        <div className='mousehover' onClick={props.handleLogout} style={{width:125,height:23,border:props.theme?"1px solid rgba(255,255,255,.2)":"1px solid rgba(0,0,0,.2)",borderRadius:'5px',marginLeft:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          <div style={{fontSize:f14,color:props.theme?"rgba(255,255,255,.5)":'rgba(0,0,0,.5)',marginRight:10}}>{L("Logout")}</div>
          <LogOutIcon theme={props.theme}/>
        </div>
      </div>
    </div>
  )
}