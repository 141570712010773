export const ReqFinFigure=(ws,marketID,stockCode,figureTemplateID=1,requestID=1)=>{
    let data ={
      request: "ReqFinFigure", 
      marketID:marketID,
      stockCode:stockCode,
      figureTemplateID:figureTemplateID,
      requestID: requestID,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqFinFigure ERROR:'+ JSON.stringify(error)) // catch error
    }
  }